import { Card, CardContent } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { ColorDynamic, useResponsiveValue } from '@superdispatch/ui';
import styled from 'styled-components';

export const LOADS_PAGE_SIZE = 10;

const StyledCard = styled(Card)`
  margin-right: -17px;
  margin-left: -17px;
  .MuiCardContent-root {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: ${ColorDynamic.Blue300};
  }
  .MuiPaginationItem-page.Mui-disabled {
    color: ${ColorDynamic.Dark100};
  }
  .Mui-selected {
    background-color: ${ColorDynamic.Blue50};
  }
  .MuiPagination-ul {
    flex-wrap: nowrap;
  }
`;

export interface PageButtonProps {
  page?: number;
  currentPage?: number;
  onClick?: (page: number) => void;
}

export interface LoadsPagePaginationProps {
  count: number;
  currentPage: number;
  onPageChange: (nextPage: number) => void;
}

export function LoadsPagePagination({
  count,
  currentPage,
  onPageChange,
}: LoadsPagePaginationProps) {
  const maxPage = Math.ceil(count / LOADS_PAGE_SIZE);
  const isMobile = useResponsiveValue(true, false, false);

  const renderCardContent = (
    <CardContent>
      <StyledPagination
        page={currentPage}
        siblingCount={maxPage - currentPage < 4 && maxPage > 99 ? 0 : undefined}
        size="large"
        count={maxPage}
        onChange={(_, page) => {
          onPageChange(page);
        }}
      />
    </CardContent>
  );

  if (!isMobile) {
    return <Card>{renderCardContent}</Card>;
  }

  return (
    <StyledCard role="navigation" aria-label="Pagination Navigation">
      {renderCardContent}
    </StyledCard>
  );
}
