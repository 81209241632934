import { IconButton, Tooltip } from '@material-ui/core';
import { Add, Lock, Search } from '@material-ui/icons';
import { Inline, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useMemo, useState } from 'react';
import { FilterIcon } from 'shared/icons/FilterIcon';
import { LinkButton } from 'shared/routing/Links';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { findLoadsPageParamsSearchEntry } from '../data/LoadsPageParams';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';
import { LoadsImportButton } from './LoadsImportButton';
import { LoadsPageFilterSearch } from './LoadsPageFilterSearch';
import { LoadsPageFilterMobile } from './mobile/LoadsPageFilterMobile';

const StyledBox = styled(Box)<{
  flexDirection?: React.CSSProperties['flexDirection'];
}>`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;
const StyledIconButton = styled(IconButton)`
  &[data-has-badge='true']::before {
    content: ' ';
    position: absolute;
    top: -2px;
    right: -2px;
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
    border: 2px solid white;
  }
`;

interface LoadsPageHeaderActionsProps {
  onUpgradePlan: () => void;
  onImportedLoads: () => void;
}

export function LoadsPageHeaderActions({
  onUpgradePlan,
  onImportedLoads,
}: LoadsPageHeaderActionsProps) {
  const isMobile = useResponsiveValue(true, false);
  const { data: settings } = useCarrierSettings();
  const shouldRestrictCreateLoad = !settings?.can_create_load;
  const { params, updateParams, isEmptyFilterParams } = useLoadsPageContext();
  const [modalState, setModalState] = useState<'filters' | 'search'>();
  const [searchName, searchValue] = useMemo(
    () => findLoadsPageParamsSearchEntry(params),
    [params],
  );

  const renderCreateLoadContent = isMobile ? <Add /> : 'Create Load';

  return (
    <div data-intercom-target="loads-page-header-actions">
      {isMobile && (
        <>
          <LoadsPageFilterMobile
            open={modalState === 'filters'}
            onClose={() => {
              setModalState(undefined);
            }}
          />

          <LoadsPageFilterSearch
            drawerProps={{
              open: modalState === 'search',
              onClose: () => {
                setModalState(undefined);
              },
            }}
            name={searchName}
            value={searchValue}
            onSearch={(field, value) => {
              updateParams(
                { stage: 'active', [field]: value },
                { strategy: 'reset' },
              );
              trackLoadsEvent({ name: 'CTMS: Searched Loads', field, value });
            }}
          />
        </>
      )}
      <StyledBox flexDirection={isMobile ? 'row-reverse' : 'row'}>
        {shouldRestrictCreateLoad ? (
          <Tooltip
            placement="bottom"
            title={
              <Inline verticalAlign="center">
                <Lock />
                <TextBox variant="body" color="white">
                  Upgrade to unlock this feature.
                </TextBox>
              </Inline>
            }
          >
            <Button
              startIcon={<Lock />}
              variant="default"
              onClick={() => {
                onUpgradePlan();
                trackLoadsEvent({
                  name: 'Carrier Clicked Create Load',
                });
              }}
            >
              {renderCreateLoadContent}
            </Button>
          </Tooltip>
        ) : (
          <LinkButton
            variant="default"
            to="/loads/add"
            onClick={() => {
              trackLoadsEvent({
                name: 'Carrier Clicked Create Load',
              });
            }}
          >
            {renderCreateLoadContent}
          </LinkButton>
        )}

        <LoadsImportButton
          onSubmitSuccess={() => {
            onImportedLoads();
          }}
          onClick={() => {
            onUpgradePlan();
          }}
        />

        {isMobile && (
          <>
            {params.stage !== 'active' && (
              <StyledIconButton
                data-has-badge={!isEmptyFilterParams || !!params.order_by}
                size="small"
                onClick={() => {
                  setModalState('filters');
                }}
              >
                <FilterIcon color="action" />
              </StyledIconButton>
            )}

            <IconButton
              size="small"
              onClick={() => {
                setModalState('search');
              }}
            >
              <Search color="action" fontSize="small" />
            </IconButton>
          </>
        )}
      </StyledBox>
    </div>
  );
}
