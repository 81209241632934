import {
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { FormikTextField } from '@superdispatch/forms';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { FormikProvider } from 'formik';
import { toInteger } from 'lodash-es';
import { useAppFormik } from 'shared/form/AppFormik';
import { InlineDateRangeField } from 'shared/form/InlineDateRangeField';
import { trackEvent } from 'shared/helpers/Analytics';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { useLocationParams } from 'shared/routing/LocationParams';
import { ReportsNotAvailable } from './core/ReportNotAvailbale';
import { ReportsCard, ReportsCardItem } from './core/ReportsCard';
import { ReportsPageHeader } from './core/ReportsPageHeader';
import {
  useDriverPayReport,
  useDriverPayTotal,
  useReportsAPI,
} from './data/ReportsAPI';
import { useReportsContext } from './data/ReportsContext';
import {
  driverPayParamsSchema,
  sendDriverPayReportRequestSchema,
} from './data/ReportsDTO';
import { ReportsDriverPayRateCell } from './ReportsDriverPayRateCell';

export function ReportsDriverPayPage() {
  const { sendDriverPayReport } = useReportsAPI();
  const { isAvailable } = useReportsContext();
  const [params, setParams] = useLocationParams({
    yupSchema: driverPayParamsSchema,
    persistName: 'ReportsDriverPay',
  });

  const {
    page,
    page_size,
    start_date,
    end_date,
    only_active: isOnlyActive,
  } = params;

  const totalDriverPay = useDriverPayTotal({
    start_date,
    end_date,
    only_active: isOnlyActive,
  });

  const driversPayReport = useDriverPayReport(params);

  const formik = useAppFormik({
    enableReinitialize: false,
    initialValues: { email: '' },
    validationSchema: sendDriverPayReportRequestSchema,
    onSubmit(values) {
      return sendDriverPayReport({
        ...values,
        start_date,
        end_date,
        only_active: isOnlyActive,
      });
    },
    onSubmitSuccess() {
      trackEvent('Carrier Sent Driver Pay Report', {
        only_active: isOnlyActive ? 'Only Active' : 'Active & Deactivated',
      });
    },
    getSuccessMessage(_, { email }) {
      return `Your report will be sent to your email ${email}. Generating and sending a report might take up to 5 min`;
    },
    getErrorMessage(error) {
      return error.message || 'Something went wrong';
    },
  });

  useErrorSnackbar(driversPayReport.error);

  return (
    <>
      <DocumentTitle title="Driver Pay Report - Reports" />

      <FormikProvider value={formik}>
        <PageLayout
          loading={driversPayReport.isFetching}
          stickyHeader={true}
          header={
            <ReportsPageHeader
              title="Driver Pay Report"
              helpLink="https://support.superdispatch.com/hc/en-us/articles/38189641642387-Create-Calculate-Driver-Pay-Reports"
              actions={
                isAvailable && (
                  <Inline space="small">
                    <FormikTextField
                      name="email"
                      id="reportName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            component="label"
                            htmlFor="reportName"
                          >
                            <TextBox
                              noWrap={true}
                              color="secondary"
                              variant="body-semibold"
                            >
                              Email
                            </TextBox>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Button
                      onClick={() => {
                        void formik.submitForm();
                      }}
                      variant="primary"
                      pending={formik.isSubmitting}
                    >
                      Send Report
                    </Button>

                    <Tooltip title="Generating and sending a report might take up to 5 min">
                      <IconButton size="small">
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Inline>
                )
              }
            />
          }
        >
          {isAvailable ? (
            <Stack space="small">
              <Columns space="small" collapseBelow="desktop">
                <Column width="content">
                  <InlineDateRangeField
                    label="Choose Period"
                    value={[start_date, end_date]}
                    onChange={({ stringValue: [start, finish] }) => {
                      setParams({
                        start_date: start,
                        end_date: finish,
                        page: 1,
                      });
                    }}
                  />
                </Column>
                <Column width="content">
                  <Box display="flex">
                    <Box width="237px">
                      <TextField
                        fullWidth={true}
                        select={true}
                        value={isOnlyActive}
                        onChange={({ target: { value } }) => {
                          setParams({
                            only_active: value === 'true',
                            page: 1,
                          });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography color="textSecondary">
                                Drivers
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem value="false">Active & Deactivated</MenuItem>
                        <MenuItem value="true">Only Active</MenuItem>
                      </TextField>
                    </Box>
                    {isOnlyActive && (
                      <Box marginLeft="xxsmall" display="flex">
                        <Tooltip title="Only Active shows drivers who are not deactivated. Note that it’s not associated with dates driver was active. ">
                          <IconButton size="small">
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Column>
              </Columns>
              <Columns space="small" collapseBelow="desktop">
                <Column width={['fluid', 'fluid', '3/4']}>
                  <TableContainer component={Paper}>
                    <Table aria-label="reports table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Pay Rate</TableCell>
                          <TableCell>Revenue</TableCell>
                          <TableCell>Driver Fee</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {!driversPayReport.data?.data.length && (
                          <TableRow>
                            <TableCell align="center" size="medium" colSpan={4}>
                              There are no drivers
                            </TableCell>
                          </TableRow>
                        )}

                        {driversPayReport.data?.data.map((row) => (
                          <TableRow key={row.guid}>
                            <TableCell id={row.guid} component="th" scope="row">
                              {row.name || row.email}
                            </TableCell>
                            <ReportsDriverPayRateCell
                              guid={row.guid}
                              payRate={row.pay_rate}
                              onSubmitSuccess={() => {
                                void totalDriverPay.refetch();
                                void driversPayReport.refetch();
                              }}
                            />
                            <TableCell>{formatCurrency(row.revenue)}</TableCell>
                            <TableCell>
                              {formatCurrency(
                                (row.revenue / 100) * row.pay_rate,
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>

                      {driversPayReport.data?.data &&
                        driversPayReport.data.pagination.count > 0 && (
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                page={page - 1}
                                rowsPerPage={page_size}
                                rowsPerPageOptions={[10, 20, 30]}
                                count={driversPayReport.data.pagination.count}
                                onPageChange={(_, pageNumber) => {
                                  setParams({ page: pageNumber + 1 });
                                }}
                                onRowsPerPageChange={(event) => {
                                  setParams({
                                    page_size: toInteger(event.target.value),
                                    page: 1,
                                  });
                                }}
                              />
                            </TableRow>
                          </TableFooter>
                        )}
                    </Table>
                  </TableContainer>
                </Column>

                <Column width={['fluid', 'fluid', '1/4']}>
                  <ReportsCard title="Total">
                    <Stack space="small">
                      <Typography>
                        for{' '}
                        <FormattedDate variant="ShortDate" date={start_date} />{' '}
                        – <FormattedDate variant="Date" date={end_date} />
                      </Typography>
                      <ReportsCardItem
                        label="Revenue:"
                        value={totalDriverPay.data?.total_revenue}
                      />
                      <ReportsCardItem
                        label="Driver Fee:"
                        value={totalDriverPay.data?.total_fee}
                      />
                    </Stack>
                  </ReportsCard>
                </Column>
              </Columns>
            </Stack>
          ) : (
            <ReportsNotAvailable />
          )}
        </PageLayout>
      </FormikProvider>
    </>
  );
}
