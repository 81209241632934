import { Typography } from '@material-ui/core';
import {
  Column,
  Columns,
  DrawerActions,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { formatLoadPayment } from 'shared/helpers/PaymentHelpers';
import { useBoolean } from 'shared/hooks/useBoolean';
import { DottedLine } from 'shared/ui/DottedLine';
import { LoadSendInvoiceDTO } from '../data/LoadActionsDTO';
import { OrdersDTO } from '../data/LoadDTO';
import { LOAD_PAYMENT_TYPES } from '../data/LoadPaymentTypes';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { useGetCombinedInvoicePDF } from '../data/LoadsAPI';
import { LoadInvoicePreviewDialog } from './LoadInvoicePreviewDialog';
import { LoadSendInvoiceRequestPayment } from './LoadSendInvoiceRequestPayment';

type PaymentMethodProp = typeof LOAD_PAYMENT_TYPES | string | undefined | null;

export interface LoadSendInvoiceDrawerActionsProps {
  submitDisabled?: boolean;
  orders?: Array<Partial<OrdersDTO>>;
  paymentMethod: PaymentMethodProp;
  isCreatedByBroker?: boolean;
}

export function LoadSendInvoiceDrawerActions({
  submitDisabled = false,
  orders,
  paymentMethod,
  isCreatedByBroker,
}: LoadSendInvoiceDrawerActionsProps) {
  const previewInvoice = useBoolean();
  const { isSubmitting, values } = useFormikContext<LoadSendInvoiceDTO>();
  const {
    invoice_date,
    invoice_id,
    factor_this_invoice: isFactorThisInvoiceAvailable,
    bol_template,
    is_carrier_requested_superpay: isCarrierRequestedSuperPay,
  } = values;

  const totalPrice = useMemo(() => {
    let total = 0;
    const isOrdersValid =
      orders && orders.length > 0 && orders.every((val) => Boolean(val));
    if (isOrdersValid) {
      total = orders.reduce(
        (current, so) => current + Number(so.invoice_price),
        0,
      );
    }
    return total;
  }, [orders]);

  const paymentMethodType = useMemo(() => {
    if (orders && orders.length > 1) {
      return 'Mixed';
    }
    return formatLoadPayment(paymentMethod);
  }, [orders, paymentMethod]);

  const isCarrierRequestedSuperPayValue =
    isCarrierRequestedSuperPay ?? undefined;

  const invoiceDate = DateTime.fromISO(invoice_date).toFormat(
    "yyyy-MM-dd'T'HH:mm:ss",
  );
  const guids: string =
    orders?.map((order) => order.guid as string).join(',') ?? '';

  const { data, isLoading, isFetching } = useGetCombinedInvoicePDF({
    load_guids: guids,
    invoice_number: invoice_id ?? '',
    invoice_date: invoiceDate,
    bol_template: bol_template ?? 'default',
    factor_this_invoice: !!isFactorThisInvoiceAvailable,
    is_carrier_requested_superpay: isCarrierRequestedSuperPayValue,
  });

  return (
    <>
      <LoadInvoicePreviewDialog
        open={previewInvoice.isEnabled}
        html={data?.html ?? null}
        onClose={() => {
          previewInvoice.setFalse();
        }}
      />
      <DrawerActions>
        <Box width="100%" paddingBottom="medium" paddingTop="medium">
          <Stack space="small">
            <Column width="fluid">
              <DecriptionRow title="Method" value={paymentMethodType} />
            </Column>
            <LoadSendInvoiceRequestPayment
              paymentMethod={paymentMethod}
              isCreatedByBroker={isCreatedByBroker}
            />
            <Column width="fluid">
              <DecriptionRow
                title="Total"
                boldValue={true}
                value={formatCurrency(totalPrice)}
              />
            </Column>

            <Inline>
              <Button
                pending={isSubmitting}
                disabled={submitDisabled}
                type="submit"
                variant="primary"
              >
                Send Invoice
              </Button>
              <Button
                disabled={isLoading || isFetching}
                onClick={() => {
                  if (!orders) {
                    return;
                  }
                  trackLoadsEvent({ name: 'Carrier Clicked Preview Invoice' });
                  previewInvoice.setTrue();
                }}
                variant="neutral"
              >
                Preview
              </Button>
            </Inline>
          </Stack>
        </Box>
      </DrawerActions>
    </>
  );
}

interface DecriptionRowParams {
  title: string;
  value: string | number | JSX.Element;
  boldValue?: boolean;
  e2eValue?: string;
}

function DecriptionRow({
  title,
  e2eValue,
  value,
  boldValue,
}: DecriptionRowParams) {
  return (
    <Columns align="center">
      <Column width="content">
        <Typography
          variant={boldValue ? 'h5' : 'caption'}
          color={boldValue ? undefined : 'textSecondary'}
          gutterBottom={true}
        >
          {title}
        </Typography>
      </Column>
      <Column width="fluid">
        <DottedLine />
      </Column>
      <Column width="content">
        <Typography
          align="right"
          color="textPrimary"
          variant={boldValue ? 'h5' : 'caption'}
          data-e2e={e2eValue}
        >
          {value}
        </Typography>
      </Column>
    </Columns>
  );
}
