import { IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton, Box } from '@superdispatch/ui-lab';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { SettingsQuickbookDesktop } from './core/SettingQuickbookDesktop';
import { SettingsPageLayout } from './core/SettingsPageLayout';
import { SettingsQuickbookOnline } from './core/SettingsQuickbookOnline';

export function SettingsQuickbooksIntegrationPage() {
  const isMobile = useResponsiveValue(true, false);

  return (
    <>
      <DocumentTitle title="Quickbooks Integration - Settings" />

      <SettingsPageLayout
        actions={
          isMobile ? (
            <IconButton
              color="inherit"
              target="_blank"
              size="small"
              rel="noopener noreferrer"
              href="https://support.superdispatch.com/hc/articles/36694203543827"
            >
              <Info
                aria-label="import loads info"
                fontSize="small"
                color="action"
              />
            </IconButton>
          ) : (
            <AnchorButton
              variant="text"
              target="_blank"
              href="https://support.superdispatch.com/hc/articles/36694203543827"
            >
              Open Help Center
            </AnchorButton>
          )
        }
        title="Quickbooks Integration"
      >
        <Box width="768px">
          <Stack space="small">
            <SettingsQuickbookOnline />
            <SettingsQuickbookDesktop />
          </Stack>
        </Box>
      </SettingsPageLayout>
    </>
  );
}
