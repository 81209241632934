import { ZENDESK_TOKEN_KEY } from 'shared/modules/zendesk/ZendeskData';
import { LocalStore } from '../Store';
import { ZendeskApi } from './ZendeskInitialize';
import { ZendeskConversationField } from './ZendeskTypes';

export function showZendeskMessenger() {
  ZendeskApi('messenger', 'show');
}

export function hideZendeskMessenger() {
  ZendeskApi('messenger', 'hide');
}

export function openZendeskMessenger() {
  ZendeskApi('messenger', 'open');
}

export function closeZendeskMessenger() {
  ZendeskApi('messenger', 'close');
}

export function setZendeskLocale(locale: string) {
  ZendeskApi('messenger:set', 'locale', locale);
}

export function setZendeskZIndex(zIndex: number) {
  ZendeskApi('messenger:set', 'zIndex', zIndex);
}

export function setZendeskCookies(enabled: boolean) {
  ZendeskApi('messenger:set', 'cookies', enabled);
}

export function setZendeskConversationFields(
  fields: ZendeskConversationField[],
) {
  ZendeskApi('messenger:set', 'conversationFields', fields);
}

export function setZendeskConversationTags(tags: string[]) {
  ZendeskApi('messenger:set', 'conversationTags', tags);
}

export function loginZendeskUser(jwtToken: string | null) {
  if (jwtToken) {
    ZendeskApi(
      'messenger',
      'loginUser',
      (callback: (token: string) => void) => {
        callback(jwtToken);
      },
      (error: string) => {
        if (error) {
          LocalStore.delete(ZENDESK_TOKEN_KEY);
        }
      },
    );
  }
}

export function logoutZendeskUser() {
  ZendeskApi('messenger', 'logoutUser');
}
