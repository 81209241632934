import { Link, SwipeableDrawer, Typography } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { useDeepEqualValue } from '@superdispatch/hooks';
import {
  Color,
  ColorDark,
  Column,
  Columns,
  DrawerContent,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { noop } from 'lodash-es';
import { NavLink } from 'react-router-dom';
import { useCookie } from 'shared/helpers/StorageHelpers';
import { MobilebarIcon } from 'shared/icons/MobilebarIcon';
import { SuperCarrierBadgeIcon } from 'shared/icons/SuperCarrierBadgeIcon';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import { SidebarMenuContainer } from '../../../loadboard/desktop/LoadboardDesktopSidebar';
import { SidebarLinkLegacy, SidebarMenuItem } from './AppSidebarMenuLegacy';
import { useNavbarContext } from './NavbarContext';
import { useCarrierMenuLegacy } from './useCarrierMenuLgacy';
import { useSidebarItems } from './useSidebarItems';

interface MobileMenuDrawerProps {
  localPaths?: {
    carrierProfile?: string;
    dispatcherProfile?: string;
    settings?: string;
    subscriptionDetails?: string;
    ratings?: string;
    logout?: string;
    dispatcherManagement?: string;
  };
}

const SidebarMenuDrawerContent = styled(DrawerContent)`
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? ColorDark.White : Color.Dark500};
`;

const Drawer = styled(SwipeableDrawer)`
  & .MuiDrawer-paper {
    min-width: 320px;
    background-color: $
      ${({ theme }) =>
        theme.palette.type === 'dark' ? ColorDark.White : Color.Dark500};
  }
`;

const Title = styled(TextBox)`
  color: ${Color.White};
`;

export function MobileMenuDrawer({
  localPaths: localPathsProp = {},
}: MobileMenuDrawerProps) {
  const { isDrawerOpen, setDrawerOpen } = useNavbarContext();
  const localPaths = useDeepEqualValue(localPathsProp);
  const { ratingsLink, accountItems, carrierItems, dispatcherItems } =
    useCarrierMenuLegacy({ localPaths });
  const { data: settings } = useCarrierSettings();
  const { offersLinks, primaryLinks, secondaryLinks } = useSidebarItems({
    localPaths,
  });

  const companyName =
    useCookie('companyName') || settings?.carrier.name || 'Unknown';
  const carrierRating = settings?.rating || { count: 0, percentage: 0 };
  const isVerified = settings?.carrier.verification_status === 'approved';

  const onClose = () => {
    setDrawerOpen(null);
  };

  return (
    <Drawer
      onClose={onClose}
      open={isDrawerOpen === 'menu'}
      onOpen={noop}
      anchor="right"
      disableSwipeToOpen={true}
    >
      <SidebarMenuDrawerContent>
        <Box height="100%">
          <Stack space="large">
            <Stack space="xxsmall">
              <Box paddingLeft="xsmall" paddingTop="small">
                <a href="/tms/loads" aria-label="Dashboard">
                  <MobilebarIcon fontSize="large" htmlColor={Color.Silver500} />
                </a>
              </Box>
              <Box paddingTop="small" paddingLeft="xsmall">
                <Box display="flex" paddingBottom="xsmall">
                  <Title noWrap={true} variant="body">
                    {companyName}
                  </Title>

                  {isVerified && <SuperCarrierBadgeIcon fontSize="small" />}
                </Box>

                <Inline verticalAlign="center" space="xsmall">
                  <Inline verticalAlign="center" space="xxsmall">
                    <ThumbUp fontSize="small" color="action" />

                    <Title>{carrierRating.percentage}%</Title>

                    <Typography color="textSecondary">
                      ({carrierRating.count})
                    </Typography>
                  </Inline>

                  <Link
                    component={ratingsLink.localPath ? NavLink : 'a'}
                    href="/tms/profile/overview"
                    to={ratingsLink.localPath}
                    color="primary"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    View Ratings
                  </Link>
                </Inline>
              </Box>
            </Stack>

            <MobileSidebar
              offersLinks={offersLinks}
              primaryLinks={primaryLinks}
              secondaryLinks={secondaryLinks}
              carrierItems={carrierItems}
              dispatcherItems={dispatcherItems}
              accountItems={accountItems}
              onClick={onClose}
            />
          </Stack>
        </Box>
      </SidebarMenuDrawerContent>
    </Drawer>
  );
}

export interface MobileSidebarMenuProps {
  offersLinks: SidebarLinkLegacy[];
  primaryLinks: SidebarLinkLegacy[];
  secondaryLinks: SidebarLinkLegacy[];
  carrierItems: SidebarLinkLegacy[];
  dispatcherItems: SidebarLinkLegacy[];
  accountItems: SidebarLinkLegacy[];
  onClick?: () => void;
}

const MenuContainer = styled.div`
  flex: 1;
  padding: 0;
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? ColorDark.White : Color.Dark500};
`;

function MobileSidebar({
  offersLinks,
  primaryLinks,
  secondaryLinks,
  carrierItems,
  dispatcherItems,
  accountItems,
  onClick,
}: MobileSidebarMenuProps) {
  return (
    <MenuContainer>
      <MobileSidebarMenu links={offersLinks} />
      <MobileSidebarMenu onClick={onClick} links={primaryLinks} />
      <MobileSidebarMenu onClick={onClick} links={secondaryLinks} />
      <MobileSidebarMenu onClick={onClick} links={carrierItems} />
      <MobileSidebarMenu onClick={onClick} links={dispatcherItems} />
      <MobileSidebarMenu onClick={onClick} links={accountItems} />
    </MenuContainer>
  );
}

const StyledBox = styled(Box)`
  border-radius: 50%;
`;
const Label = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
`;
export const LinkIcon = styled.a`
  width: 24px;
  height: 24px;

  path {
    fill: ${Color.Silver500};
  }
`;

export function MobileSidebarMenu({
  links,
  disableGutter,
  onClick,
}: {
  links: SidebarLinkLegacy[];
  disableGutter?: boolean;
  onClick?: () => void;
}) {
  return (
    <SidebarMenuContainer
      data-intercom-target="siebar-menu-section"
      data-disablegutter={!!disableGutter}
    >
      {links.map(
        (link) =>
          !link.hidden && (
            <SidebarMenuItem onClick={onClick} key={link.key} link={link}>
              <Columns align="center">
                <Column width="fluid">
                  <Columns space="small" align="center">
                    <Column width="content">
                      <LinkIcon as={link.Icon} />
                    </Column>

                    <Column width="fluid">
                      <Label>{link.label}</Label>
                    </Column>
                  </Columns>
                </Column>

                <Column width="content">
                  {link.badgeContent && (
                    <StyledBox padding="xsmall" backgroundColor="Red300" />
                  )}
                </Column>
              </Columns>
            </SidebarMenuItem>
          ),
      )}
    </SidebarMenuContainer>
  );
}
