import { QueryBuilder } from '@material-ui/icons';
import { Color, Inline, Tag } from '@superdispatch/ui';
import { AccountOffIcon } from 'shared/icons';
import { PersonAddAlt } from 'shared/icons/PersonAddAlt';
import { DispatcherDTO } from 'shared/modules/dispatcher/DispatcherDTO';

interface DispatcherStatusProps {
  dispatcher: DispatcherDTO;
}

export function DispatcherStatus({ dispatcher }: DispatcherStatusProps) {
  if (dispatcher.connection_status === 'deactivated') {
    return (
      <Tag variant="subtle" color="red">
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <AccountOffIcon fontSize="small" />
          Deactivated
        </Inline>
      </Tag>
    );
  } else if (
    dispatcher.connection_status === 'pending' &&
    dispatcher.connection_type === 'invite'
  ) {
    return (
      <Tag variant="subtle" color="yellow">
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <QueryBuilder fontSize="small" />
          Invited
        </Inline>
      </Tag>
    );
  } else if (
    dispatcher.connection_status === 'pending' &&
    dispatcher.connection_type === 'join_request'
  ) {
    return (
      <Tag variant="subtle" color="blue">
        <Inline noWrap={true} space="xxsmall" verticalAlign="center">
          <PersonAddAlt htmlColor={Color.Blue300} />
          Join Request
        </Inline>
      </Tag>
    );
  }

  return null;
}
