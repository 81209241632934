import { useResponsiveValue } from '@superdispatch/ui';
import { useFlag } from 'shared/settings/FeatureToggles';
import {
  hideIntercomLauncher,
  showIntercomLauncher,
  showIntercomMessenger,
  showNewIntercomMessage,
} from './Intercom';
import {
  hideZendeskMessenger,
  openZendeskMessenger,
  showZendeskMessenger,
} from './zendesk/Zendesk';

export function useCustomerSupport() {
  const shouldShowZendeskLauncher = useFlag('ctms_zendesk_launcher');
  const isMobile = useResponsiveValue(true, false);

  const showLauncher = () => {
    if (isMobile) return;

    if (shouldShowZendeskLauncher) {
      showZendeskMessenger();
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
      hideZendeskMessenger();
    }
  };

  const showLauncherMessanger = (message?: string) => {
    if (shouldShowZendeskLauncher) {
      openZendeskMessenger();
      showZendeskMessenger();
    } else if (message) {
      showNewIntercomMessage(message);
    } else {
      showIntercomMessenger();
    }
  };

  const hideLauncher = () => {
    hideZendeskMessenger();
    hideIntercomLauncher();
  };

  return {
    showLauncher,
    hideLauncher,
    showLauncherMessanger,
  };
}
