import { URITemplateParams } from '@superdispatch/uri';
import {
  QueryClient,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { Schema } from 'yup';

export type APIQueryInput =
  | string[]
  | readonly [domain: string, resource: string, variables?: URITemplateParams];

export type APIQueryOptions<TData> = Omit<
  UseQueryOptions<TData, Error>,
  'queryFn' | 'queryKey' | 'queryHash' | 'queryKeyHashFn' | 'isDataEqual'
>;

export interface UseAPIQueryOptions<TData> extends APIQueryOptions<TData> {
  schema?: Schema<TData>;
  normalize?: (response: unknown) => TData;
}

export type APIQueryResult<TData> = UseQueryResult<TData, Error>;

export function useAPIQuery<TData>(
  input: APIQueryInput,
  query: () => Promise<APIResponse<TData>>,
  { schema, normalize, ...options }: UseAPIQueryOptions<TData> = {},
): APIQueryResult<TData> {
  return useQuery(
    input,
    () =>
      query().then(({ data }) => {
        if (schema) data = schema.cast(data);
        if (normalize) data = normalize(data);
        return data;
      }),
    options,
  );
}

export type APIQueryDataUpdater<TData> = (data: TData) => TData;

export function setAPIQueryData<TData>(
  queryClient: QueryClient,
  input: APIQueryInput,
  updater: APIQueryDataUpdater<TData>,
): void {
  const data = queryClient.getQueryData<TData>(input);
  if (data) queryClient.setQueryData(input, updater(data));
}
