import { Typography } from '@material-ui/core';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { ReactNode, useEffect, useState } from 'react';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { EmptySearchLoadsIcon } from 'shared/icons/EmptySearchLoadsIcon';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { ScrollToTop } from 'shared/routing/ScrollToTop';
import {
  getLoadActionThreeModesVariants,
  LoadActionThreeModes,
} from './core/LoadActionThreeModes';
import { LoadAddTerminalDrawer } from './core/LoadAddTerminalDrawer';
import { LoadAssign } from './core/LoadAssign';
import {
  isLoadInstantActionType,
  useLoadInstantAction,
} from './core/LoadInstantAction';
import { LoadMarkAsPaidDrawer } from './core/LoadMarkAsPaidDrawer';
import { LoadNoteAddDialog } from './core/LoadNoteAddDialog';
import { LoadSendBOLDrawer } from './core/LoadSendBOLDrawer';
import { LoadSendInvoiceDrawer } from './core/LoadsendInvoiceDrawer';
import {
  formatLoadStage,
  formatLoadStatus,
  LoadDTO,
  LoadUpdateTerminalDTO,
} from './data/LoadDTO';
import { trackLoadsEvent } from './data/LoadsAnalytics';
import { useLoadsCache, useLoadsPage } from './data/LoadsAPI';
import { useLoadsPageContext } from './loads-page/data/LoadsPageParamsContext';
import { LoadCancelDialog } from './loads-page/LoadCancelDialog';
import { LoadsPageLoad } from './loads-page/LoadsPageLoad';
import { LoadsPageLoadAction } from './loads-page/LoadsPageLoadActions';
import {
  LoadsPagePagination,
  LOADS_PAGE_SIZE,
} from './loads-page/LoadsPagePagination';

interface LoadsListProps {
  pageHeader?: ReactNode;
}

export function LoadsList({ pageHeader }: LoadsListProps) {
  const { addSnackbar } = useSnackbarStack();
  const { params, updateParams } = useLoadsPageContext();
  const loads = useLoadsPage(params);
  const { invalidateLoads, setLoadsPageItems } = useLoadsCache();
  const { showLauncher, hideLauncher } = useCustomerSupport();

  const [modalState, setModalState] = useState<
    undefined | { type: LoadsPageLoadAction; load: LoadDTO }
  >();

  const getModalLoad = (variant: LoadsPageLoadAction): undefined | LoadDTO =>
    modalState?.type !== variant ? undefined : modalState.load;
  const getModalLoadGUID = (variant: LoadsPageLoadAction): undefined | string =>
    modalState?.type !== variant ? undefined : modalState.load.guid;

  const closeModal = () => {
    setModalState(undefined);
  };

  const closeModalAndRefetch = () => {
    closeModal();
    invalidateLoads();
  };

  const mutateLoad = (guid: string, values: Partial<LoadDTO>) => {
    setLoadsPageItems(params, (items) =>
      items.map((x) => (x.guid !== guid ? x : { ...x, ...values })),
    );
  };

  const removeLoad = (guid: string) => {
    setLoadsPageItems(params, (items) => items.filter((x) => x.guid !== guid));
  };

  const { submitInstantAction, isSubmitting } = useLoadInstantAction({
    onActionComplete: (type, load) => {
      if (type === 'unarchive') {
        removeLoad(load.guid);
      }

      if (type === 'mark_as_unpaid') {
        invalidateLoads();
      }
    },
  });

  const isSendInvoiceDrawerOpened = getModalLoad('send_invoice_drawer');

  useEffect(() => {
    if (isSendInvoiceDrawerOpened) {
      hideLauncher();
    } else {
      showLauncher();
    }
  }, [isSendInvoiceDrawerOpened, showLauncher, hideLauncher]);

  useEffect(() => {
    if (loads.error) {
      addSnackbar(loads.error.message, { variant: 'error' });
    }
  }, [loads.error, addSnackbar]);

  useEffect(() => {
    setModalState(undefined);
  }, [params]);

  return (
    <>
      <DocumentTitle title={formatLoadStage(params.stage) + ' Loads'} />
      <ScrollToTop key={params.page} />

      <LoadNoteAddDialog
        onClose={closeModal}
        load={getModalLoad('add_note')}
        onSubmitSuccess={({ note, loadGUID }) => {
          closeModal();
          mutateLoad(loadGUID, { latest_internal_note: note });
        }}
      />
      <LoadAssign
        onClose={closeModal}
        load={getModalLoad('assign')}
        onSubmitSuccess={({ loadGUID, currentDriver }) => {
          closeModal();
          if (currentDriver) {
            trackLoadsEvent({
              name: 'CTMS: Driver Reassigned',
              page: 'loads_list',
            });
            removeLoad(loadGUID);
          } else {
            invalidateLoads();
          }
        }}
      />
      <LoadSendBOLDrawer
        onClose={closeModal}
        pageType="loads_list"
        loadGUID={getModalLoadGUID('send_bol')}
        onSubmitSuccess={closeModalAndRefetch}
      />
      <LoadMarkAsPaidDrawer
        onClose={closeModal}
        load={getModalLoad('mark_as_paid')}
        onSubmitSuccess={() => {
          trackLoadsEvent({
            name: 'CTMS: Marked as Paid',
            page: 'loads_list',
          });
          closeModalAndRefetch();
        }}
      />
      <LoadCancelDialog
        onClose={closeModalAndRefetch}
        load={getModalLoad('cancel')}
        disableAutoclose={true}
      />

      {getLoadActionThreeModesVariants().map((variant) => (
        <LoadActionThreeModes
          key={variant}
          variant={variant}
          onClose={closeModal}
          load={getModalLoad(variant)}
          onSubmitSuccess={(data, { load }) => {
            closeModal();
            switch (variant) {
              case 'delete':
                trackLoadsEvent({
                  name: 'Carrier Deleted Load',
                  page: 'loads_list',
                  utm_medium: formatLoadStage(params.stage),
                  load_status: formatLoadStatus(load.status),
                  is_created_by_broker: load.is_created_by_broker,
                });
                break;
              case 'archive':
                trackLoadsEvent({
                  name: 'CTMS: Load Archived',
                  page: 'loads_list',
                });
                break;
              case 'restore':
                trackLoadsEvent({
                  name: 'CTMS: Restored Load',
                  page: 'loads_list',
                });
                break;

              case 'mark_as_picked_up':
                trackLoadsEvent({
                  name: 'CTMS: Marked as Picked Up',
                  page: 'loads_list',
                });
                break;

              case 'mark_as_delivered':
                trackLoadsEvent({
                  name: 'CTMS: Marked as Delivered',
                  page: 'loads_list',
                });
                break;

              case 'mark_as_new':
                trackLoadsEvent({
                  name: 'CTMS: Marked as New',
                  page: 'loads_list',
                });
                break;

              case 'dispatch_to_carrier':
                trackLoadsEvent({
                  name: 'CTMS: Dispatched To Carrier',
                  page: 'loads_list',
                });
                break;

              case 'unassign':
                trackLoadsEvent({
                  name: 'CTMS: Driver Unassigned',
                  page: 'loads_list',
                });
                break;
            }

            if (
              variant === 'delete' ||
              variant === 'archive' ||
              variant === 'restore' ||
              variant === 'unassign' ||
              variant === 'mark_as_in_terminal'
            ) {
              removeLoad(load.guid);
            } else if (variant === 'remove_terminal') {
              const { terminals_diagram } = data as LoadUpdateTerminalDTO;

              mutateLoad(load.guid, { terminals_diagram });
            } else {
              invalidateLoads();
            }
          }}
        />
      ))}
      <LoadAddTerminalDrawer
        onClose={closeModal}
        load={getModalLoad('add_terminal')}
        onSubmitSuccess={(response, { loadGUID }) => {
          trackLoadsEvent({
            name: 'CTMS: Terminal Added',
            page: 'loads_list',
          });
          closeModal();
          mutateLoad(loadGUID, {
            terminals_diagram: response.terminals_diagram,
          });
        }}
      />

      <LoadSendInvoiceDrawer
        onClose={closeModal}
        load={getModalLoad('send_invoice_drawer')}
      />

      <PageLayout
        loading={loads.isFetching || isSubmitting}
        header={pageHeader}
      >
        <Columns space="small">
          <Column width="fluid">
            <Stack space="small">
              <Stack space="xsmall">
                {loads.data?.data.length === 0 && (
                  <Box paddingTop="large">
                    <Stack space="small" align="center">
                      <Stack space="medium" align="center">
                        <EmptySearchLoadsIcon />

                        <Typography variant="h2">
                          No matching results
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                )}

                {loads.data?.data.map((load) => (
                  <LoadsPageLoad
                    key={load.guid}
                    load={load}
                    onAction={(type) => {
                      if (isLoadInstantActionType(type)) {
                        submitInstantAction(type, load);
                      } else {
                        setModalState({ type, load });
                      }
                    }}
                  />
                ))}

                {loads.data != null &&
                  loads.data.pagination.count > LOADS_PAGE_SIZE && (
                    <LoadsPagePagination
                      currentPage={params.page}
                      count={loads.data.pagination.count}
                      onPageChange={(nextPage) => {
                        updateParams({ page: nextPage });
                      }}
                    />
                  )}
              </Stack>
            </Stack>
          </Column>
        </Columns>
      </PageLayout>
    </>
  );
}
