import { Divider, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { useEventHandler } from '@superdispatch/hooks';
import { Inline, Tag, useResponsiveValue } from '@superdispatch/ui';
import { Button, ButtonProps } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { isEmpty, omitBy } from 'lodash-es';
import { ReactNode, useMemo } from 'react';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import {
  canHaveSimilarSearch,
  createSearchCriteria,
  createSimilarSearch,
  searchCriteriaToJSON,
} from 'shared/modules/loadboard/PostingSearchCriteriaDTO';
import { LinkButton } from 'shared/routing/Links';
import {
  CarrierSettings,
  useCarrierSettings,
} from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { MenuButton } from 'shared/ui/MenuButton';
import {
  formatLoadStage,
  formatLoadStatus,
  isLoadCanceled,
  LoadDTO,
} from '../data/LoadDTO';
import { formatStageMedium, trackLoadsEvent } from '../data/LoadsAnalytics';
import { LoadsPageParams } from '../data/LoadsPageParams';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';

export type LoadsPageLoadAction =
  | 'assign'
  | 'unassign'
  | 'duplicate'
  | 'add_note'
  | 'mark_as_new'
  | 'mark_as_picked_up'
  | 'mark_as_delivered'
  | 'mark_as_paid'
  | 'mark_as_unpaid'
  | 'send_bol'
  | 'view_bol'
  | 'archive'
  | 'unarchive'
  | 'delete'
  | 'restore'
  | 'cancel'
  | 'add_terminal'
  | 'remove_terminal'
  | 'mark_as_in_terminal'
  | 'remove_dispatched_to_carrier_label'
  | 'request_access_dispatch_to_carrier'
  | 'dispatch_to_carrier'
  | 'expedite_payment'
  | 'send_invoice_drawer'
  | 'upgrade_plan'
  | 'upgrade_plan_warning';

interface LoadsPageLoadActionProps {
  load: LoadDTO;
  settings?: CarrierSettings;
  params: LoadsPageParams;
  isCancelLoadEnabled: boolean;
  isExpeditedPayEnabled: boolean;
  isMobile: boolean;
}

interface LoadsPageLoadActionOption {
  label: ReactNode;
  action?: LoadsPageLoadAction;
  dataIntercomTarget?: string;
  onClick?: (props: LoadsPageLoadActionProps) => void;
  isPrimary?: (props: LoadsPageLoadActionProps) => boolean;
  isVisible?: (props: LoadsPageLoadActionProps) => boolean;
  isDisabled?: (props: LoadsPageLoadActionProps) => boolean;
  buttonProps?: (props: LoadsPageLoadActionProps) => Partial<ButtonProps>;
}

interface LoadsPageLoadActionGroup {
  options: LoadsPageLoadActionOption[];
  isVisible?: (props: LoadsPageLoadActionProps) => boolean;
}

function isHTMLElement(element: unknown): element is HTMLElement {
  try {
    return element instanceof HTMLElement;
  } catch {
    return typeof element === 'object' && !!element && 'tagName' in element;
  }
}

const actions: LoadsPageLoadActionGroup[] = [
  {
    isVisible: ({ load }) => load.is_dispatched_to_carrier,
    options: [
      {
        label: 'Open Dispatched Load',
        onClick: ({ load }) => {
          openExternalURL(load.url_in_stms, { target: '_blank' });
        },
      },
      {
        label: 'Remove "Dispatched to Carrier"',
        action: 'remove_dispatched_to_carrier_label',
      },
    ],
  },

  {
    isVisible: ({ load, isMobile }) => !!load.can_be_edited && isMobile,
    options: [
      {
        label: 'Edit',
        onClick: ({ load, params }) => {
          openExternalURL(
            parseURITemplate('/tms/loads/{guid}/edit{?next}', {
              guid: load.guid,
              next: window.location.pathname + window.location.search,
            }),
          );

          trackLoadsEvent({
            name: 'Carrier Clicked Edit Load',
            page: 'loads_list',
            is_created_by_broker: !!load.is_created_by_broker,
            load_status: formatLoadStatus(load.status),
            utm_medium:
              params.stage === 'active'
                ? 'Search Results'
                : formatLoadStage(params.stage),
          });
        },
      },
    ],
  },

  {
    isVisible: ({ load, settings }) =>
      !!settings?.has_terminal && !load.archived && load.is_active,

    options: [
      {
        label: 'Mark as in Terminal',
        action: 'mark_as_in_terminal',
        isVisible: ({ load }) =>
          !!load.terminals.length && load.can_mark_as_in_terminal,
      },
      {
        label: 'Add Terminal',
        action: 'add_terminal',
        isVisible: ({ load }) => load.can_add_terminal,
      },
      {
        label: 'Remove Terminal',
        action: 'remove_terminal',
        isVisible: ({ load }) =>
          load.terminals.some((terminal) => !terminal.is_delivered),
      },
    ],
  },
  {
    isVisible: ({ load }) => load.is_active,

    options: [
      {
        label: 'Assign',
        action: 'assign',
        isVisible: ({ load }) =>
          !load.archived && load.can_be_assigned_to_driver,
        buttonProps: ({ load }) => ({
          variant: load.stage === 'picked_up' ? 'neutral' : 'default',
        }),
        dataIntercomTarget: 'load-assign-button',
        isPrimary: ({ load }) =>
          load.stage === 'new' ||
          load.stage === 'in_terminal' ||
          (load.stage === 'picked_up' && !load.can_be_unassigned_from_driver),
      },

      {
        label: 'Reassign',
        action: 'assign',
        buttonProps: ({ load }) => ({
          variant: load.stage === 'in_terminal' ? 'default' : 'neutral',
        }),
        isVisible: ({ load }) => load.can_be_unassigned_from_driver,
        isPrimary: ({ load }) =>
          load.stage === 'in_terminal' ||
          load.stage === 'picked_up' ||
          load.stage === 'assigned',
      },

      {
        label: 'Unassign',
        action: 'unassign',
        isVisible: ({ load }) =>
          !load.archived && load.can_be_unassigned_from_driver,
      },

      {
        label: 'Duplicate',
        action: 'duplicate',
        isVisible: ({ load }) => !load.archived && load.can_be_duplicated,
      },

      {
        label: 'Split Order',
        isVisible: ({ load }) => !load.archived && load.can_be_split,
        onClick: ({ load, params }) => {
          trackLoadsEvent({
            name: 'Carrier Clicked Split Order',
            page: 'loads_list',
            utm_medium: formatStageMedium(params.stage),
            is_created_by_broker: load.is_created_by_broker,
          });
          openExternalURL(
            '/tms/loads-split/{guid}{?back_url,is_created_by_broker,utm_medium}',
            {
              guid: load.guid,
              back_url: window.location.pathname + window.location.search,
              utm_medium: formatStageMedium(params.stage),
              is_created_by_broker: load.is_created_by_broker,
            },
          );
        },
      },

      {
        label: 'Mark as New',
        action: 'mark_as_new',
        buttonProps: () => ({ variant: 'neutral' }),
        isVisible: ({ load }) => load.status !== 'new',
      },

      {
        label: 'Mark as Picked Up',
        action: 'mark_as_picked_up',
        buttonProps: () => ({ variant: 'neutral' }),
        isVisible: ({ load }) => !load.archived && load.can_be_picked_up,
      },

      {
        label: 'Mark as Delivered',
        action: 'mark_as_delivered',
        buttonProps: () => ({ variant: 'neutral' }),
        isVisible: ({ load }) =>
          !load.archived &&
          load.can_be_delivered &&
          !load.can_mark_as_in_terminal,
      },
      {
        label: 'Dispatch to Carrier',
        action: 'dispatch_to_carrier',
        isVisible: ({ load }) =>
          !load.archived &&
          load.can_be_dispatched_to_carrier &&
          !load.should_request_dispatch_to_carrier,
      },

      { label: 'Send BOL', action: 'send_bol' },

      {
        label: 'Send Invoice',
        action: 'send_invoice_drawer',
        dataIntercomTarget: 'send-invoice-button',
        isPrimary: ({ load }) => load.stage === 'delivered',
        isVisible: ({ load }) => load.can_send_invoice,
        onClick: ({ load }) => {
          trackLoadsEvent({
            name: 'CTMS: Send Invoice Clicked',
            page: 'loads_list',
            load_stage: load.stage,
          });
        },
      },

      {
        label: 'Mark as Paid',
        action: 'mark_as_paid',
        isPrimary: ({ load }) => load.stage === 'billed',
        isVisible: ({ load }) => !load.archived && load.can_be_marked_as_paid,
      },

      {
        label: 'Mark as Unpaid',
        action: 'mark_as_unpaid',
        isVisible: ({ load }) => !!load.can_be_marked_as_unpaid,
      },

      { label: 'Add Internal Note', action: 'add_note' },
    ],
  },
  {
    options: [
      {
        label: (
          <Inline space="small">
            <Typography>Expedite Payment</Typography>
            <Tag color="blue" variant="subtle">
              New
            </Tag>
          </Inline>
        ),
        action: 'expedite_payment',
        isVisible: ({ load, isExpeditedPayEnabled }) =>
          isExpeditedPayEnabled &&
          !!load.payments[0]?.is_expedited_pay_available,
      },
      {
        label: 'View BOL',
        action: 'view_bol',
        isVisible: ({ load }) => load.status !== 'new',
        onClick: ({ load, params }) => {
          trackLoadsEvent({
            name: 'CTMS: Clicked View BOL',
            page: 'loads_list',
            utm_medium: formatStageMedium(params.stage),
            status: load.status,
          });
        },
      },
    ],
  },
  {
    isVisible: ({ load }) => !load.archived && load.is_active,
    options: [
      {
        label: 'Search for Similar Loads',
        isDisabled: ({ load }) =>
          !canHaveSimilarSearch(
            createSearchCriteria({
              pickup_venues: [load.pickup.venue],
              delivery_venues: [load.delivery.venue],
            }),
          ),
        onClick: ({ load }) => {
          trackLoadsEvent({
            name: 'CTMS: Search for Similar Loads Clicked',
            page: 'loads_list',
          });
          openExternalURL(
            '/loadboard/loads{?utm_medium,utm_source,search_criteria}',
            {
              utm_source: 'Web CTMS',
              utm_medium: 'Similar Loads',
              search_criteria: searchCriteriaToJSON(
                createSimilarSearch(
                  createSearchCriteria({
                    pickup_venues: [omitBy(load.pickup.venue, isEmpty)],
                    delivery_venues: [omitBy(load.delivery.venue, isEmpty)],
                  }),
                ),
              ),
            },
          );
        },
      },
    ],
  },
  {
    options: [
      {
        label: 'Archive',
        action: 'archive',
        buttonProps: () => ({ variant: 'neutral' }),
        isPrimary: ({ load }) => load.stage === 'paid',
        isVisible: ({ load, isCancelLoadEnabled }) => {
          if (isCancelLoadEnabled) {
            return (
              !load.archived &&
              load.is_active &&
              (!load.is_created_by_broker ||
                isLoadCanceled(load) ||
                load.status !== 'new')
            );
          }
          return !load.archived && load.is_active;
        },
      },
      {
        label: 'Unarchive',
        action: 'unarchive',
        isVisible: ({ load }) => load.archived && load.is_active,
      },
      {
        label: 'Delete',
        action: 'delete',
        isVisible: ({ load, isCancelLoadEnabled }) => {
          if (isCancelLoadEnabled) {
            return (
              load.is_active &&
              (!load.is_created_by_broker ||
                isLoadCanceled(load) ||
                load.status !== 'new')
            );
          }

          return load.is_active;
        },
      },
      {
        label: 'Cancel Load',
        action: 'cancel',
        isVisible: ({ load, isCancelLoadEnabled }) => {
          const isInterminal = load.terminals.some((t) => t.is_current);
          return (
            load.is_created_by_broker &&
            !isLoadCanceled(load) &&
            load.is_active &&
            load.status === 'new' &&
            !isInterminal &&
            isCancelLoadEnabled
          );
        },
      },
      {
        label: 'Restore',
        action: 'restore',
        isVisible: ({ load }) => !load.is_active,
        buttonProps: () => ({ variant: 'neutral' }),
        isPrimary: ({ load }) => load.stage === 'deleted',
      },
    ],
  },
];

export interface LoadsPageLoadActions {
  load: LoadDTO;
  onAction: (action: LoadsPageLoadAction) => void;
}

export function LoadsPageLoadActions({ load, onAction }: LoadsPageLoadActions) {
  const { data: settings } = useCarrierSettings();
  const { params } = useLoadsPageContext();
  const handleAction = useEventHandler(onAction);
  const isCancelLoadEnabled = useFlag('enable_cancel_load');
  const isExpeditedPayEnabled = useFlag('payments_expedited_pay');
  const isMobile = useResponsiveValue(true, false, false);

  const [primaryAction, menuOptions] = useMemo(() => {
    let nextPrimaryAction: ReactNode = null;
    let nextMenuOptions: ReactNode[] = [];
    let idx = 0;

    for (const { options, isVisible: isVisibleGroup } of actions) {
      const menuProps: LoadsPageLoadActionProps = {
        load,
        settings,
        params,
        isCancelLoadEnabled,
        isExpeditedPayEnabled,
        isMobile,
      };
      const groupOptions: ReactNode[] = [];

      if (isVisibleGroup && !isVisibleGroup(menuProps)) {
        continue;
      }

      for (const {
        label,
        action,
        isPrimary,
        isVisible,
        isDisabled,
        onClick,
        buttonProps,
        dataIntercomTarget,
      } of options) {
        if (isVisible && !isVisible(menuProps)) {
          continue;
        }

        const disabled = isDisabled?.(menuProps);
        const handleClick = () => {
          onClick?.(menuProps);

          if (action) {
            handleAction(action);
          }
        };

        if (isPrimary?.(menuProps) && !isMobile) {
          nextPrimaryAction = (
            <Button
              {...buttonProps?.(menuProps)}
              disabled={disabled}
              onClick={handleClick}
              data-intercom-target={dataIntercomTarget}
            >
              {label}
            </Button>
          );
        } else {
          groupOptions.push(
            <MenuItem key={idx++} disabled={disabled} onClick={handleClick}>
              {label}
            </MenuItem>,
          );
        }
      }

      if (groupOptions.length) {
        if (nextMenuOptions.length > 0) {
          nextMenuOptions.push(<Divider key={idx++} />);
        }

        nextMenuOptions.push(groupOptions);
      }
    }

    return [nextPrimaryAction, nextMenuOptions];
  }, [
    load,
    settings,
    params,
    isCancelLoadEnabled,
    isExpeditedPayEnabled,
    isMobile,
    handleAction,
  ]);

  return (
    <Inline space="xsmall" verticalAlign="center">
      {primaryAction}

      {!!menuOptions.length && (
        <MenuButton
          variant="neutral"
          data-intercom-target="load-actions-menu"
          renderMenu={(menuProps) => (
            <Menu
              {...menuProps}
              onClick={(event) => {
                if (isHTMLElement(event.target)) {
                  menuProps.onClose();
                }
              }}
            >
              {menuOptions}
            </Menu>
          )}
        >
          {isMobile ? <MoreHoriz /> : 'Options'}
        </MenuButton>
      )}

      {load.can_be_edited && !isMobile && (
        <LinkButton
          variant="neutral"
          to={parseURITemplate('/loads/{guid}/edit{?next}', {
            guid: load.guid,
            next: window.location.pathname + window.location.search,
          })}
          onClick={() => {
            trackLoadsEvent({
              name: 'Carrier Clicked Edit Load',
              page: 'loads_list',
              is_created_by_broker: !!load.is_created_by_broker,
              load_status: formatLoadStatus(load.status),
              utm_medium:
                params.stage === 'active'
                  ? 'Search Results'
                  : formatLoadStage(params.stage),
            });
          }}
        >
          Edit
        </LinkButton>
      )}
    </Inline>
  );
}
