import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { formatDate } from '@superdispatch/dates';
import {
  Column,
  Columns,
  ExitTransitionPlaceholder,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';

interface DriverDeactivationPlanUpdateDialogProps extends DialogProps {
  onSubmitSuccess: () => void;
}

export function DriverDeactivationPlanUpdateDialog({
  onSubmitSuccess,
  ...props
}: DriverDeactivationPlanUpdateDialogProps) {
  return (
    <Dialog {...props}>
      <ExitTransitionPlaceholder in={props.open}>
        <Content />
      </ExitTransitionPlaceholder>
      <DialogActions>
        <Button onClick={onSubmitSuccess}>Got It</Button>
      </DialogActions>
    </Dialog>
  );
}

function Content() {
  const { data: subscription } = useSubscriptionSettings();

  const renewDate = formatDate(subscription?.billing_period_end_date, {
    variant: 'ShortDate',
  });
  const driversCount = Number(subscription?.plan?.drivers_count);
  return (
    <>
      <DialogTitle>Your New Plan will begin on {renewDate}</DialogTitle>
      <DialogContent>
        <Box
          backgroundColor="Blue50"
          borderRadius="small"
          padding="xsmall"
          maxWidth="400px"
        >
          <Columns space="xsmall">
            <Column width="content">
              <Info fontSize="small" color="primary" />
            </Column>
            <Column>
              <Stack>
                <Stack space="none">
                  <Typography variant="body1">
                    Current Plan: {driversCount}{' '}
                    {formatPlural(driversCount, 'Driver', 'Drivers')}
                  </Typography>
                  <Typography>
                    Deactivating drivers today will not change your current
                    plan. Your plan will automatically update on {renewDate},{' '}
                    {DateTime.local().year} to reflect the number of active
                    drivers on your account.
                  </Typography>
                </Stack>
                <Link
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://support.superdispatch.com/hc/articles/35612448514195-How-To-Manage-Your-Subscription-Plan-In-Carrier-TMS"
                >
                  Learn more
                </Link>
              </Stack>
            </Column>
          </Columns>
        </Box>
      </DialogContent>
    </>
  );
}
