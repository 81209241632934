import { Typography } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { LinkButton } from 'shared/routing/Links';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { Image } from 'shared/ui/Image';
import styled from 'styled-components';
import addBankAccountIllustration from '../../assets/add_bank_account_illustration.png';
import bankVerifiedIllustration from '../../assets/bank_verified_illustration.png';
import bankVerifiedIllustrationDark from '../../assets/bank_verified_illustration_dark.png';
import happyClientIllustration from '../../assets/happy_client_illustration.png';
import happyClientIllustrationDark from '../../assets/happy_client_illustration_dark.png';
import paymentOnHoldIllustration from '../../assets/payment_on_hold_illustration.png';
import paymentOnHoldIllustrationDark from '../../assets/payment_on_hold_illustration_dark.png';
import paymentReceivedIllustration from '../../assets/payment_received_illustration.png';
import paymentReceivedIllustrationDark from '../../assets/payment_received_illustration_dark.png';
import paymentTypeIllustration from '../../assets/payment_type_illustration.png';
import paymentTypeIllustrationDark from '../../assets/payment_type_illustration_dark.png';
import superPayGradientTitle1x from '../../assets/superpay_gradient_title_1x.png';
import superPayGradientTitle1xDark from '../../assets/superpay_gradient_title_1x_dark.png';
import superPayGradientTitle from '../../assets/superpay_gradient_title_2x.png';
import superPayGradientTitleDark from '../../assets/superpay_gradient_title_2x_dark.png';
import superpayOrderIllustration from '../../assets/superpay_order_illustration.png';
import { trackSuperPayEvent } from '../../data/SuperPayAnalytics';
import { useSuperPaySettings } from '../../data/SuperPayAPI';
import { logPaymentInfo } from '../PaymentLogger';
import {
  GradientBox,
  PromoCard,
  PromoCardContainer,
  PromoChip,
  PromoChipContainer,
  PromoStepCard,
} from './SuperPayPromoComponents';

const BannerContainer = styled(Box)`
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CustomPromoChip = styled(PromoChip)`
  background-color: ${ColorDynamic.Blue50};
`;

export function PromoPageContent({ setupLink }: { setupLink: string }) {
  const { data: superPayVerification } = useSuperPaySettings();
  const { data: settings } = useCarrierSettings();
  const hasAccountingFeature = settings?.user.has_accounting_feature;
  const isRestricted = superPayVerification?.verification_step === 'restricted';

  useEffect(() => {
    trackSuperPayEvent({
      name: 'Carrier Opened SuperPay Promo Page',
    });
  }, []);

  return (
    <>
      <BannerContainer
        borderWidth={['none', 'small']}
        borderBottomWidth="none"
        borderTopLeftRadius="medium"
        borderTopRightRadius="medium"
        borderBottomLeftRadius="none"
        borderBottomRightRadius="none"
        paddingLeft="small"
        paddingRight="small"
        borderColor="Silver400"
        marginTop={['none', 'large']}
        aria-label="superpay promo banner"
        overflow="hidden"
      >
        <GradientBox top="50px" right="-150px" rotate="15deg" />
        <GradientBox top="160px" left="-150px" rotate="-150deg" />
        <GradientBox bottom="-20px" left="-160px" rotate="-135deg" />
        <GradientBox bottom="70px" right="-160px" rotate="45deg" />

        <Box
          marginTop="xxlarge"
          paddingTop={['none', 'none', 'large']}
          width={['180px', '208px', '208px']}
        >
          <Image
            src={superPayGradientTitle}
            width="100%"
            alt="gradient logo"
            srcDark={superPayGradientTitleDark}
          />
        </Box>

        <Box marginTop="medium" width={['180px', 'auto', 'auto']}>
          <Typography align="center" variant="h2">
            Automated ACH Payments!
          </Typography>
        </Box>

        <Box marginTop="large">
          <PromoChipContainer>
            <PromoChip top="-10px" left="6px">
              No More Lost Checks
            </PromoChip>
            <CustomPromoChip elevation={3} top="-6px" left="60px">
              No More Waiting
            </CustomPromoChip>
            <PromoChip elevation={4}>No More Chasing</PromoChip>
          </PromoChipContainer>
        </Box>

        {hasAccountingFeature && (
          <Box marginTop="medium">
            <SetupSuperPayButton
              source="Top Button"
              isRestricted={isRestricted}
              setupLink={setupLink}
            />
          </Box>
        )}

        <Box marginTop="xxlarge">
          <Typography variant="h4">How it&apos;s Better?</Typography>
        </Box>

        <Box marginTop="small">
          <PromoCardContainer>
            <PromoCard
              backgroundColor="Silver200"
              imgSrc={bankVerifiedIllustration}
              imgSrcDark={bankVerifiedIllustrationDark}
              title="One Setup for All Shippers"
              subtitle="Confirm your bank account once and accept money from any shipper."
            />

            <PromoCard
              backgroundColor="Teal50"
              imgSrc={paymentReceivedIllustration}
              imgSrcDark={paymentReceivedIllustrationDark}
              title="Track Payment Status"
              subtitle="Know the exact date and status of your payment."
            />

            <PromoCard
              backgroundColor="Silver200"
              imgSrc={paymentOnHoldIllustration}
              imgSrcDark={paymentOnHoldIllustrationDark}
              title="Know What’s Holding Your Payment"
              subtitle="See reasons for the hold and resolve without playing phone tag."
            />

            <PromoCard
              backgroundColor="Blue50"
              imgSrc={paymentTypeIllustration}
              imgSrcDark={paymentTypeIllustrationDark}
              title="Stand Out & Get More Loads"
              subtitle="More and more shippers are preferring carriers that accept SuperPay."
            />
          </PromoCardContainer>
        </Box>

        <Box marginTop="xxlarge" width="100%">
          <Stack align="center" space="small">
            <Typography variant="h4">How it works?</Typography>

            <PromoStepCard imgSrc={addBankAccountIllustration} step={1}>
              <Typography variant="h4">Securely set up bank account</Typography>
            </PromoStepCard>

            <PromoStepCard imgSrc={superpayOrderIllustration} step={2}>
              <Typography variant="h4">Book orders with</Typography>
              <Image
                src={superPayGradientTitle1x}
                height="17px"
                alt="gradient logo"
                srcDark={superPayGradientTitle1xDark}
              />
            </PromoStepCard>
          </Stack>
        </Box>

        <Box marginTop="xlarge" width={['168px', 'auto', 'auto']}>
          <Typography align="center" variant="h2">
            ... and Get Paid Automatically
          </Typography>
        </Box>

        <Box
          display="flex"
          marginTop="xxlarge"
          width={['200px', '240px', '240px']}
          height={['138px', '166px', '166px']}
        >
          <Image
            src={happyClientIllustration}
            width="100%"
            height="100%"
            alt="happy client"
            srcDark={happyClientIllustrationDark}
          />
        </Box>
      </BannerContainer>
      {hasAccountingFeature && (
        <Box
          padding="small"
          backgroundColor="White"
          borderColor="Silver400"
          borderWidth="small"
        >
          <Inline horizontalAlign="center">
            <SetupSuperPayButton
              source="Footer Button"
              isRestricted={isRestricted}
              setupLink={setupLink}
            />
          </Inline>
        </Box>
      )}
    </>
  );
}

type SuperPayButtonContent = 'Top Button' | 'Footer Button';
interface SuperPayButtonProps {
  source: SuperPayButtonContent;
  isRestricted: boolean;
  setupLink: string;
}
function SetupSuperPayButton({
  source,
  isRestricted,
  setupLink,
}: SuperPayButtonProps) {
  return isRestricted ? (
    <Box
      padding="small"
      backgroundColor="Yellow50"
      color="Yellow500"
      width="100%"
      borderRadius="small"
    >
      <Columns space="large" align="center" collapseBelow="tablet">
        <Column width="fluid">
          <Columns space="xsmall">
            <Column width="content">
              <WarningRounded fontSize="small" />
            </Column>
            <Column width="fluid">
              <Typography>
                In order to sign up for SuperPay, you will need to become a
                Verified Carrier or be sent a SuperPay load from a Shipper.
              </Typography>
            </Column>
          </Columns>
        </Column>
        <Column width="content">
          <Button size="large" disabled={true}>
            Set up SuperPay
          </Button>
        </Column>
      </Columns>
    </Box>
  ) : (
    <LinkButton
      size="large"
      to={setupLink}
      onClick={() => {
        trackSetupSuperPayButton(source);
      }}
    >
      Set up SuperPay
    </LinkButton>
  );
}

function trackSetupSuperPayButton(utmContent: SuperPayButtonContent) {
  trackSuperPayEvent({
    name: 'Carrier Clicked Set Up SuperPay',
    utm_content: utmContent,
  });
  logPaymentInfo('Carrier Clicked Set Up SuperPay', 'SuperPayPromoPage');
}
