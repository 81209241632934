import { RouteObject } from 'react-router-dom';
import { DriversPage } from './DriversPage';
import { DriversPageContextProvider } from './DriversPageContext';
import { DriversPageDetails } from './DriversPageDetails';

function DriversOutlet() {
  return (
    <DriversPageContextProvider>
      <DriversPage />
    </DriversPageContextProvider>
  );
}

export const driversRoutes: RouteObject[] = [
  {
    path: 'drivers',
    element: <DriversOutlet />,
    children: [
      {
        path: ':driverGUID?/:action?',
        element: <DriversPageDetails />,
      },
    ],
  },
];
