import { Paper as MuiPaper } from '@material-ui/core';
import { ColorDynamic, Stack, useResponsiveValue } from '@superdispatch/ui';
import { ReactChild, ReactNode, Suspense } from 'react';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';

const NavigationBarContainerRoot = styled.div`
  display: flex;

  width: 100%;
  min-width: 100%;
  max-width: 100%;

  min-height: 100vh;
`;

const NavigationBarContainerContent = styled.div<{ isMobile: boolean }>`
  position: relative;
  min-height: inherit;

  width: ${({ isMobile }) => (isMobile ? '100%' : `calc(100% - 88px)`)};
  max-width: ${({ isMobile }) => (isMobile ? '100%' : `calc(100% - 88px)`)};
  min-width: ${({ isMobile }) => (isMobile ? '100%' : `calc(100% - 88px)`)};

  @media print {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const NavigationBarContainerBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const Paper = styled(MuiPaper)<{ hasBorder?: boolean }>`
  height: 100%;
  overflow-x: auto;
  border: ${({ hasBorder }) =>
    hasBorder ? `1px solid ${ColorDynamic.Silver400}` : 'none'};
  background-color: ${ColorDynamic.Silver200};
`;

export interface NavigationBarContainerProps {
  children?: ReactNode;
  navigationBar: ReactChild;
  mobileMenuBar: ReactChild;
}

export function NavigationBarContainer({
  children,
  navigationBar,
  mobileMenuBar,
}: NavigationBarContainerProps) {
  const isLoadboardResponsivenessEnabled = useFlag('loadboard_responsiveness');
  const isCarrierResponsivenessEnabled = useFlag('carrier_tms_responsiveness');
  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';

  const isLoadboard = import.meta.env.VITE_APP_NAME === 'slbd';
  const isCTMS = import.meta.env.VITE_APP_NAME === 'ctms';

  const isDestopNavigationEnabled =
    !isMobile ||
    (!isCarrierResponsivenessEnabled && isCTMS) ||
    (!isLoadboardResponsivenessEnabled && isLoadboard);

  return (
    <NavigationBarContainerRoot>
      {isDestopNavigationEnabled && navigationBar}
      {((isCarrierResponsivenessEnabled && isCTMS) ||
        (isLoadboardResponsivenessEnabled && isLoadboard)) &&
      isMobile ? (
        <Stack>
          <Paper hasBorder={!isMobile}>
            <NavigationBarContainerContent isMobile={isMobile}>
              <Suspense fallback={<ContentProgressIndicator />}>
                {children}
              </Suspense>
            </NavigationBarContainerContent>
          </Paper>
          <NavigationBarContainerBottom>
            {mobileMenuBar}
          </NavigationBarContainerBottom>
        </Stack>
      ) : (
        <NavigationBarContainerContent isMobile={isMobile}>
          <Suspense fallback={<ContentProgressIndicator />}>
            {children}
          </Suspense>
        </NavigationBarContainerContent>
      )}
    </NavigationBarContainerRoot>
  );
}
