import {
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormikCheckboxField, FormikTextField } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { SettingsPageForm } from './core/SettingsPageLayout';
import { useBillingSettings, useSettingsAPI } from './data/SettingsAPI';
import { billingSettingsSchema } from './data/SettingsDTO';

export function SettingsBillingPage() {
  const { data } = useBillingSettings();
  const { patchBillingSettings } = useSettingsAPI();
  const formik = useAppFormik({
    initialValues: data,
    validationSchema: billingSettingsSchema,
    onSubmit(values) {
      return patchBillingSettings(values, data);
    },
    getSuccessMessage() {
      return 'Billing Settings successfully updated';
    },
  });

  return (
    <>
      <DocumentTitle title="Billing - Settings" />

      <SettingsPageForm formik={formik} title="Billing" loading={!data}>
        <Card>
          <CardContent>
            <Stack space="small">
              <FormikCheckboxField
                name="attach_bol"
                label="Attach BOL to Invoices"
              />

              <FormikCheckboxField
                name="include_dates_on_bol"
                label="Add pickup/delivery dates to BOL"
              />

              <FormikTextField
                name="bcc_email"
                label={
                  <Inline verticalAlign="center">
                    <Typography>
                      Send a blind copy (BCC) of an invoice to this email
                    </Typography>
                    <Tooltip title="Learn more" placement="top">
                      <IconButton
                        color="inherit"
                        target="_blank"
                        size="small"
                        rel="noopener noreferrer"
                        href="https://support.superdispatch.com/hc/en-us/articles/38189948524307-Send-a-Copy-of-an-Invoice-To-Yourself-In-Carrier-TMS"
                      >
                        <Info fontSize="small" color="action" />
                      </IconButton>
                    </Tooltip>
                  </Inline>
                }
              />
            </Stack>
          </CardContent>
        </Card>
      </SettingsPageForm>
    </>
  );
}
