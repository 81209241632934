import { Menu, MenuItem } from '@material-ui/core';
import { Cancel, MoreHoriz, PowerSettingsNew } from '@material-ui/icons';
import {
  Color,
  ColorDynamic,
  Inline,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { AccountOffIcon as MuiAccountOffIcon } from 'shared/icons';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { MenuButton } from 'shared/ui/MenuButton';
import styled from 'styled-components';
import { DriverDTO } from '../data/DriverDTO';
import { DriversPageMutationDialogType } from '../DriversPageMutationDialog';
import { DriverDeactivationPlanUpdateDialog } from './DriverDeactivationPlanUpdateDialog';

interface DriverDetailsMenuButtonProps {
  currentDriver: DriverDTO;
  setCurrentModal: (modal: DriversPageMutationDialogType) => void;
}

const AccountOffIcon = styled(MuiAccountOffIcon)`
  width: 20px;
  height: 20px;
`;

export function DriverDetailsMenuButton({
  currentDriver,
  setCurrentModal,
}: DriverDetailsMenuButtonProps) {
  const isMobile = useResponsiveValue(true, false);
  const shouldUseSeatBasedPricing = useFlag('seats_based_pricing');
  const isMultiCarrierConnectionEnabled = useFlag(
    'multi_carrier_connection_management',
  );
  const [shouldShowPlanUpdateDrawer, setShouldShowPlanUpdateDrawer] =
    useState(false);
  const { data } = useCarrierSettings();
  const isPaying = data?.is_paying;

  return (
    <>
      <DriverDeactivationPlanUpdateDialog
        open={shouldShowPlanUpdateDrawer}
        onClose={() => {
          setShouldShowPlanUpdateDrawer(false);
        }}
        onSubmitSuccess={() => {
          setShouldShowPlanUpdateDrawer(false);
          setCurrentModal('deactivate');
        }}
      />
      {currentDriver.is_suspended ? (
        <Inline>
          {isMobile ? (
            <MenuButton
              size="small"
              aria-label="driver options"
              renderMenu={({ onClose, ...menuProps }) => (
                <Menu {...menuProps} onClose={onClose}>
                  <MenuItem
                    key="reactivate"
                    onClick={() => {
                      onClose();
                      setCurrentModal('reactivate');
                    }}
                  >
                    <TextBox>Reactivate</TextBox>
                  </MenuItem>
                  <MenuItem
                    key="remove"
                    onClick={() => {
                      onClose();
                      setCurrentModal('delete');
                    }}
                  >
                    <TextBox>Remove</TextBox>
                  </MenuItem>
                </Menu>
              )}
            >
              <MoreHoriz />
            </MenuButton>
          ) : (
            <>
              {!isMultiCarrierConnectionEnabled && (
                <Button
                  variant="neutral"
                  onClick={() => {
                    setCurrentModal('reactivate');
                  }}
                >
                  <Inline noWrap={true} space="xxsmall" verticalAlign="center">
                    <PowerSettingsNew
                      htmlColor={Color.Dark100}
                      fontSize="small"
                    />
                    Reactivate
                  </Inline>
                </Button>
              )}
              <Button
                variant="neutral"
                onClick={() => {
                  setCurrentModal('delete');
                }}
              >
                <Inline noWrap={true} space="xxsmall" verticalAlign="center">
                  <Cancel htmlColor={ColorDynamic.Red500} fontSize="small" />
                  Remove
                </Inline>
              </Button>
            </>
          )}
        </Inline>
      ) : (
        <Button
          onClick={() => {
            if (shouldUseSeatBasedPricing && isPaying) {
              setShouldShowPlanUpdateDrawer(true);
            } else {
              setCurrentModal('deactivate');
            }
          }}
          variant="neutral"
        >
          <Inline noWrap={true} space="xxsmall" verticalAlign="center">
            <AccountOffIcon htmlColor={Color.Dark100} />
            Deactivate
          </Inline>
        </Button>
      )}
    </>
  );
}
