import {
  CircularProgress,
  Fade,
  Link,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { useDeepEqualMemo } from '@superdispatch/hooks';
import { Inline, Stack } from '@superdispatch/ui';
import { Alert, AnchorButton, Box, Button } from '@superdispatch/ui-lab';
import { lazy, Suspense, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { BankErrorBanner } from 'shared/errors/SuperPayBankErrors';
import { trim } from 'shared/helpers/CommonHelpers';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { useSet } from 'shared/helpers/ReactHelpers';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { LocalStore } from 'shared/helpers/Store';
import useWindowSize from 'shared/hooks/useWindowSize';
import { WeakPasswordIcon } from 'shared/icons/WeakPasswordIcon';
import { DontShowAgainButton } from 'shared/layout/notification-stack/core/DontShowAgainButton';
import { useNotifications } from 'shared/layout/notification-stack/data/NotificationAPI';
import {
  NotificationType,
  NotificationVariant,
} from 'shared/layout/notification-stack/data/NotificationDTO';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { useShowCelebrationNotification } from 'shared/modules/subscription/core/useShowCelebrationNotification';
import { LinkButton } from 'shared/routing/Links';
import {
  SuperPayVerificationStatus,
  useCarrierSettings,
} from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { shouldShowUserChangePassword } from '../../../carrier/dispatchers/data/DispatcherProfileSecurityEnhancement';
import { CelebrationNotification } from './core/CelebrationNotification';
import { useVerificationNotificationsHidden } from './core/HideVerificationNotifications';
import { SignUpCelebrationNotification } from './core/SignupCelebrationNotification';
import { trackNotificationEvent } from './data/NotificationAnalytics';
import { useTrialExpiredNotificationStorageService } from './data/NotificationUtils';
import {
  useClickedUpdatePasswordBanner,
  useIsClickedUpdatePassword,
} from './data/PasswordBannerStorage';

const Confetti = lazy(() => import('react-confetti'));

const IGNORED_NOTIFICATIONS = new Set<NotificationType>([
  'super_loadboard_here',
]);

const TRANSIENT_NOTIFICATIONS = new Set<NotificationType>([
  'verified_carrier_request_pending',
  'verified_carrier_request_approved',
]);

const NOTIFICATION_EXCLUDED_ROUTES: Partial<Record<NotificationType, string>> =
  {
    superpay_bank_account_not_verified: '/profile/superpay',
    superpay_not_setup: '/profile/superpay',
    superpay_bank_account_verification_failed: '/profile/superpay',
    superpay_bank_account_payment_error: '/profile/superpay',
  };

const AlertSnackbar = styled(Snackbar)`
  left: 112px;
  bottom: 32px;
  max-width: 512px;
  padding: 0;

  & .MuiAlert-root {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.14), 0 3px 14px rgba(0, 0, 0, 0.12),
      0 4px 5px rgba(0, 0, 0, 0.2);

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    left: 0;
    bottom: 0;
    width: 100%;
  }

  @media print {
    display: none;
  }
`;

const AlertContent = styled(Box)`
  width: 400px;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

function TransitionComponent(props: SlideProps) {
  return <Slide {...props} direction="right" />;
}

interface NotificationProps {
  onClose: () => void;
  notification: NotificationVariant;
  superPayVerificationStatus: SuperPayVerificationStatus | undefined;
}

function Notification({
  onClose,
  notification,
  superPayVerificationStatus,
}: NotificationProps) {
  const { showLauncherMessanger } = useCustomerSupport();
  const { shouldShowCelebrationNotification, closeCelebrationNotification } =
    useShowCelebrationNotification();
  const [utm_source] = useSearchParam('utm_source');
  const shouldShowCarrierSignupCelebration = useFlag(
    'carrier_signup_celebration',
  );
  const { setClickedUpdatePassword } = useIsClickedUpdatePassword();
  const { closeBanner } = useClickedUpdatePasswordBanner();
  const isTrialExpired = notification.notification_type === 'trial_expired';
  const { isActiveTrialExpiredNotificationOpen, setSentTime } =
    useTrialExpiredNotificationStorageService(isTrialExpired, onClose);

  const closeUpdatePassword = () => {
    onClose();
    setClickedUpdatePassword();
  };

  const closeTrialExpired = () => {
    onClose();
    setSentTime();
  };

  if (utm_source === 'carrier_signup' && shouldShowCarrierSignupCelebration) {
    return <SignUpCelebrationNotification />;
  }

  if (shouldShowCelebrationNotification) {
    return <CelebrationNotification onClose={closeCelebrationNotification} />;
  }

  if (
    notification.notification_type === 'trial_expired' &&
    !isActiveTrialExpiredNotificationOpen
  ) {
    return (
      <Alert severity="critical" onClose={closeTrialExpired}>
        <Inline verticalAlign="center">
          {notification.message}
          <LinkButton
            size="small"
            variant="neutral"
            to="/settings/subscription"
            onClick={() => {
              closeTrialExpired();
              trackNotificationEvent({
                name: 'Carrier Clicked Upgrade Now Alert',
              });
            }}
          >
            Upgrade Now
          </LinkButton>
        </Inline>
      </Alert>
    );
  }

  switch (notification.notification_type) {
    case 'trial_countdown':
      return (
        <Alert severity="caution" onClose={onClose}>
          <Inline verticalAlign="center">
            <span>{notification.message}</span>
            <AnchorButton
              size="small"
              variant="neutral"
              href="/billing/plan/upgrade/"
            >
              Upgrade
            </AnchorButton>
          </Inline>
        </Alert>
      );

    case 'payment_past_due':
      return (
        <Alert onClose={onClose} severity="caution">
          <Inline space="small" verticalAlign="center">
            <span>{notification.message}</span>

            <AnchorButton
              variant="neutral"
              size="small"
              target="_blank"
              href="/billing/payment/"
              onClick={() => {
                onClose();
              }}
            >
              Update Payment
            </AnchorButton>
          </Inline>
        </Alert>
      );

    case 'super_loadboard_here':
      return (
        <Alert severity="info" onClose={onClose}>
          Super Loadboard is here! Check it out and{' '}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={notification.context.profile_url}
            onClick={() => {
              onClose();
            }}
          >
            set up your account
          </Link>{' '}
          to become a Super Carrier.{' '}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.superdispatch.com/hc/en-us/articles/35661884290707-What-is-Verified-Carrier-Status"
            onClick={() => {
              onClose();
            }}
          >
            Learn more...
          </Link>
        </Alert>
      );

    case 'upgrade_plan':
      return (
        <Alert severity="caution" onClose={onClose}>
          <Inline verticalAlign="center">
            <span>{notification.message}</span>

            <AnchorButton
              variant="neutral"
              size="small"
              target="_blank"
              href="/billing/plan/upgrade/"
              onClick={() => {
                onClose();
              }}
            >
              Upgrade
            </AnchorButton>
          </Inline>
        </Alert>
      );

    case 'verified_carrier_status_revoked':
      return (
        <Alert severity="critical" onClose={onClose}>
          <Inline verticalAlign="center">
            <span>{notification.message}</span>

            <AnchorButton
              size="small"
              variant="default"
              target="_blank"
              href="/tms/profile/cargo_insurance"
              onClick={() => {
                onClose();
              }}
            >
              Resubmit Documents
            </AnchorButton>

            <DontShowAgainButton
              notificationType={notification.notification_type}
              onClick={onClose}
            />
          </Inline>
        </Alert>
      );

    case 'verified_carrier_request_pending':
      return (
        <Alert severity="info" onClose={onClose}>
          {notification.message}
        </Alert>
      );

    case 'verified_carrier_request_declined':
      return (
        <Alert severity="critical" onClose={onClose}>
          <Inline verticalAlign="center">
            <span>{notification.message}</span>

            <AnchorButton
              size="small"
              variant="default"
              target="_blank"
              href="/tms/profile"
              onClick={() => {
                onClose();
              }}
            >
              Resubmit Documents
            </AnchorButton>

            <DontShowAgainButton
              notificationType={notification.notification_type}
              onClick={onClose}
            />
          </Inline>
        </Alert>
      );

    case 'verified_carrier_request_approved':
      return (
        <Alert severity="positive" onClose={onClose}>
          <Stack space="xsmall">
            <Typography>{notification.message}</Typography>

            {superPayVerificationStatus !== 'verified' ? (
              <>
                <Typography>
                  Next, set up SuperPay to streamline pay!
                </Typography>
                <AnchorButton
                  size="small"
                  variant="neutral"
                  target="_blank"
                  href="/tms/profile/superpay?utm_medium=Alert Banner Carrier Verification Confirmation"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Set Up SuperPay
                </AnchorButton>
              </>
            ) : (
              <AnchorButton
                size="small"
                variant="neutral"
                target="_blank"
                href="/loadboard/loads?load_alert=create"
                onClick={() => {
                  onClose();
                }}
              >
                Create free Load Alerts
              </AnchorButton>
            )}
          </Stack>
        </Alert>
      );

    case 'verified_carrier_request_reviewed':
      return (
        <Alert severity="caution" onClose={onClose}>
          <Inline verticalAlign="center">
            <Typography>{notification.message}</Typography>

            <AnchorButton
              size="small"
              variant="default"
              target="_blank"
              href="/tms/profile/cargo_insurance"
              onClick={() => {
                onClose();
              }}
            >
              Review
            </AnchorButton>

            <DontShowAgainButton
              notificationType={notification.notification_type}
              onClick={onClose}
            />
          </Inline>
        </Alert>
      );

    case 'verify_fmcsa_phone_number':
      return (
        <Alert severity="caution" onClose={onClose}>
          <Inline verticalAlign="center">
            <Typography>{notification.message}</Typography>

            <AnchorButton
              size="small"
              variant="neutral"
              target="_blank"
              href="/tms/profile/phone-verification"
              onClick={() => {
                onClose();
                trackNotificationEvent({
                  name: 'Carrier Clicked Verify FMCSA Phone Number Snackbar',
                });
              }}
            >
              Verify Now
            </AnchorButton>
          </Inline>
        </Alert>
      );

    case 'superpay_bank_account_payment_error':
      return (
        <BankErrorBanner
          source="Alert Banner"
          paymentError={notification.context}
          titlePrefix="SuperPay"
          onClose={onClose}
        />
      );

    case 'superpay_not_setup':
      return (
        <Alert severity="caution" onClose={onClose}>
          <AlertContent>
            <Stack>
              <Typography>To receive payment, setup SuperPay.</Typography>

              <AnchorButton
                size="small"
                variant="neutral"
                target="_blank"
                href="/tms/profile/superpay?utm_medium=Alert Banner Set Up SuperPay"
                onClick={() => {
                  onClose();
                }}
              >
                Setup SuperPay
              </AnchorButton>
            </Stack>
          </AlertContent>
        </Alert>
      );

    case 'superpay_bank_account_not_verified':
      return (
        <Alert severity="caution" onClose={onClose}>
          <AlertContent>
            <Stack>
              <Typography>
                To receive payment, complete Bank Account verification.
              </Typography>

              <AnchorButton
                size="small"
                variant="neutral"
                target="_blank"
                href="/tms/profile/superpay/onboarding/account-details/verify-bank-account?utm_medium=Alert Banner Complete Bank Account Verification"
                onClick={onClose}
              >
                Verify Bank Account
              </AnchorButton>
            </Stack>
          </AlertContent>
        </Alert>
      );

    case 'superpay_bank_account_verification_failed':
      return (
        <Alert severity="critical" onClose={onClose}>
          <AlertContent>
            <Stack>
              <Typography>
                SuperPay bank account verification failed.
              </Typography>

              <Button
                size="small"
                variant="neutral"
                onClick={() => {
                  showLauncherMessanger(
                    'I attempted to set up SuperPay but my bank account verification had an error. Can you help?',
                  );
                  onClose();
                }}
              >
                Contact Support
              </Button>
            </Stack>
          </AlertContent>
        </Alert>
      );

    case 'update_password':
      return (
        <Alert
          icon={<WeakPasswordIcon />}
          severity="info"
          onClose={closeUpdatePassword}
        >
          <Stack space="xsmall">
            <Typography>
              Enhance account security. We recommend that you update your
              password to meet the new standards.
            </Typography>
            <LinkButton
              to="/dispatchers/profile/change-password"
              size="small"
              variant="neutral"
              onClick={() => {
                closeUpdatePassword();
                trackNotificationEvent({
                  name: 'Carrier Clicked Update Password Banner',
                });
                closeBanner();
              }}
            >
              Update My Password
            </LinkButton>
          </Stack>
        </Alert>
      );

    default:
      return null;
  }
}

export function NotificationStack() {
  const { width, height } = useWindowSize();
  const { pathname } = useLocation();
  const verificationNotificationsHidden = useVerificationNotificationsHidden();
  const isShowUpdatePasswordEnabled = useFlag('show_update_password');
  const { updatePasswordReminder } = useIsClickedUpdatePassword();
  const { data: carrierSettings } = useCarrierSettings();
  const { data: dispatcher } = useDispatcherProfile();
  const { shouldShowCelebrationNotification, shouldShowConfetti } =
    useShowCelebrationNotification();
  const superpayOnboardingStatus = carrierSettings?.superpay.verification;

  const { data } = useNotifications();

  const notifications = useMemo<NotificationVariant[] | undefined>(() => {
    const apiNotifications = Array.from(data || []);

    const shouldShowReminder = () =>
      dispatcher?.created_at &&
      shouldShowUserChangePassword(
        dispatcher.created_at,
        dispatcher.p_changed_at,
      );

    if (
      isShowUpdatePasswordEnabled &&
      !updatePasswordReminder &&
      shouldShowReminder()
    ) {
      apiNotifications.push({
        notification_type: 'update_password',
      });
    }

    return apiNotifications;
  }, [
    data,
    isShowUpdatePasswordEnabled,
    updatePasswordReminder,
    dispatcher?.p_changed_at,
    dispatcher?.created_at,
  ]);

  const [closedBanners, addClosedBanner] = useSet(
    () =>
      new Set(
        trim(LocalStore.get('closed_notification_banners'))
          .split(',')
          .filter(
            (name: NotificationType) =>
              TRANSIENT_NOTIFICATIONS.has(name) ||
              verificationNotificationsHidden(name),
          ),
      ),
  );

  useEffect(() => {
    LocalStore.set(
      'closed_notification_banners',
      Array.from(closedBanners).join(','),
    );
  }, [closedBanners]);

  const [isOpen, firstNotification] = useDeepEqualMemo<
    [boolean, NotificationVariant?]
  >(() => {
    if (!notifications?.length) {
      return [false, undefined];
    }

    for (const notification of notifications) {
      const excludedRoute =
        NOTIFICATION_EXCLUDED_ROUTES[notification.notification_type];

      if (
        !(excludedRoute && pathname.startsWith(excludedRoute)) &&
        !closedBanners.has(notification.notification_type) &&
        !IGNORED_NOTIFICATIONS.has(notification.notification_type)
      ) {
        return [true, notification];
      }
    }

    return [false, notifications[0]];
  }, [notifications, pathname, closedBanners]);

  return (
    <>
      <AlertSnackbar
        open={isOpen || !!shouldShowCelebrationNotification}
        TransitionComponent={TransitionComponent}
        key={firstNotification?.notification_type}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div>
          {firstNotification && (
            <Notification
              notification={firstNotification}
              superPayVerificationStatus={superpayOnboardingStatus}
              onClose={() => {
                addClosedBanner(firstNotification.notification_type);
              }}
            />
          )}
        </div>
      </AlertSnackbar>
      <Fade in={!!shouldShowConfetti}>
        {shouldShowConfetti ? (
          <Suspense fallback={<CircularProgress />}>
            <Confetti width={width - 100} height={height} />
          </Suspense>
        ) : (
          <Box />
        )}
      </Fade>
    </>
  );
}
