import { ButtonBase, Zoom } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ColorDynamic } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { useDismissOnboarding } from './LoadSuggestionOnboarding';

const DismissContainer = styled(ButtonBase)`
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 32px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${ColorDynamic.Dark100};
  color: ${ColorDynamic.White};
`;

const DISMISS_THRESHOLD = 80; // px

export interface Props {
  onDismiss: () => void;
  children?: React.ReactNode;
  isDismissing?: boolean;
  shouldRunOnboarding?: boolean;
}

export function LoadSuggestionSwipeToDismiss({
  onDismiss,
  children,
  isDismissing,
  shouldRunOnboarding,
}: Props) {
  const [translate, setTranslate] = useState(0);
  const containerWidth = useRef(0);
  const dismissButtonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const initTranslate = useRef(0);
  const { isOnboardingRunning } = useDismissOnboarding({
    shouldRunOnboarding,
    contentRef,
    dismissButtonRef,
    setTranslate,
  });

  const handleDismiss = useCallback(() => {
    setTranslate(0);
    onDismiss();
  }, [onDismiss]);

  const swipeHandlers = useSwipeable({
    onSwipeStart: () => {
      initTranslate.current = translate;
    },
    onSwiping: (event) => {
      if (isOnboardingRunning) return;

      if (event.deltaX > 0 - initTranslate.current) {
        setTranslate(0);
        return;
      }

      if (event.dir === 'Left') {
        setTranslate(event.deltaX + initTranslate.current);
      }
    },
    onTouchEndOrOnMouseUp: () => {
      if (!containerWidth.current || isOnboardingRunning) return;

      const shouldShowDismiss = translate < -DISMISS_THRESHOLD * 0.7;
      const isImmediateDismissTriggered =
        -1 * translate >= containerWidth.current * (DISMISS_THRESHOLD / 100);

      if (isImmediateDismissTriggered) {
        setTranslate(() => -containerWidth);
        handleDismiss();
      } else if (shouldShowDismiss) {
        setTranslate(() => -1 * DISMISS_THRESHOLD);
      } else {
        setTranslate(() => 0);
      }
    },
  });

  useEffect(() => {
    if (contentRef.current && dismissButtonRef.current) {
      contentRef.current.style.transform = `translateX(${translate}px)`;
      dismissButtonRef.current.style.width = `${translate * -1 - 8}px`;
    }
  }, [translate]);

  return (
    <Box
      as="div"
      ref={(node: HTMLDivElement | null) => {
        if (containerWidth.current === 0 && node) {
          containerWidth.current = node.getBoundingClientRect().width;
        }
      }}
      marginBottom="small"
      position="relative"
    >
      <DismissContainer
        ref={dismissButtonRef}
        onClick={handleDismiss}
        data-cy="Dismiss Suggestion Button"
        disabled={isDismissing || isOnboardingRunning}
      >
        <Zoom in={translate * -1 > 50}>
          <Close fontSize="small" />
        </Zoom>
      </DismissContainer>
      <div
        {...swipeHandlers}
        ref={(node) => {
          contentRef.current = node;
          swipeHandlers.ref(node);
        }}
      >
        {children}
      </div>
    </Box>
  );
}
