import {
  Card,
  CardActionArea,
  CardContent,
  Fade,
  Typography,
} from '@material-ui/core';
import { FiberManualRecord, Star, ThumbUp } from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  ElementVisibility,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { forwardRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatCurrency, formatNumber } from 'shared/helpers/IntlHelpers';
import { BookNowIcon } from 'shared/icons/BookNowIcon';
import { LoadSuggestionsVehicles } from 'shared/modules/load-suggestions/core/LoadSuggestionsVehicles';
import { LoadSuggestionsVenues } from 'shared/modules/load-suggestions/core/LoadSuggestionsVenues';
import { useTrackViewedLoad } from 'shared/modules/load-suggestions/data/LoadSuggestionAnalytics';
import { LoadSuggestionDTO } from 'shared/modules/load-suggestions/data/LoadSuggestionDTO';
import { PostingPrivateTag } from 'shared/modules/loadboard/PostingPrivateTag';
import { FormattedRelativeTimeToNow } from 'shared/ui/FormattedRelativeTimeToNow';
import { InlineBulletItems } from 'shared/ui/InlineBulletItems';
import { PaymentInfo } from 'shared/ui/PaymentInfo';
import { updateSearchQuery } from 'shared/utils/URLUtils';
import styled from 'styled-components';
import { LoadSuggestionsActivity } from '../LoadSuggestionsActivity';

const PriceBox = styled(TextBox)`
  line-height: normal;
`;
const StyledCard = styled(Card)`
  position: relative;
  && .MuiCardActionArea-focusHighlight {
    opacity: 0;
  }
`;
const ExclusiveLoadBadge = styled.div(({ theme }) => {
  const mode = theme.palette.type;
  const color =
    mode === 'dark'
      ? 'rgba(255, 198, 29, 0.00) 70%'
      : 'rgba(255, 255, 255, 0.00) 70%';

  return `
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 164px;
  height: 164px;
  background: radial-gradient(62.97% 62.53% at 93.29% 93.6%, #FFC61A 0%, ${color})
  `;
});
const StyledStar = styled(Star)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: white;
`;

interface LoadSuggestionsListItemProps {
  load: LoadSuggestionDTO;
  visibilityState: ElementVisibility;
  shouldRunOnboarding?: boolean;
  onClick?: () => void;
}

export const LoadSuggestionsListItem = forwardRef<
  HTMLDivElement,
  LoadSuggestionsListItemProps
>(({ load, visibilityState, shouldRunOnboarding, onClick }, ref) => {
  const navigate = useNavigate();
  const location = useLocation();

  const openPostingScreen = (source?: {
    utm_medium: 'Suggested Load';
    utm_content: 'Suggested Tab';
  }) => {
    navigate({
      pathname: '/suggested-loads',
      search: updateSearchQuery(location.search, (prev) => ({
        ...prev,
        ...source,
        current_load_guid: load.guid,
      })),
    });
  };

  useTrackViewedLoad({
    loadVisibilityState: visibilityState,
    load,
  });

  return (
    <StyledCard
      data-cy="LoadCard"
      ref={ref}
      onClick={() => {
        openPostingScreen({
          utm_content: 'Suggested Tab',
          utm_medium: 'Suggested Load',
        });
        onClick?.();
      }}
    >
      <CardActionArea>
        <CardContent>
          <Stack space="small">
            <Stack space="xxsmall">
              {!!load.driver_names && (
                <LoadSuggestionsActivity driverNames={load.driver_names} />
              )}
              <Columns align="center" space="medium">
                <Column width="fluid">
                  <Inline verticalAlign="center" space="xxsmall">
                    <PriceBox
                      variant="heading-3"
                      color={load.carrier_access.can_book ? 'blue' : 'inherit'}
                    >
                      {formatCurrency(load.price, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                      })}
                    </PriceBox>

                    {load.carrier_access.can_book && (
                      <BookNowIcon
                        htmlColor={Color.Blue300}
                        // eslint-disable-next-line react/forbid-component-props
                        style={{ width: '20px', height: '20px' }}
                      />
                    )}

                    {load.is_posted_to_private_loadboard && (
                      <PostingPrivateTag />
                    )}
                  </Inline>
                </Column>

                <Column width="content">
                  <Box data-intercom-target="load-suggestions-unseen-indicator">
                    <Inline
                      space="xxsmall"
                      verticalAlign="center"
                      horizontalAlign="right"
                    >
                      {!load.is_seen && shouldRunOnboarding && (
                        <Typography color="primary">unseen load</Typography>
                      )}
                      <Fade
                        in={!load.is_seen}
                        timeout={{ enter: 0, appear: 0, exit: 500 }}
                      >
                        <Box fontSize="12px">
                          <FiberManualRecord
                            fontSize="inherit"
                            color="primary"
                          />
                        </Box>
                      </Fade>

                      <Typography color="textSecondary" align="right">
                        <FormattedRelativeTimeToNow
                          date={load.posted_to_loadboard_at}
                        />
                      </Typography>
                    </Inline>
                  </Box>
                </Column>
              </Columns>

              <Inline space="xsmall">
                <PaymentInfo
                  method={load.payment.method}
                  terms={load.payment.terms}
                  source="Suggested Load"
                  superPayLabelTarget="_blank"
                />

                <InlineBulletItems>
                  {!!load.price_per_mile && (
                    <TextBox variant="caption" color="secondary">
                      {formatCurrency(load.price_per_mile, {
                        maximumFractionDigits: 2,
                      })}
                      /mi
                    </TextBox>
                  )}
                  {!!load.distance_miles && (
                    <TextBox variant="caption" color="secondary">
                      {formatNumber(load.distance_miles, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      mi
                    </TextBox>
                  )}
                </InlineBulletItems>
              </Inline>
              <TextBox color="secondary">
                <Inline space="xsmall" verticalAlign="center">
                  {load.shipper.name}
                  {!!load.shipper.rating_details && (
                    <Inline space="xxsmall" verticalAlign="center">
                      <ThumbUp fontSize="inherit" color="action" />
                      <TextBox color="inherit">
                        {load.shipper.rating_details.overall_rating}%
                      </TextBox>
                    </Inline>
                  )}
                </Inline>
              </TextBox>
            </Stack>
            <Stack space="small">
              <LoadSuggestionsVenues
                pickup={load.pickup}
                delivery={load.delivery}
              />

              <LoadSuggestionsVehicles
                vehicles={load.vehicles}
                transportType={load.transport_type}
              />
            </Stack>
          </Stack>
          {load.is_exclusive && (
            <ExclusiveLoadBadge>
              <StyledStar />
            </ExclusiveLoadBadge>
          )}
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
});

LoadSuggestionsListItem.displayName = 'LoadSuggestionsListItem';
