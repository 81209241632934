import { Column, Columns, Tag, useResponsiveValue } from '@superdispatch/ui';
import {
  Sidebar,
  SidebarContainer,
  SidebarMenuItem,
} from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { SidebarMenuItemLink } from 'shared/routing/SidebarMenuItemLink';

interface SettingsSidebarProps {
  href: string;
  children?: ReactNode;
}

function SettingsSidebarExternalItem({ href, children }: SettingsSidebarProps) {
  return (
    <SidebarMenuItem
      external={true}
      onClick={() => {
        openExternalURL(href, { target: '_blank' });
      }}
    >
      {children}
    </SidebarMenuItem>
  );
}

export function SettingsSidebar() {
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();

  return (
    <Sidebar title="Settings">
      <SidebarMenuItemLink to="/settings/general/">General</SidebarMenuItemLink>
      <SidebarMenuItemLink to="/settings/terminals/">
        Terminals
      </SidebarMenuItemLink>
      <SidebarMenuItemLink to="/settings/driver-app/">
        Driver App
      </SidebarMenuItemLink>
      <SidebarMenuItemLink to="/settings/billing/">Billing</SidebarMenuItemLink>
      <SidebarMenuItemLink to="/settings/factoring/">
        Factoring
      </SidebarMenuItemLink>
      <SidebarMenuItemLink to="/settings/quickbooks/">
        Quickbooks Integration
      </SidebarMenuItemLink>
      <SidebarMenuItemLink to="/settings/subscription/">
        <Columns space="xsmall">
          <Column width="fluid">Subscription Details</Column>
          <Column width="content">
            {doesBillingAddressNotExist && (
              <Tag variant="subtle" color="yellow">
                To Do
              </Tag>
            )}
          </Column>
        </Columns>
      </SidebarMenuItemLink>
      <SidebarMenuItemLink to="/settings/notifications/">
        Notifications
      </SidebarMenuItemLink>
      <SettingsSidebarExternalItem href="/loadboard/settings/notifications/">
        Loadboard Notifications
      </SettingsSidebarExternalItem>
    </Sidebar>
  );
}

export function SettingsContainerOutlet() {
  const isMobile = useResponsiveValue(true, true, false);

  if (isMobile) {
    return <Outlet />;
  }

  return (
    <SidebarContainer sidebar={<SettingsSidebar />}>
      <Outlet />
    </SidebarContainer>
  );
}
