import { useDeepEqualValue } from '@superdispatch/hooks';
import { createContext, ReactNode, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchQueryText } from 'shared/helpers/ReactHelpers';
import { useNullableContext } from 'shared/hooks/useNullableContext';
import { usePromptNavigate } from 'shared/routing/NavigationBlock';

export interface ContactsPageRouter {
  rootURL: string;
  currentContactGUID: string | undefined;
  openContact: (guid: string, replace?: boolean) => void;
  openList: () => void;
  currentModal: 'add' | 'import' | undefined;
  setCurrentModal: (modal?: 'add' | 'import' | undefined) => void;
  searchText: string;
  searchQuery: string | undefined;
  setSearchText: (text: string) => void;
}

const context = createContext<null | ContactsPageRouter>(null);

export function useContactsPageRouter(): ContactsPageRouter {
  return useNullableContext('ContactsPageRouter', context);
}

export interface ContactsPageRouterProviderProps {
  children?: ReactNode;
}

const rootURL = '/contacts';

export function ContactsPageRouterProvider({
  children,
}: ContactsPageRouterProviderProps) {
  const { contactGUID: currentContactGUID } = useParams();
  const [currentModal, setCurrentModal] = useState<'add' | 'import'>();
  const [searchText, searchQuery, setSearchText] = useSearchQueryText();

  const navigate = usePromptNavigate();
  const openContact = useCallback(
    (guid: string, replace?: boolean) => {
      navigate(`${rootURL}/${guid}`, { replace });
    },
    [navigate],
  );

  const openList = useCallback(() => {
    navigate(rootURL);
  }, [navigate]);

  const ctx = useDeepEqualValue({
    rootURL,
    openContact,
    openList,
    currentContactGUID,
    currentModal,
    setCurrentModal,
    searchText,
    searchQuery,
    setSearchText,
  });

  return <context.Provider value={ctx}>{children}</context.Provider>;
}
