import { Link, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { trackEvent } from 'shared/helpers/Analytics';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { BankPaymentErrorDTO } from 'shared/modules/superpay/SuperPayDTO';
import { SuperPayBankErrorNotifications } from 'shared/settings/CarrierSettingsAPI';

type Source =
  | 'Load Details'
  | 'SuperPay Settings'
  | 'Load Offer Details'
  | 'Alert Banner'
  | 'Load Tooltip'
  | 'Load List';

function getIntercomDataTarget(code: string) {
  switch (code) {
    case 'R02':
      return 'SuperPay Bank Account Closed';
    case 'R03':
      return 'SuperPay No Bank Account Located';
    case 'R16':
      return 'SuperPay Bank Account Frozen';
    case 'R29':
      return 'SuperPay Originator Unauthorized';
    default:
      return '';
  }
}

function getIntercomMessage(code: string) {
  switch (code) {
    case 'R02':
      return 'Hi, I’m getting a Bank Account Closed error and need to add a new bank account for SuperPay.';
    case 'R03':
      return 'Hi, I’m setting up my bank account for SuperPay and got an error “Account Not Located.';
    case 'R16':
      return 'Hi, I’m getting Bank Account Frozen error and need to add a new bank account for SuperPay.';
    case 'R29':
      return 'Hi, I’m getting Originator Unauthorized error and need to add a new bank account for SuperPay.';
    default:
      return '';
  }
}

interface ErrorMessageBoxProps {
  message: ReactNode;
  action?: ReactNode;
  onClose?: () => void;
}

export function ErrorMessageBox({
  message,
  action,
  onClose,
}: ErrorMessageBoxProps) {
  return (
    <Box width="100%" aria-label="error message">
      <Alert severity="critical" onClose={onClose}>
        <Stack space="xsmall">
          {message}
          {action}
        </Stack>
      </Alert>
    </Box>
  );
}

export function BankErrorMessage({
  paymentError,
  source,
}: {
  paymentError: SuperPayBankErrorNotifications;
  source: Source;
}) {
  const { showLauncherMessanger } = useCustomerSupport();
  return (
    <Stack>
      <Typography>
        {paymentError.name}.{' '}
        {paymentError.description && `${paymentError.description} `}
        {paymentError.action} [Error {paymentError.code}]
      </Typography>
      <Link
        component="button"
        color="inherit"
        data-intercom-target={getIntercomDataTarget(paymentError.code)}
        onClick={(e) => {
          e.preventDefault();
          trackEvent('Clicked Contact Support', {
            utm_medium: source,
            utm_content: `Error ${paymentError.code}`,
          });
          showLauncherMessanger(getIntercomMessage(paymentError.code));
        }}
      >
        Contact support
      </Link>
    </Stack>
  );
}

export function BankErrorSimpleBanner({
  paymentError,
  source,
}: {
  paymentError: SuperPayBankErrorNotifications;
  source: Source;
}) {
  return (
    <Box padding="xsmall" borderRadius="small" backgroundColor="Red50">
      <Columns space="xsmall">
        <Column width="content">
          <Error fontSize="small" color="error" />
        </Column>
        <Column>
          <BankErrorMessage paymentError={paymentError} source={source} />
        </Column>
      </Columns>
    </Box>
  );
}

interface BankErrorBannerProps {
  paymentError: BankPaymentErrorDTO | SuperPayBankErrorNotifications;
  source: Source;
  titlePrefix?: string;
  onClose?: () => void;
}

export function BankErrorBanner({
  source,
  paymentError,
  titlePrefix,
  onClose,
}: BankErrorBannerProps) {
  const { showLauncherMessanger } = useCustomerSupport();
  return (
    <ErrorMessageBox
      onClose={onClose}
      message={
        <Stack space={paymentError.description ? 'medium' : 'none'}>
          <Box>
            <Typography variant="body1">
              {titlePrefix && `${titlePrefix}: `}
              {paymentError.name}
            </Typography>
            <Typography>{paymentError.description}</Typography>
          </Box>
          <Typography>
            {paymentError.action} {`[Error ${paymentError.code}]`}
          </Typography>
        </Stack>
      }
      action={
        <Button
          variant="neutral"
          size="small"
          data-intercom-target={getIntercomDataTarget(paymentError.code)}
          onClick={(e) => {
            e.preventDefault();
            trackEvent('Clicked Contact Support', {
              utm_medium: source,
              utm_content: `Error ${paymentError.code}`,
            });
            showLauncherMessanger(getIntercomMessage(paymentError.code));
          }}
        >
          Contact Support
        </Button>
      }
    />
  );
}
