import { useEffect, useMemo, useState } from 'react';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import styled from 'styled-components';
import { useTrackingDriverAutoUpdatePage } from './core/TrackingDriverUpdates';
import { getTrackingDriverStatus } from './data/TrackingDTO';
import { TrackingMapboxMap } from './TrackingMapboxMap';
import { TrackingSidebar } from './TrackingSidebar';

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`;

export const TrackingPage = () => {
  const { data: drivers, isLoading } = useTrackingDriverAutoUpdatePage();
  const [checkedDriversIds, setCheckedDriversIds] = useState<Set<number>>(
    () => new Set(),
  );
  const [hoveredDriverId, setHoveredDriverId] = useState<number | undefined>();
  const debouncedHoveredDriverId = useDebouncedValue(hoveredDriverId, 200);

  const checkedDrivers = useMemo(
    () =>
      drivers ? drivers.filter((d) => checkedDriversIds.has(d.id)) : undefined,
    [drivers, checkedDriversIds],
  );

  useEffect(() => {
    if (drivers) {
      const nextDriverIDS = new Set<number>();

      for (const driver of drivers) {
        if (getTrackingDriverStatus(driver) === 'tracking_active') {
          nextDriverIDS.add(driver.id);
        }
      }

      setCheckedDriversIds(nextDriverIDS);
    } else {
      setCheckedDriversIds((prev) => (prev.size === 0 ? prev : new Set()));
    }
  }, [drivers]);

  return (
    <>
      <DocumentTitle title="GPS Tracking" />

      <Content>
        <TrackingSidebar
          drivers={drivers}
          isLoading={isLoading}
          checkedDriversIds={checkedDriversIds}
          setCheckedDriversIds={setCheckedDriversIds}
          onDriverHover={setHoveredDriverId}
        />

        <TrackingMapboxMap
          drivers={checkedDrivers}
          hoveredDriverId={debouncedHoveredDriverId}
          onFocusChange={setHoveredDriverId}
        />
      </Content>
    </>
  );
};
