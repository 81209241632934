import { useResponsiveValue } from '@superdispatch/ui';
import { SidebarContainer } from '@superdispatch/ui-lab';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { LoadsContextProvider } from '../loads/data/LoadsContext';
import { LoadsPageContextProvider } from '../loads/loads-page/data/LoadsPageParamsContext';
import { ReportsSidebar } from './core/ReportsSidebar';
import { ReportsContextProvider } from './data/ReportsContext';
import { ReportsAccountsPayablePage } from './ReportsAccountsPayable';
import { ReportsAccountsReceivablePage } from './ReportsAccountsReceivablePage';
import { ReportsCompanyRevenuePage } from './ReportsCompanyRevenuePage';
import { ReportsCustomPage } from './ReportsCustomPage';
import { ReportsDriverPayPage } from './ReportsDriverPayPage';
import { ReportsLoadsPage } from './ReportsLoadsPage';

function ReportsPage() {
  const isMobile = useResponsiveValue(true, false);
  if (isMobile) {
    return <SidebarContainer sidebar={<ReportsSidebar />} />;
  }

  return <Navigate to="/reports/accounts-receivable" />;
}

function ReportsOutlet() {
  const isMobile = useResponsiveValue(true, false);
  if (isMobile) {
    return <Outlet />;
  }

  return (
    <SidebarContainer sidebar={<ReportsSidebar />}>
      <Outlet />
    </SidebarContainer>
  );
}

export function ReportsContainerOutlet() {
  return (
    <LoadsContextProvider>
      <LoadsPageContextProvider>
        <ReportsContextProvider>
          <Outlet />
        </ReportsContextProvider>
      </LoadsPageContextProvider>
    </LoadsContextProvider>
  );
}

export const reportsRoutes: RouteObject[] = [
  {
    path: 'reports/:page?',
    element: <ReportsContainerOutlet />,
    children: [
      {
        path: 'loads',
        element: <ReportsLoadsPage />,
      },
      {
        path: '*',
        element: <ReportsOutlet />,
        children: [
          {
            path: 'driver-pay',
            element: <ReportsDriverPayPage />,
          },
          {
            path: 'accounts-receivable',
            element: <ReportsAccountsReceivablePage />,
          },
          {
            path: 'company-revenue',
            element: <ReportsCompanyRevenuePage />,
          },
          {
            path: 'accounts-payable',
            element: <ReportsAccountsPayablePage />,
          },
          {
            path: 'custom-report/:reportGUID?/:action?',
            element: <ReportsCustomPage />,
          },
        ],
      },

      {
        index: true,
        element: <ReportsPage />,
      },
    ],
  },
];
