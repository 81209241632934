import { DispatcherPageContextProvider } from './data/DispatchersProvider';
import { DispatchersOutlet } from './dispatcher-management/DispatchersOutlet';
import { DispatcherPageDetails } from './dispatcher-management/DispatchersPageDetails';
import { DispatcherProfilePage } from './dispatchers-profile/DispatcherProfilePage';

export const dispatcherRoutes = [
  {
    path: 'dispatchers',
    element: (
      <DispatcherPageContextProvider>
        <DispatchersOutlet />
      </DispatcherPageContextProvider>
    ),
    children: [
      {
        path: 'profile/:action?',
        element: <DispatcherProfilePage />,
      },
      {
        path: ':dispatcherGUID?',
        element: <DispatcherPageDetails />,
      },
    ],
  },
];
