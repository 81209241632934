import { useResponsiveValue } from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { MobileAppBridge } from 'shared/data/MobileAppBridge';
import { ZENDESK_TOKEN_KEY } from 'shared/modules/zendesk/ZendeskData';
import { useFlag } from 'shared/settings/FeatureToggles';
import { hideIntercomLauncher } from '../Intercom';
import { LocalStore } from '../Store';
import {
  hideZendeskMessenger,
  loginZendeskUser,
  setZendeskConversationTags,
} from './Zendesk';
import './zendesk.css';
import { Scope, ZendeskMethod } from './ZendeskTypes';

export const ZendeskApi = (
  scope: Scope,
  method: ZendeskMethod,
  ...args: unknown[]
) => {
  window.zE?.apply(null, [scope, method, ...args]);
};

const API_KEY = '5e126156-0153-445a-bc39-9b442fa5c8ab';
const isLocal = import.meta.env.VITE_APP_TARGET === 'local';

export function initialize(onLoad: () => void) {
  if (
    typeof window === 'undefined' ||
    window.zE ||
    MobileAppBridge.isInjected() ||
    document.getElementById('ze-snippet') ||
    isLocal
  ) {
    return;
  }

  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.type = 'text/javascript';
  script.async = true;
  script.onload = () => {
    onLoad();
    loginZendeskUser(LocalStore.get(ZENDESK_TOKEN_KEY));
  };
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${API_KEY}`;

  document.body.appendChild(script);
}

export function useZendeskInitialize(tag: 'Super_Loadboard' | 'Carrier_TMS') {
  const isZendeskEnabled = useFlag('ctms_zendesk_launcher');
  const isMobile = useResponsiveValue(true, false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isZendeskEnabled) return;

    initialize(() => {
      setIsLoaded(true);
    });
  }, [isZendeskEnabled, isMobile]);

  useEffect(() => {
    if (isLoaded) {
      setZendeskConversationTags([tag]);
    }
  }, [isLoaded, tag]);

  useEffect(() => {
    if (isLoaded && isMobile) {
      hideZendeskMessenger();
      ZendeskApi('messenger:on', 'close', hideZendeskMessenger);
    }
    if (isMobile) {
      hideIntercomLauncher();
    }
  }, [isLoaded, isMobile]);
}
