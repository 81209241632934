import type { GeocodeQueryType } from '@mapbox/mapbox-sdk/services/geocoding';
import { StandardTextFieldProps } from '@material-ui/core';
import { AutocompleteProps } from '@material-ui/lab';
import { dequal } from 'dequal';
import { useState } from 'react';
import { Geocoding } from 'shared/geo/GeoHelpers';
import { formatAddressLine } from 'shared/helpers/AddressHelpers';
import { useDebouncedValue } from 'shared/helpers/ReactHelpers';
import { useGeoPredictions } from './GeoService';

interface GeoFieldCommonProps
  extends Omit<
    AutocompleteProps<Geocoding, true, true, false>,
    'renderInput' | 'defaultValue' | 'value'
  > {
  TextFieldProps?: StandardTextFieldProps;
}

export interface UseGeoFieldPropsOptions {
  types: GeocodeQueryType[];
  countries?: string[];
  formatOptionLabel?: (option: Geocoding) => string;
  proximity?: 'US' | 'CA';
  sortByCountry?: 'US' | 'CA';
}

export function useGeoFieldProps({
  types,
  countries,
  sortByCountry,
  formatOptionLabel = (option) =>
    formatAddressLine(
      option.postcode,
      option.place,
      option.region_short,
      option.country,
    ),
}: UseGeoFieldPropsOptions): GeoFieldCommonProps {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>();
  const debouncedQuery = useDebouncedValue(query, 200);

  const { data: predictions = [], isInitialLoading: isPredictionsLoading } =
    useGeoPredictions(debouncedQuery, types, countries, sortByCountry);
  const isLoading = isOpen && isPredictionsLoading;

  return {
    forcePopupIcon: false,
    onOpen: () => {
      setIsOpen(true);
    },
    onClose: () => {
      setIsOpen(false);
    },
    includeInputInList: true,
    getOptionLabel: (option: Geocoding | string) =>
      typeof option === 'string' ? option : formatOptionLabel(option),
    disableClearable: isLoading as true,
    loading: isLoading,
    options: predictions,
    getOptionSelected: dequal,
    onInputChange: (_, inputValue, reason) => {
      //skipping geocoding request for programmatic updates that usually happens on initial form filling.
      // do not need to show options for already filled address.
      if (inputValue && reason === 'reset') return;

      setQuery(inputValue);
    },
  };
}
