import { Avatar, Link, Typography } from '@material-ui/core';
import { AccountBalance, Help } from '@material-ui/icons';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { BankErrorBanner } from 'shared/errors/SuperPayBankErrors';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { SuperPayBankAccountDTO } from 'shared/modules/superpay/SuperPayDTO';
import styled from 'styled-components';
import { BankValidationErrorBanner } from './BankErrorBanners';
import { BankVerification } from './BankVerification';
import { BankVerificationStatusTag } from './BankVerificationStatusTag';

const BankAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
  background: ${ColorDynamic.Silver200};
`;

const ContactSupportLink = styled(Link)`
  cursor: pointer;
`;

interface Props {
  bankAccount?: SuperPayBankAccountDTO;
  hasAccountFeature?: boolean;
  path: string;
}

export function BankDetails({ bankAccount, hasAccountFeature, path }: Props) {
  const { showLauncherMessanger } = useCustomerSupport();
  const bankName = bankAccount?.bank_name;
  const bankAccountDisplayNumber = bankAccount?.display_number;
  const verificationStatus = bankAccount?.verification_status;
  const shouldShowVerification =
    verificationStatus === 'new' || verificationStatus === 'pending';

  const hasValidationError =
    verificationStatus === 'errored' ||
    verificationStatus === 'failed' ||
    verificationStatus === 'locked';

  if (!bankAccount) {
    return (
      <Stack>
        <Typography variant="h3">Bank Account</Typography>
        <Typography color="textSecondary">No data</Typography>
      </Stack>
    );
  }

  return (
    <Stack space="large">
      {shouldShowVerification && (
        <Box margin="auto" maxWidth="500px">
          {/* The 'path' prop was introduced as a replacement for 'useRouteMatch' in order to facilitate the migration to React Router v6. */}
          <BankVerification
            path={path}
            bankName={bankName}
            hasAccountFeature={hasAccountFeature}
            bankAccountDisplayNumber={bankAccountDisplayNumber}
            bankAccountDateSent={bankAccount.microdeposit_sent_at}
            bankAccountAvailableDate={bankAccount.verification_available_date}
            isVerificationAvailable={!!bankAccount.is_verification_available}
          />
        </Box>
      )}

      <Stack space="medium">
        {bankAccount.payment_error ? (
          <BankErrorBanner
            paymentError={bankAccount.payment_error}
            source="SuperPay Settings"
          />
        ) : (
          hasValidationError && (
            <BankValidationErrorBanner
              verificationStatus={verificationStatus}
            />
          )
        )}

        <Box aria-label="bank account details" margin="auto" maxWidth="496px">
          <Stack space="small">
            <Columns
              reverse={[true, false]}
              align={['top', 'center']}
              collapseBelow="tablet"
              space={['large', 'small']}
            >
              <Column>
                <Typography variant="h3">Bank Account</Typography>
              </Column>

              <Column width="content">
                <Inline verticalAlign="center" space="xxsmall">
                  <Box fontSize="16px">
                    <Help fontSize="inherit" color="action" />
                  </Box>
                  <Box fontSize="14px">
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href="https://support.superdispatch.com/hc/sections/35595227737363-Carrier-TMS"
                    >
                      How SuperPay works
                    </Link>
                  </Box>
                </Inline>
              </Column>
            </Columns>

            <Columns
              collapseBelow="tablet"
              space="small"
              align={['top', 'center']}
            >
              <Column>
                <Inline verticalAlign="center" space="small">
                  <BankAvatar>
                    <AccountBalance color="action" />
                  </BankAvatar>

                  <Stack space="none">
                    <Typography variant="h3">
                      &bull;&bull;&bull;&bull;{bankAccountDisplayNumber}
                    </Typography>

                    <Typography color="textSecondary">{bankName}</Typography>
                  </Stack>
                </Inline>
              </Column>

              <Column width="content">
                {verificationStatus && (
                  <BankVerificationStatusTag status={verificationStatus} />
                )}
              </Column>
            </Columns>
            {bankAccount.verification_status === 'verified' && (
              <Inline space="xxsmall">
                <Typography variant="caption" color="textSecondary">
                  To change bank account,
                </Typography>
                <ContactSupportLink
                  aria-label="contact support link"
                  variant="caption"
                  color="textSecondary"
                  onClick={() => {
                    showLauncherMessanger('I want to change my bank account');
                  }}
                >
                  contact support.
                </ContactSupportLink>
              </Inline>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
