import { Typography } from '@material-ui/core';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { FormikMaxLengthTextField } from '@superdispatch/forms';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, ButtonArea } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useAppFormik } from 'shared/form/AppFormik';
import {
  FormikCheckboxArrayField,
  FormikCheckboxArrayGroup,
} from 'shared/form/FormikCheckboxArrayGroup';
import { trackRatingSubmittedAnalytics } from 'shared/modules/ratings/data/RatingAnalytics';
import {
  useLatestShipperRating,
  useShipperRatingsAPI,
} from 'shared/modules/ratings/data/ShipperRatingsAPI';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import {
  getShipperRatingTagDisplayName,
  NEGATIVE_SHIPPER_RATING_TAGS,
  POSITIVE_SHIPPER_RATING_TAGS,
  ShipperNewRatingDTO,
  shipperNewRatingSchema,
  ShipperRatingDTO,
} from './data/ShipperRatingDTO';

export function RatingShipperForm() {
  const { values, errors, touched, initialValues, setValues } =
    useFormikContext<ShipperNewRatingDTO>();

  const hasTagsError = touched.tags && errors.tags;
  const hasEditedNegativeRating =
    initialValues.rating !== '' && values.rating === 'NEGATIVE';

  return (
    <Stack space="medium">
      <Stack>
        <Typography color="textSecondary">
          Learn more about ratings{' '}
          <WebViewLink
            href="https://support.superdispatch.com/hc/articles/35661788289555-Ratings-in-Super-Dispatch"
            target="_blank"
            rel="noreferrer"
          >
            here.
          </WebViewLink>
        </Typography>
      </Stack>

      <Stack space="small">
        <Typography variant="h4">Rate your recent experience</Typography>

        <Columns space="small">
          <Column>
            <ButtonArea
              fullWidth={true}
              variant="danger"
              icon={<ThumbDown />}
              active={values.rating === 'NEGATIVE'}
              onClick={() => {
                void setValues({ ...values, rating: 'NEGATIVE', tags: [] });
              }}
            >
              Negative
            </ButtonArea>
          </Column>

          <Column>
            <ButtonArea
              fullWidth={true}
              icon={<ThumbUp />}
              variant="success"
              active={values.rating === 'POSITIVE'}
              onClick={() => {
                void setValues({ ...values, rating: 'POSITIVE', tags: [] });
              }}
            >
              Positive
            </ButtonArea>
          </Column>
        </Columns>
      </Stack>

      {hasEditedNegativeRating && (
        <Box padding="xsmall" borderRadius="small" backgroundColor="Yellow50">
          Update will set the 10-day Pending period.
        </Box>
      )}

      {values.rating ? (
        <>
          <Stack space="small">
            {values.rating === 'POSITIVE' ? (
              <Typography variant="h4">What went well?</Typography>
            ) : (
              <div>
                <Typography variant="h4">What went wrong?</Typography>
                <Typography
                  variant="caption"
                  color={hasTagsError ? 'error' : 'textSecondary'}
                >
                  {hasTagsError ? 'Select reason below' : 'Required'}
                </Typography>
              </div>
            )}

            {values.rating === 'POSITIVE' ? (
              <FormikCheckboxArrayGroup name="tags">
                <Stack space="none">
                  {POSITIVE_SHIPPER_RATING_TAGS.map((tag) => (
                    <FormikCheckboxArrayField
                      key={tag}
                      name={tag}
                      label={getShipperRatingTagDisplayName(tag)}
                    />
                  ))}
                </Stack>
              </FormikCheckboxArrayGroup>
            ) : (
              <FormikCheckboxArrayGroup name="tags">
                <Stack space="none">
                  {NEGATIVE_SHIPPER_RATING_TAGS.map((tag) => (
                    <FormikCheckboxArrayField
                      key={tag}
                      name={tag}
                      label={getShipperRatingTagDisplayName(tag)}
                    />
                  ))}
                </Stack>
              </FormikCheckboxArrayGroup>
            )}
          </Stack>

          <FormikMaxLengthTextField
            name="comment"
            label="Tell more about it"
            placeholder={
              values.rating === 'POSITIVE'
                ? 'Very communicative, paid on time...'
                : 'What disappointed you the most? \nWhat happened?'
            }
            helperText="Publicly visible."
            minRows={3}
            maxLength={280}
            multiline={true}
            fullWidth={true}
          />
        </>
      ) : null}
    </Stack>
  );
}

interface RatingsShipperFormOptions {
  guid: string;
  onSuccess: () => void;
}

export function useRatingShipperForm({
  guid,
  onSuccess,
}: RatingsShipperFormOptions) {
  const { rateShipper } = useShipperRatingsAPI();
  const { data: latestShipperRating } = useLatestShipperRating(guid);

  const isEditingRating = !!latestShipperRating?.guid;

  const formik = useAppFormik<ShipperNewRatingDTO, ShipperRatingDTO>({
    validationSchema: shipperNewRatingSchema,
    initialValues: isEditingRating ? latestShipperRating : {},
    onSubmit: (values) => rateShipper(guid, values),
    onSubmitSuccess: (values) => {
      trackRatingSubmittedAnalytics('Carrier Submitted Rating', values.data);
      onSuccess();
    },
    getSuccessMessage: () =>
      isEditingRating ? 'Rating updated' : 'Rating submitted',
    getErrorMessage: ({ message }) => {
      if (message === 'Forbidden') {
        return 'No rating permission. Contact manager to resolve the issue.';
      }

      return message;
    },
  });

  return { formik, isEditingRating };
}
