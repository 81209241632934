import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  APIListQueryResult,
  BaseAPIListQueryOptions,
  findAPIListQueryItem,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { APIResponse, requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { APIListQueryData } from './../../../shared/api/APIListQuery';
import { ContactDTO, ContactEditDTO, contactSchema } from './ContactDTO';

export function useContactsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateContacts() {
      void queryClient.invalidateQueries(['contacts']);
    }

    function findContact(guid: string) {
      return findAPIListQueryItem<ContactDTO>(
        ['contacts', 'list'],
        queryClient,
        (item) => item.guid === guid,
      );
    }

    function filterContactList(guids: Set<string>) {
      queryClient.setQueriesData<APIListQueryData<ContactDTO> | undefined>(
        ['contacts', 'list'],
        (data) => {
          if (!data) return data;

          return {
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              data: page.data.filter((contact) => !guids.has(contact.guid)),
            })),
          };
        },
      );
    }

    function findNextContact(guid?: string) {
      const contacts = queryClient.getQueryData<
        APIListQueryData<ContactDTO[] | undefined>
      >(['contacts', 'list'], { exact: false });

      const nextContact = contacts?.pages
        .flatMap((page) => page.data)
        .flat()
        .find((contact) => contact?.guid !== guid);

      return nextContact?.guid;
    }

    return {
      findContact,
      invalidateContacts,
      findNextContact,
      filterContactList,
    };
  }, [queryClient]);
}

export function useContactsAPI() {
  const { invalidateContacts } = useContactsCache();

  return useMemo(() => {
    function createContact(
      json: ContactEditDTO,
    ): Promise<APIResponse<ContactDTO>> {
      return requestCarrierAPI('POST /internal/web/contacts/', { json }).then(
        (response: APIResponse<ContactDTO>) => {
          invalidateContacts();
          return { ...response, data: contactSchema.cast(response.data) };
        },
      );
    }

    function updateContact(
      guid: string,
      json: ContactEditDTO,
    ): Promise<APIResponse<ContactDTO>> {
      return requestCarrierAPI(
        ['PUT /internal/web/contacts/{guid}/', { guid }],
        { json },
      ).then((response) => {
        invalidateContacts();
        return { ...response, data: contactSchema.cast(response.data) };
      });
    }

    function deleteContact(guid: string) {
      return requestCarrierAPI([
        'DELETE /internal/web/contacts/{guid}/',
        { guid },
      ]);
    }

    function batchDeleteContacts(guids: string[]) {
      return Promise.all(guids.map((guid) => deleteContact(guid)));
    }

    return {
      createContact,
      updateContact,
      deleteContact,
      batchDeleteContacts,
    };
  }, [invalidateContacts]);
}

export function useContact(
  guid: string | null | undefined,
  options?: APIQueryOptions<ContactDTO>,
): APIQueryResult<ContactDTO> {
  const { findContact } = useContactsCache();

  return useAPIQuery(
    ['contacts', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/contacts/{guid}/', { guid }]),
    {
      ...options,
      enabled: !!guid,
      schema: contactSchema,
      initialData() {
        return !guid ? undefined : findContact(guid);
      },
    },
  );
}

export interface ContactsPageParams {
  q?: string;
  page_size?: number;
}

export function useContactsList(
  params?: ContactsPageParams,
  options?: BaseAPIListQueryOptions<ContactDTO>,
  isSearchEnabled?: boolean,
): APIListQueryResult<ContactDTO> {
  return useAPIListQuery(
    ['contacts', 'list', { params }],
    (page) =>
      requestCarrierAPI([
        isSearchEnabled
          ? 'GET /internal/web/contacts/search/{?page,params*}'
          : !params?.q
          ? 'GET /internal/web/contacts/{?page,params*}'
          : 'GET /internal/web/contacts/find/{?page,params*}',
        { page, params },
      ]),
    { ...options, schema: contactSchema },
  );
}

export function useBatchDeleteContacts(
  options?: APIMutationOptions<Set<string>, unknown, string | undefined>,
): APIMutationResult<Set<string>, unknown, string | undefined> {
  const { batchDeleteContacts } = useContactsAPI();
  const { filterContactList } = useContactsCache();

  return useAPIMutation<Set<string>, unknown, string | undefined>(
    (guids) =>
      batchDeleteContacts(Array.from(guids)).then((responses) => {
        filterContactList(guids);
        return responses[0] as APIResponse;
      }),
    options,
  );
}

interface ContactsUploadResponse {
  count: number;
}

export function useUploadContacts(
  options?: APIMutationOptions<File, ContactsUploadResponse>,
): APIMutationResult<File, ContactsUploadResponse> {
  const { invalidateContacts } = useContactsCache();
  return useAPIMutation((file) => {
    const body = new FormData();
    body.append('file', file);
    return requestCarrierAPI<APIResponse<ContactsUploadResponse>>(
      'POST /internal/web/contacts/import/',
      { body },
    ).then((response) => {
      invalidateContacts();
      return response;
    });
  }, options);
}
