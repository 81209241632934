import { useResponsiveValue } from '@superdispatch/ui';
import { SidebarContainer } from '@superdispatch/ui-lab';
import { Navigate, Outlet, useMatch } from 'react-router-dom';
import { useFlag } from 'shared/settings/FeatureToggles';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { DispatchersListSidebar } from './DispatchersListSidebar';

export function DispatchersOutlet() {
  const match = useMatch('/dispatchers/profile/:action?');
  const isMobile = useResponsiveValue(true, false);
  const shouldShowDispatcherManagement = useFlag('dispatcher_management_page');
  const { currentDispatcherGUID } = useDispatcherPageContext();

  const isDispatcherProfilePage = match?.pathname;

  if ((isMobile && currentDispatcherGUID) || isDispatcherProfilePage) {
    return <Outlet />;
  }

  if (!shouldShowDispatcherManagement) {
    return <Navigate to="/tms/loads" />;
  }

  return (
    <SidebarContainer sidebar={<DispatchersListSidebar />}>
      <Outlet />
    </SidebarContainer>
  );
}
