import {
  ALL_PAYMENT_METHODS,
  ALL_PAYMENT_TERMS,
  CUSTOMER_TYPES,
  VEHICLE_TYPES,
} from '@superdispatch/sdk';
import { startCase } from 'lodash-es';
import { isEmptyRecord } from 'shared/helpers/CommonHelpers';
import {
  yupArray,
  yupBoolean,
  yupDateString,
  yupEnum,
  yupNumber,
  yupObject,
  yupOmit,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';
import { PAYMENT_TERMS_AND_METHODS } from './LoadPaymentTypes';

export const LOAD_STAGES = [
  'active',
  'new',
  'in_terminal',
  'assigned',
  'picked_up',
  'delivered',
  'billed',
  'paid',
  'archived',
  'deleted',
  'aging',
  'delayed-pickups',
  'delayed-deliveries',
  'to-pickup-today',
  'to-deliver-today',
  'payable',
  'receivable',
  'receivable/past-due',
  'revenue',
] as const;
export type LoadStage = (typeof LOAD_STAGES)[number];
export function formatLoadStage(stage: LoadStage): string {
  return startCase(stage);
}
export function isLoadStageComplete(stage: LoadStage, compare: LoadStage) {
  return LOAD_STAGES.indexOf(stage) >= LOAD_STAGES.indexOf(compare);
}

const vehicleStatusOrder = {
  ready: 1,
  not_ready: 2,
  canceled: 3,
};

export function sortVehiclesByStatus(vehicles: LoadVehicleDTO[]) {
  return vehicles.sort((a, b) => {
    const statusA = a.status == null ? 'ready' : a.status;
    const statusB = b.status == null ? 'ready' : b.status;
    return vehicleStatusOrder[statusA] - vehicleStatusOrder[statusB];
  });
}

//
// LoadVehicleDTO
//

export type LoadVehicleDTO = InferType<typeof loadVehicleSchema>;
export const loadVehicleSchema = yupObject({
  guid: yupString(),
  vin: yupString(),
  make: yupString(),
  model: yupString(),
  year: yupNumber(),
  color: yupString(),
  price: yupNumber(),
  type: yupEnum(VEHICLE_TYPES, 'other').required(),
  lot_number: yupString(),
  is_inoperable: yupBoolean(),
  requires_enclosed_trailer: yupBoolean(),
  curb_weight: yupNumber(),
  height: yupNumber(),
  length: yupNumber(),
  width: yupNumber(),
  status: yupEnum(['not_ready', 'ready', 'canceled'], null).optional(),
});

//
// LoadNotPickedUpVehicleDTO
//

export type LoadNotPickedUpVehicleDTO = InferType<
  typeof loadNotPickedUpVehicleSchema
>;
const loadNotPickedUpVehicleSchema = loadVehicleSchema.shape({
  reason: yupString(),
});

//
// LoadLegDTO
//

export function isEmptyLoadLegVenue({
  contacts,
  ...venue
}: InferType<typeof loadVenueSchema>) {
  return isEmptyRecord(venue) && isEmptyRecord(contacts[0]);
}

export type LoadVenueDTO = InferType<typeof loadVenueSchema>;
export const loadVenueSchema = yupObject({
  name: yupString().max(200),
  address: yupString().max(256),
  city: yupString().max(100),
  state: yupString().max(100),
  zip: yupString().max(20),
  business_type: yupEnum(CUSTOMER_TYPES, null).optional(),
  buyer_number: yupString().max(200).optional(),
  contacts: yupArray(
    yupObject({
      name: yupString().max(200),
      phone: yupString().max(200),
      phone2: yupString().nullable(true).max(200),
      email: yupString().multipleEmails().max(300),
    }),
  ).defined(),
});

//
// LoadExpenseDTO
//

export type LoadExpenseType = (typeof LOAD_EXPENSE_TYPES)[number];
export const LOAD_EXPENSE_TYPES = [
  'pulling_fee',
  'storage_fee',
  'late_fee',
  'dry_run_fee',
  'tax',
  'fuel',
  'toll',
  'other',
] as const;
export function formatLoadExpenseType(type: LoadExpenseType) {
  switch (type) {
    case 'pulling_fee':
      return 'Pulling Fee';
    case 'storage_fee':
      return 'Storage Fee';
    case 'late_fee':
      return 'Late Fee';
    case 'dry_run_fee':
      return 'Dry Run Fee';
    case 'tax':
      return 'Tax';
    case 'fuel':
      return 'Fuel';
    case 'toll':
      return 'Toll';
    case 'other':
      return 'Other';
  }
}
export type LoadExpenseDTO = InferType<typeof loadExpenseSchema>;
export const loadExpenseSchema = yupObject({
  guid: yupString(),
  name: yupString().max(50),
  type: yupEnum(LOAD_EXPENSE_TYPES, 'other'),
  price: yupNumber().required('Enter price'),
  date: yupDateString('DateTimeISO').required(),
  receipt_url: yupString(),
  show_on_invoice: yupBoolean(),
  deduct_from_driver_pay: yupBoolean(),
});

//
// LoadPaymentDTO
//

export type LoadPaymentStatus = (typeof LOAD_PAYMENT_STATUSES)[number];
export const LOAD_PAYMENT_STATUSES = [
  'initiating',
  'processing',
  'received',
  'on_hold',
  'failed',
] as const;

export type LoadPaymentDTO = InferType<typeof loadPaymentSchema>;
export const loadPaymentSchema = yupObject({
  price: yupNumber(),
  status: yupEnum(LOAD_PAYMENT_STATUSES, null),
  method: yupEnum(PAYMENT_TERMS_AND_METHODS, null),
  status_details: yupString(),
  notes: yupString(),
  broker_fee: yupNumber(),
  driver_pay: yupNumber(),
  paid_at: yupDateString('DateTimeISO'),
  receipt_date: yupDateString('DateTimeISO'),
  terms: yupString().nullable(true),
  invoice: yupObject({
    invoice_id: yupString(),
    invoice_notes: yupString(),
    sent_at: yupDateString('DateTimeISO'),
    errors: yupArray(
      yupObject({
        channel: yupString(),
        message: yupString(),
      }),
    ).defined(),
  }),
  paid_amount: yupNumber(),
  paid_method: yupNumber(),
  payment_details: yupObject({
    payment_method: yupEnum(ALL_PAYMENT_METHODS, null),
    payment_terms: yupEnum(ALL_PAYMENT_TERMS, null),
  })
    .optional()
    .nullable(),
  payment_reference_number: yupString(),
  on_hold_reasons: yupArray(yupString()),
  custom_on_hold_reason: yupString().optional(),
  is_expedited_pay_available: yupBoolean().nullable(true).default(null),
  expedited_pay_fee: yupNumber(),
});

//
// LoadAttachmentDTO
//
export const ATTACHMENT_FILE_TYPES = ['image', 'pdf', 'any'] as const;
export type AttachmentFileType = (typeof ATTACHMENT_FILE_TYPES)[number];

const FILE_TYPE_PATTERNS = {
  pdf: /\.pdf$/i,
  image: /\.(gif|jpe?g|png|tiff|webp|bmp)$/i,
};

export function parseAttachmentFileType(fileName: string): AttachmentFileType {
  for (const [type, pattern] of Object.entries(FILE_TYPE_PATTERNS)) {
    if (pattern.test(fileName)) {
      return type as AttachmentFileType;
    }
  }

  return 'any';
}

export type LoadAttachmentDTO = InferType<typeof loadAttachmentSchema>;
export const loadAttachmentSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  file_type: yupEnum(ATTACHMENT_FILE_TYPES, 'any'),
  file_url: yupString().required('Wait for upload(s) to finish successfully'),
  is_created_by_broker: yupBoolean(),
});

//
// LoadAttachmentCreateDTO
//

export type LoadAttachmentCreateDTO = InferType<
  typeof loadAttachmentCreateSchema
>;
export const loadAttachmentCreateSchema = loadAttachmentSchema.shape({
  guid: yupOmit,
  load_guid: yupString(),
});

//
// LoadAttachmentEditDTO
//

export type LoadAttachmentEditDTO = InferType<typeof loadAttachmentEditSchema>;
export const loadAttachmentEditSchema = loadAttachmentSchema.shape({
  load_guid: yupString(),
});

//
// LoadDTO
//

export type LoadStatus = (typeof LOAD_STATUSES)[number];
export const LOAD_STATUSES = ['new', 'picked_up', 'delivered'] as const;
export function formatLoadStatus(input: unknown): string {
  switch (input as LoadStatus) {
    case 'new':
      return 'New';
    case 'picked_up':
      return 'Picked Up';
    case 'delivered':
      return 'Delivered';
    default:
      return 'Unknown';
  }
}

export type LoadInspectionType = (typeof LOAD_INSPECTION_TYPES)[number];
export const LOAD_INSPECTION_TYPES = ['standard', 'advanced', 'aiag'] as const;
export function formatLoadInspectionType(type: LoadInspectionType) {
  switch (type) {
    case 'standard':
      return 'Standard';
    case 'advanced':
      return 'Advanced';
    case 'aiag':
      return 'AIAG';
  }
}

export type LoadLegDTO = InferType<typeof loadLegSchema>;
export const loadLegSchema = yupObject({
  notes: yupString(),
  venue: loadVenueSchema,
  actual_date: yupDateString('DateTimeISO'),
  scheduled_date: yupDateString('DateISO'),
});

export type LoadDriverDTO = InferType<typeof loadDriverSchema>;
export const loadDriverSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  phone_number: yupString().optional(),
  email: yupString().optional(),
  notes: yupString().optional(),
  connection_status: yupEnum([
    'pending',
    'activated',
    'declined',
    'deactivated',
  ]),
}).default(undefined);

export type LoadDTO = InferType<typeof loadSchema>;
export type LoadPickupDTO = LoadDTO['pickup'];
export type LoadDeliveryDTO = LoadDTO['delivery'];
export type LoadCustomerDTO = LoadDTO['customer'];
export type LoadInternalNoteDTO = NonNullable<
  LoadDTO['internal_notes']
>[number];
export type LoadVehicleInspectionDTO = NonNullable<
  LoadDTO['inspection_photos']
>[number];
export type LoadTerminalDiagramDTO = LoadDTO['terminals_diagram'];
export type LoadActivityDTO = LoadDTO['activities'];
export type FeaturesDTO = LoadDTO['features'];

export const SHIPPER_FEATURES = ['restrict_changing_load_id'] as const;
export const isDisabledLoadId = (load: LoadDTO) => {
  return load.features?.includes('restrict_changing_load_id');
};

export function isEmptyLoadLeg({
  venue,
  ...leg
}: LoadPickupDTO | LoadDeliveryDTO) {
  return isEmptyRecord(leg) && isEmptyLoadLegVenue(venue);
}

export function isLoadCanceled(load: LoadDTO) {
  return (
    load.is_canceled_by === 'broker' ||
    load.is_canceled_by === 'dispatcher' ||
    load.is_canceled_by === 'driver' ||
    load.is_canceled_by === 'api_user' ||
    load.is_canceled_by_broker === true // TODO: Deleted the `load.is_canceled_by`_broker constraint once the backend fix the issue
  );
}

export type PhotoDTO = InferType<typeof photoSchema>;
const photoSchema = yupObject({
  id: yupString(),
  url: yupString(),
  thumbnail_url: yupString(),
  type: yupString(),
  is_vehicle_detected: yupBoolean().nullable(true).default(null),
  damages: yupArray(
    yupObject({
      code: yupString(),
      note: yupString(),
    }),
  ).default([]),
});

export type VideoDTO = InferType<typeof videoSchema>;
const videoSchema = yupObject({
  guid: yupString(),
  url: yupString(),
  taken_at: yupString(),
  thumbnail_url: yupString(),
  latitude: yupNumber(),
  longitude: yupNumber(),
  type: yupString(),
  duration_in_seconds: yupNumber().nullable(true),
});

export type AIAGInspectionDamageDTO = InferType<
  typeof aiagInspectionDamageSchema
>;
const aiagInspectionCodeSchema = yupObject({
  code: yupString(),
  description: yupString(),
});
const aiagInspectionDamageSchema = yupObject({
  area: aiagInspectionCodeSchema,
  severity: aiagInspectionCodeSchema,
  type: aiagInspectionCodeSchema,
  photos: yupArray(
    yupObject({
      original_photo_url: yupString(),
      photo_thumbnail_url: yupString(),
    }),
  ).defined(),
});
const aiagInspectionSchema = yupObject({
  damages: yupArray(aiagInspectionDamageSchema),
  step: yupEnum(['pickup', 'delivery']),
});

const inspectionShema = yupObject({
  aiag_inspections: yupArray(aiagInspectionSchema).defined(),
  guid: yupString(),
  make: yupString(),
  model: yupString(),
  videos: yupArray(videoSchema),
  photos: yupArray(
    yupObject({
      guid: yupString(),
      rendered_photo: yupString(),
      rendered_photo_thumbnail: yupString(),
      taken_at: yupDateString('DateTimeISO'),
      type: yupString(),
      is_vehicle_detected: yupBoolean().nullable(true).default(null),
      damages: yupArray(
        yupObject({
          code: yupString(),
          note: yupString(),
        }),
      ).default([]),
    }),
  ),
  vin: yupString(),
  year: yupNumber(),
});

export const loadSchema = yupObject({
  guid: yupString(),
  number: yupString(),
  internal_load_id: yupString().max(25),
  payment_details: yupObject({
    payment_method: yupEnum(ALL_PAYMENT_METHODS, null),
    payment_terms: yupEnum(ALL_PAYMENT_TERMS, null),
  })
    .optional()
    .nullable(),
  instructions: yupString(),
  inspection_type: yupEnum(LOAD_INSPECTION_TYPES),
  is_created_by_broker: yupBoolean().required(),
  is_canceled_by_broker: yupBoolean(),
  is_canceled_by: yupEnum(['dispatcher', 'driver', 'broker', 'api_user'])
    .nullable(true)
    .default(null),
  is_dispatched_to_carrier: yupBoolean().required(),
  is_active: yupBoolean().required(),
  is_instant_dispatch: yupBoolean(),
  archived: yupBoolean().required(),
  status: yupEnum(LOAD_STATUSES),
  current_leg: yupNumber(),
  is_pickedup_from_origin: yupBoolean(),
  is_delivered_to_destination: yupBoolean(),
  latest_internal_note: yupString(),
  stage: yupEnum(LOAD_STAGES, 'new'),
  pickup: loadLegSchema,
  delivery: loadLegSchema,
  features: yupArray(yupEnum(SHIPPER_FEATURES, null)),
  can_add_terminal: yupBoolean().required(),
  can_be_assigned_to_driver: yupBoolean().required(),
  can_be_delivered: yupBoolean().required(),
  can_be_dispatched_to_carrier: yupBoolean().required(),
  can_be_duplicated: yupBoolean().required(),
  can_be_edited: yupBoolean(),
  can_edit_customer_information: yupBoolean().default(true),
  pickup_driver_address: yupString().nullable(),
  delivery_driver_address: yupString().nullable(),
  //property 'can_payment_be_edited' was added before api implementation
  //when the api starts returning this field we can remove default value
  can_payment_be_edited: yupBoolean().default(true),
  can_be_marked_as_new: yupBoolean(),
  can_be_marked_as_paid: yupBoolean().required(),
  can_be_marked_as_unpaid: yupBoolean(),
  can_be_picked_up: yupBoolean().required(),
  can_be_split: yupBoolean().required(),
  can_be_unassigned_from_driver: yupBoolean().required(),
  can_mark_as_in_terminal: yupBoolean().required(),
  can_send_invoice: yupBoolean().required(),
  is_changed_by_broker: yupBoolean(),
  terminals: yupArray(
    yupObject({
      guid: yupString(),
      name: yupString(),
      is_current: yupBoolean(),
      is_delivered: yupBoolean(),
    }),
  ).defined(),
  terminals_diagram: yupArray(
    yupObject({
      is_active: yupBoolean().required(),
      is_complete: yupBoolean(),
    }),
  ),
  trip: yupObject({ name: yupString().nullable(true) })
    .nullable(true)
    .unknown(),
  driver: loadDriverSchema.nullable(true),
  vehicles: yupArray(loadVehicleSchema).defined(),
  not_picked_up_vehicles: yupArray(loadNotPickedUpVehicleSchema),
  expenses: yupArray(loadExpenseSchema),
  customer: yupObject({
    shipper_profile_url: yupString(),
    venue: loadVenueSchema,
  }),
  url_in_stms: yupString(),
  should_request_dispatch_to_carrier: yupBoolean().required(),
  payments: yupArray(loadPaymentSchema).defined(),
  internal_notes: yupArray(
    yupObject({
      text: yupString(),
      id: yupNumber().nullable(false),
      created_at: yupDateString('DateTimeISO'),
      author: yupObject({ name: yupString() }),
    }),
  ),
  attachments: yupArray(loadAttachmentSchema),
  activities: yupArray(
    yupObject({
      is_changed_by_broker: yupBoolean(),
      level: yupEnum(['info', 'critical', 'warning'], 'info'),
      text: yupString(),
      timestamp: yupDateString('DateTimeISO'),
      user: yupObject({
        name: yupString(),
        email: yupString(),
      }).nullable(),
    }),
  ),
  signatures: yupObject({
    delivery_driver_signature: yupString(),
    delivery_signature_address: yupString(),
    delivery_touchless_signature_sms_sent_at: yupDateString('DateTimeISO'),
    pickup_driver_signature: yupString(),
    pickup_signature_address: yupString(),
    pickup_touchless_signature_sms_sent_at: yupDateString('DateTimeISO'),
  }),
  inspection_info: yupArray(inspectionShema),
  inspection_photos: yupArray(inspectionShema),
  undetected_vehicle_count: yupNumber(),
  online_bol_url: yupString(),
});

export type AddLoadDTO = InferType<typeof addLoadSchema>;
export const addLoadSchema = yupObject({
  number: yupString(),
  internal_load_id: yupString().max(25),
  instructions: yupString().nullable(),
  inspection_type: yupEnum(LOAD_INSPECTION_TYPES),
  save_pickup_contact: yupBoolean(),
  save_delivery_contact: yupBoolean(),
  save_customer_contact: yupBoolean(),
  payment_details: yupObject({
    payment_method: yupEnum(ALL_PAYMENT_METHODS, null),
    payment_terms: yupEnum(ALL_PAYMENT_TERMS, null),
  })
    .optional()
    .nullable(),
  pickup: yupObject({
    notes: yupString(),
    venue: loadVenueSchema,
    actual_date: yupDateString('DateTimeISO'),
    scheduled_date: yupDateString('DateISO'),
  }),
  delivery: yupObject({
    notes: yupString(),
    venue: loadVenueSchema,
    actual_date: yupDateString('DateTimeISO'),
    scheduled_date: yupDateString('DateISO'),
  }),
  customer: yupObject({
    shipper_profile_url: yupString(),
    venue: loadVenueSchema,
  }),
  payments: yupArray(loadPaymentSchema).defined(),
  vehicles: yupArray(
    yupObject({
      vin: yupString(),
      make: yupString(),
      model: yupString(),
      year: yupNumber(),
      color: yupString(),
      price: yupNumber(),
      type: yupEnum(VEHICLE_TYPES, 'other').required(),
      lot_number: yupString(),
      is_inoperable: yupBoolean(),
      requires_enclosed_trailer: yupBoolean(),
    }),
  ).defined(),
  attachments: yupArray(
    loadAttachmentSchema.shape({
      guid: yupOmit,
    }),
  ).defined(),
});

export type LoadCountsDTO = InferType<typeof loadCountsSchema>;
export const loadCountsSchema = yupObject({
  count: yupNumber(),
});
export type LoadStagesCounts = Partial<Record<LoadStage, LoadCountsDTO>>;

export type LoadUpdateTerminalDTO = InferType<typeof loadUpdateTerminalSchema>;
export const loadUpdateTerminalSchema = yupObject({
  terminals_diagram: yupArray(
    yupObject({
      is_active: yupBoolean().required(),
      is_complete: yupBoolean(),
    }),
  ),
});

export type LoadMarkAsPaidDTO = InferType<typeof loadMarkAsPaidSchema>;
export const loadMarkAsPaidSchema = yupObject({
  paid_amount: yupNumber().nullable(),
  paid_method: yupNumber().nullable(),
  receipt_date: yupDateString('DateTimeISO'),
  payment_notes: yupString(),
  payment_reference_number: yupString(),
});

export const LOAD_CANCEL_REASONS = [
  'unit_not_ready',
  'unknown_inoperable_vehicle',
  'cannot_meet_dates',
  'low_price',
  'changed_route',
] as const;

export type LoadCancelDTO = InferType<typeof cancelLoadSchema>;
export const cancelLoadSchema = yupObject({
  cancel_reason_type: yupEnum(LOAD_CANCEL_REASONS).default(''),
  cancel_reason: yupString().max(512),
});

export type OfferDeclinePayloadDTO = InferType<
  typeof offerDeclinePayloadSchema
>;
export const offerDeclineReasons = [
  'low_price',
  'lack_of_capacity',
  'inconvenient_dates',
] as const;
export const offerDeclinePayloadSchema = yupObject({
  decline_comment: yupString().max(512),
  decline_reasons: yupArray(yupString().oneOf(offerDeclineReasons)),
});

export type AttachmentDTO = InferType<typeof attachmentSchema>;
export const attachmentSchema = yupObject({
  id: yupNumber(),
  guid: yupString(),
  file: yupString(),
  file_type: yupString(),
  name: yupString(),
});

export type Vehicle = InferType<typeof vehicleSchema>;
export const vehicleSchema = yupObject({
  guid: yupString(),
  vin: yupString(),
  make: yupString(),
  model: yupString(),
  year: yupNumber(),
});

export type OrdersDTO = InferType<typeof orderLoadSchema>;
export const orderLoadSchema = yupObject({
  id: yupString(),
  guid: yupString(),
  number: yupString(),
  internal_load_id: yupString(),
  url: yupString(),
  status: yupNumber(),
  status_display: yupString(),
  archived: yupBoolean(),
  is_paid: yupBoolean(),
  price: yupString(),
  invoice_id: yupString(),
  invoice_date: yupString().nullable(),
  invoice_sent_date: yupString().nullable(),
  invoice_price: yupString(),
  vehicles: yupArray(vehicleSchema),
  attachments: yupArray(
    yupObject({
      id: yupNumber(),
      guid: yupString(),
      file: yupString(),
      file_type: yupString(),
      name: yupString(),
    }),
  ),
  pickup_name: yupString(),
  pickup_contact: yupString(),
  pickup_address: yupString(),
  pickup_city: yupString(),
  pickup_state: yupString(),
  pickup_zip: yupString(),
  pickup_date: yupString(),
  pickedup_date: yupString(),
  delivery_name: yupString(),
  delivery_contact: yupString(),
  delivery_address: yupString(),
  delivery_city: yupString(),
  delivery_state: yupString(),
  delivery_zip: yupString(),
  delivery_date: yupString(),
  delivered_date: yupString().nullable(),
  broker_name: yupString(),
});

export type CustomerDTO = InferType<typeof customerSchema>;
export const customerSchema = yupObject({
  name: yupString(),
  email: yupString(),
  fax: yupString(),
});

export type AccountInfoDTO = InferType<typeof accountInfoSchema>;
export const accountInfoSchema = yupObject({
  is_factoring_switched_on: yupBoolean(),
  customer: customerSchema,
  invoice_number: yupString(),
  invoice_date: yupString(),
  qb_online: yupObject({
    connected: yupBoolean(),
    message: yupString(),
  }),
  qb_desktop: yupObject({
    connected: yupBoolean(),
    is_invoice_sent: yupBoolean(),
    message: yupString(),
  }),
  order: orderLoadSchema,
  attachments: yupArray(attachmentSchema),
});
