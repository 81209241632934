import { RouteObject } from 'react-router-dom';
import { ContactsPage } from './ContactsPage';
import { ContactsPageDetails } from './ContactsPageDetails';
import { ContactsPageRouterProvider } from './ContactsPageRouter';

function ContactsPageOutlet() {
  return (
    <ContactsPageRouterProvider>
      <ContactsPage />
    </ContactsPageRouterProvider>
  );
}

export const contactsRoutes: RouteObject[] = [
  {
    path: '/contacts',
    element: <ContactsPageOutlet />,
    children: [
      {
        path: ':contactGUID?/:action?',
        element: <ContactsPageDetails />,
      },
    ],
  },
];
