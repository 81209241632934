import {
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Clear, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
  VisibilityObserver,
} from '@superdispatch/ui';
import {
  Box,
  Button,
  Sidebar,
  SidebarMenuItem,
  TextBox,
} from '@superdispatch/ui-lab';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  splitTextByLine,
  StyledNotesIcon,
} from 'shared/modules/driver/DriverUtils';
import {
  PlanUpdateDrawer,
  useNextPlanEstimate,
  useShowPlanUpdateDrawer,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { DriverAvatar } from './core/DriverAvatar';
import { DriverInviteDrawerLoading } from './core/DriverInviteLoading';
import { getDriverLabel } from './data/DriverPageUtils';
import { trackDriversEvent } from './data/DriversAnalytics';
import { DriverInviteDrawer } from './DriverInviteDrawer';
import { useDriversPageContext } from './DriversPageContext';

const DriverListAction = styled(Box)`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
`;

export function DriversPageList() {
  const isMobile = useResponsiveValue(true, false);
  const navigate = useNavigate();
  const {
    openDriver,
    currentDriverGUID,
    searchQueryDrivers,
    driverList,
    driver: driverData,
  } = useDriversPageContext();
  const {
    isError: isErrorDriver,
    isFetching: isFetchingDriver,
    data: currentDriver,
  } = driverData;
  const [searchText, _, setSearchText] = searchQueryDrivers;
  const { shouldShowPlanUpdateDrawer } = useShowPlanUpdateDrawer();
  const nextPlanEstimate = useNextPlanEstimate();
  const { data: subscription } = useSubscriptionSettings();

  const canInviteDriver = useMemo(() => {
    if (subscription?.plan) {
      const { plan } = subscription;
      const seatsCount = Number(plan.seats_count);
      const driversCount = Number(plan.drivers_count);
      return (
        seatsCount > driversCount && driversCount === 0 && seatsCount === 1
      );
    }
    return false;
  }, [subscription]);

  const shouldShowDispatcherManagementAlert = useFlag(
    'dispatcher_management_alert',
  );
  const [currentModal, setCurrentModal] = useState<
    'add' | 'invite' | 'plan_upgrade'
  >();

  const { isFetching, data, isFetchingNextPage, fetchNextPage, hasNextPage } =
    driverList;

  const list = data?.pages.flatMap((item) => item.data);

  const inviteDriver = () => {
    trackDriversEvent({ name: 'Carrier Clicked Invite Driver' });
    setCurrentModal('invite');

    if (shouldShowPlanUpdateDrawer) {
      void nextPlanEstimate.refetch().then(({ data: nextPlan }) => {
        setCurrentModal(
          nextPlan && !canInviteDriver ? 'plan_upgrade' : 'invite',
        );
      });
    }
  };

  useEffect(() => {
    if (!isFetching && !isFetchingDriver) {
      const fistDriverGUID = list?.[0]?.guid;
      if ((isErrorDriver || !currentDriver) && fistDriverGUID && !isMobile) {
        openDriver(fistDriverGUID, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openDriver,
    isFetching,
    list?.length,
    isErrorDriver,
    isFetchingDriver,
    currentDriver,
    isMobile,
  ]);

  return (
    <>
      <DriverInviteDrawerLoading
        open={nextPlanEstimate.isFetching && !!currentModal}
        onClose={() => {
          setCurrentModal(undefined);
        }}
      />

      <PlanUpdateDrawer
        open={currentModal === 'plan_upgrade' && !nextPlanEstimate.isFetching}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onSubmitSuccess={() => {
          setCurrentModal('invite');
        }}
      />

      <DriverInviteDrawer
        open={currentModal === 'invite' && !nextPlanEstimate.isFetching}
        onClose={() => {
          setCurrentModal(undefined);
        }}
        onOpen={() => {
          setCurrentModal('invite');
        }}
      />

      <Sidebar
        title={
          <Columns align="center">
            <Column width="1/2">
              <Typography variant="h3">Drivers</Typography>
            </Column>
            {isMobile && (
              <Column width="1/2">
                <Inline horizontalAlign="right">
                  <Button
                    size="small"
                    startIcon={<Add />}
                    onClick={inviteDriver}
                  >
                    Invite Driver
                  </Button>
                </Inline>
              </Column>
            )}
          </Columns>
        }
        header={
          <Stack space="small">
            {!isMobile && (
              <Button
                startIcon={<Add />}
                fullWidth={true}
                onClick={inviteDriver}
              >
                Invite Driver
              </Button>
            )}
            <TextField
              disabled={!list?.length && !searchText}
              fullWidth={true}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              placeholder="Search for drivers…"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="action" />
                  </InputAdornment>
                ),
                endAdornment: !!searchText && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchText('');
                      }}
                    >
                      <Clear color="action" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        }
      >
        <Box>
          {!data?.pages.length ? (
            <ListItem>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          ) : !list?.length ? (
            <Box padding="small">
              <TextBox color="secondary" align="center">
                {searchText ? 'No search results' : 'No drivers'}
              </TextBox>
            </Box>
          ) : (
            list.map((driver) => {
              const isCurrent = currentDriverGUID === driver.guid;
              const driverLabel = getDriverLabel(driver);

              return (
                <SidebarMenuItem
                  key={driver.guid}
                  selected={isCurrent}
                  onClick={() => {
                    openDriver(driver.guid);
                  }}
                  avatar={<DriverAvatar driver={driver} />}
                  action={
                    driver.notes ? (
                      <Tooltip
                        placement="top"
                        title={splitTextByLine(driver.notes)}
                      >
                        <StyledNotesIcon htmlColor={ColorDynamic.Dark100} />
                      </Tooltip>
                    ) : null
                  }
                >
                  {driverLabel}
                </SidebarMenuItem>
              );
            })
          )}

          {hasNextPage && (
            <VisibilityObserver
              onChange={(visibility) => {
                if (!isFetchingNextPage && visibility === 'visible') {
                  void fetchNextPage();
                }
              }}
              render={({ ref }) => (
                <ListItem ref={ref}>
                  <ListItemText
                    primary={<Skeleton />}
                    secondary={<Skeleton />}
                  />
                </ListItem>
              )}
            />
          )}
        </Box>
        {shouldShowDispatcherManagementAlert && !isMobile && (
          <DriverListAction
            paddingTop="xxsmall"
            paddingBottom="small"
            paddingLeft="xsmall"
            paddingRight="xsmall"
            backgroundColor="White"
          >
            <Box borderRadius="medium" backgroundColor="Blue50" padding="small">
              <Stack space="small">
                <Stack space="xxsmall">
                  <Typography color="textPrimary" variant="body1">
                    Dispatchers Page
                  </Typography>
                  <Typography color="textPrimary">
                    Now you can manage dispatchers. Go to Profile → Dispatchers
                    to manage them.
                  </Typography>
                </Stack>
                <Button
                  onClick={() => {
                    navigate(
                      '/dispatchers?utm_medium=dispatchers_page_announcement',
                    );
                  }}
                  variant="neutral"
                >
                  Manage Dispatchers
                </Button>
              </Stack>
            </Box>
          </DriverListAction>
        )}
      </Sidebar>
    </>
  );
}
