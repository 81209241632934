import { createSvgIcon } from 'shared/icons/IconUtils';

export const MobileAddIcon = createSvgIcon(
  'MobileAddIcon',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 32C24.4183 32 28 28.4183 28 24C28 19.5817 24.4183 16 20 16C15.5817 16 12 19.5817 12 24C12 28.4183 15.5817 32 20 32ZM19 23V20H21V23H24V25H21V28H19V25H16V23H19Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2364 0C20.9964 0 21.6467 0.266819 22.1875 0.800455C22.7292 1.335 23 1.97727 23 2.72727L23 3V6.81818V14.4578C22.1245 14.1828 21.1972 14.025 20.2364 14.0027V6.81818L6.76365 6.81818V22.1818H10.1649C10.0566 22.7714 10 23.3791 10 24C10 25.8214 10.487 27.5291 11.3378 29H7L6.76365 29C6.00365 29 5.35327 28.7332 4.81251 28.1995C4.27084 27.665 4 27.0227 4 26.2727V26.0032L4 26L4 22.1818L4 6.81818V2.72727L4.00025 2.68782C4.00959 1.95472 4.27988 1.3256 4.81113 0.800454C5.32411 0.295088 5.93499 0.0290195 6.64378 0.00224657C6.68051 0.000859287 6.7175 0.000114504 6.75475 1.22139e-05L6.76365 0H20H20.2364ZM23 16.5815C22.1426 16.2345 21.2113 16.0317 20.2364 16.0034V20H21V23H23V22.1818V16.5815ZM23 25V26.2727C23 26.3196 22.9989 26.3661 22.9968 26.4121C22.9651 27.1026 22.6958 27.6984 22.1889 28.1995C21.7787 28.6036 21.3059 28.8547 20.7706 28.9528C20.599 28.9843 20.4209 29 20.2364 29H13.7546C12.6567 27.6304 12 25.8919 12 24C12 23.3746 12.0718 22.7659 12.2075 22.1818L19 22.1818V23H16V25H19V28H21V25H23Z"
      fill="currentColor"
    />
  </>,
  {
    viewBox: '0 0 32 32',
    style: { height: '32px', width: '32px' },
  },
);
