import { Typography } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { LoadOrganizationIcon } from 'shared/icons/LoadOrganizationIcon';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import { MobileAddIcon } from 'shared/icons/MobileAddIcon';
import { UploadCloudIcon } from 'shared/icons/UploadCloudIcon';
import { CustomerComments } from 'shared/modules/subscription/core/CustomerComments';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  padding: 40px 48px;
  overflow: hidden;
  background: radial-gradient(
    100% 55% at 80% 100%,
    #8c2842 0%,
    #8c2842 2%,
    ${Color.Dark500} 100%
  );

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const CommentsBox = styled(Box)`
  margin-top: 56px;
`;

const StyledTextBox = styled(TextBox)`
  color: ${Color.White};
`;
const items = [
  {
    icon: <MobileAddIcon fontSize="large" htmlColor={Color.Silver500} />,
    title: 'Create & Import from Driver App',
    description: 'Create and Import loads from Super Dispatch Driver App',
  },
  {
    icon: <LoadsSidebarIcon fontSize="large" htmlColor={Color.Silver500} />,
    title: 'Advanced Load Management',
    description:
      'Effortlessly create, import, request, negotiate, and book loads without making a single phone call.',
  },
  {
    icon: <LoadOrganizationIcon htmlColor={Color.Silver500} />,
    title: 'Unified Load Organization',
    description:
      'Keep track of all your loads from any load board in one convenient place.',
  },
  {
    icon: <UploadCloudIcon htmlColor={Color.Silver500} />,
    title: 'Boost Productivity',
    description:
      'Speed up your process by importing loads from PDF or CSV files, minimizing manual tasks and errors.',
  },
];

interface PaywallDialogDescriptionProps {
  pageName: string;
}

const DecsriptionText = styled(Typography)`
  color: ${Color.Silver500};
`;

export function PaywallDialogDescription({
  pageName,
}: PaywallDialogDescriptionProps) {
  const shouldShowMonetizationExperiment = useFlag('monetization_experiment');

  const listItems = useMemo(() => {
    if (shouldShowMonetizationExperiment) {
      return items;
    }

    return items.slice(1);
  }, [shouldShowMonetizationExperiment]);

  return (
    <StyledBox height="100%">
      <Stack space="medium">
        <Stack space="small">
          <StyledTextBox variant="heading-2">
            Pro Subscription Required
          </StyledTextBox>
          <StyledTextBox>
            To access {pageName}, upgrade your plan now to get the most out of
            Super Dispatch.
          </StyledTextBox>
        </Stack>
        {listItems.map((item) => (
          <Columns align="top" key={item.title} space="medium">
            <Column width="content">
              <Inline horizontalAlign="right">{item.icon}</Inline>
            </Column>
            <Column>
              <Stack space="xxsmall">
                <StyledTextBox variant="heading-4">{item.title}</StyledTextBox>
                <DecsriptionText>{item.description}</DecsriptionText>
              </Stack>
            </Column>
          </Columns>
        ))}
      </Stack>
      <CommentsBox>
        <CustomerComments color={Color.Silver500} source="trial" />
      </CommentsBox>
    </StyledBox>
  );
}
