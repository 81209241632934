import { useVisibilityObserver } from '@superdispatch/ui';
import { useEffect, useState } from 'react';
import { useVisibilityState } from 'shared/helpers/BrowserHelpers';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { LoadSuggestionDTO } from 'shared/modules/load-suggestions/data/LoadSuggestionDTO';

const DISMISS_ONBOARDING_SEEN_COUNT_KEY =
  'onboarding.dismiss_onboarding_seen_count';
const NEW_INDICATOR_ONBOARDING_SEEN_COUNT_KEY =
  'onboarding.new_load_indicator_seen_count';

interface DismissOnboardingProps {
  contentRef: React.RefObject<HTMLDivElement | null>;
  dismissButtonRef: React.RefObject<HTMLButtonElement | null>;
  setTranslate: (value: React.SetStateAction<number>) => void;
  shouldRunOnboarding?: boolean;
}

export function useDismissOnboarding({
  contentRef,
  dismissButtonRef,
  shouldRunOnboarding,
  setTranslate,
}: DismissOnboardingProps) {
  const [isOnboardingRunning, setIsOnboardingRunning] = useState(false);
  const documentVisibility = useVisibilityState();
  const elementVisibility = useVisibilityObserver(contentRef.current);

  useEffect(() => {
    const onboardingSeenCount =
      Number(LocalStore.get(DISMISS_ONBOARDING_SEEN_COUNT_KEY)) || 0;

    if (
      !shouldRunOnboarding ||
      onboardingSeenCount > 2 ||
      documentVisibility === 'hidden' ||
      'Cypress' in window ||
      !contentRef.current ||
      !dismissButtonRef.current ||
      elementVisibility === 'invisible'
    ) {
      return;
    }

    contentRef.current.style.transition = `500ms`;
    dismissButtonRef.current.style.transition = `500ms`;
    setIsOnboardingRunning(true);

    const timeoutId = setTimeout(() => {
      // imitate swipe left
      setTranslate(-80);
    }, 1000);

    const timeoutId2 = setTimeout(() => {
      // go back to initial position
      setTranslate(0);
    }, 2500);

    const timeoutId3 = setTimeout(() => {
      // unset transition after onboarding is done
      if (contentRef.current && dismissButtonRef.current) {
        contentRef.current.style.transition = 'unset';
        dismissButtonRef.current.style.transition = 'unset';
      }
      LocalStore.set(
        DISMISS_ONBOARDING_SEEN_COUNT_KEY,
        (onboardingSeenCount + 1).toString(),
      );
      setIsOnboardingRunning(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(timeoutId2);
      clearTimeout(timeoutId3);
      setIsOnboardingRunning(false);
    };

    // we want the onboarding to repeat only when the user switches tab or reloads the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentVisibility]);

  return {
    isOnboardingRunning,
  };
}

export function useNewLoadIndicatorOnboarding(
  loads: LoadSuggestionDTO[] | undefined,
) {
  const newLoadIndicatorSeenCount = useLocalStore(
    NEW_INDICATOR_ONBOARDING_SEEN_COUNT_KEY,
  );
  const documentVisibility = useVisibilityState();

  const hasNewLoad = loads?.some((load) => !load.is_seen);

  useEffect(() => {
    const currentSeenCount =
      Number(LocalStore.get(NEW_INDICATOR_ONBOARDING_SEEN_COUNT_KEY)) || 0;

    if (documentVisibility !== 'hidden' && currentSeenCount < 4 && hasNewLoad) {
      LocalStore.set(
        NEW_INDICATOR_ONBOARDING_SEEN_COUNT_KEY,
        (currentSeenCount + 1).toString(),
      );
    }
  }, [documentVisibility, hasNewLoad]);

  return {
    shouldRunOnboarding: (Number(newLoadIndicatorSeenCount) || 0) < 4,
  };
}
