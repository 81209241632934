/*
- ETA (average: 43%): Top >80% ; Good: 55% - 80% ; Can Improve: 0-54%
- Late Cancellation(average: 1%): Top 0% ; Good 1%-10% ; Can improve: >10%
- Photos taken on-site (average: 79%): Top: >90% ; Good: 80%-90% ; Can improve: 0-79%
 */

import { formatPlural } from 'shared/helpers/IntlHelpers';
import { CarrierPerformanceDTO } from '../data/CarrierPerformanceDTO';

export function totalDeliveriesPlural(total: number | null | undefined) {
  const totalNumber = total || 0;
  return formatPlural(
    totalNumber,
    `Out of ${totalNumber} delivery`,
    `Out of ${totalNumber} deliveries`,
  );
}

export type PerformanceThreshold = 'top' | 'good' | 'can-improve';

export function etaThreshold(eta: number | null): PerformanceThreshold | null {
  if (eta == null) return null;
  if (eta > 80) return 'top';
  if (eta >= 55 && eta <= 80) return 'good';
  return 'can-improve';
}

export function lateCancellationThreshold(
  lateCancellation: number | null,
): PerformanceThreshold | null {
  if (lateCancellation == null) return null;
  if (lateCancellation < 1) return 'top';
  if (lateCancellation >= 1 && lateCancellation <= 10) return 'good';
  return 'can-improve';
}

export function photosOnSiteThreshold(
  photosTaken: number | null,
): PerformanceThreshold | null {
  if (photosTaken == null) return null;
  if (photosTaken > 90) return 'top';
  if (photosTaken >= 80 && photosTaken <= 90) return 'good';
  return 'can-improve';
}

export function calculateRate(finalSample: number, generalSample: number) {
  return Math.round((finalSample / generalSample) * 100);
}

export function calculateAverageRate(
  finalSamples: number[],
  generalSample: number,
) {
  const totalRate = finalSamples.reduce(
    (acc, finalSample) => acc + calculateRate(finalSample, generalSample),
    0,
  );
  return Math.round(totalRate / finalSamples.length);
}

export function calculateETAMeanRate(indicators: CarrierPerformanceDTO) {
  const generalCount = indicators.eta_provided_general_count;
  const finalPickupCount = indicators.eta_provided_final_pickup_count;
  const finalDeliveryCount = indicators.eta_provided_final_delivery_count;

  if (!generalCount) return null;
  if (finalPickupCount == null || finalDeliveryCount == null) return null;

  return calculateAverageRate(
    [finalPickupCount, finalDeliveryCount],
    generalCount,
  );
}

export function calculateETARates(indicators: CarrierPerformanceDTO) {
  const generalCount = indicators.eta_provided_general_count;
  const finalPickupCount = indicators.eta_provided_final_pickup_count;
  const finalDeliveryCount = indicators.eta_provided_final_delivery_count;

  if (!generalCount) return null;
  if (finalPickupCount == null || finalDeliveryCount == null) return null;

  return {
    pickupRate: calculateRate(finalPickupCount, generalCount),
    deliveryRate: calculateRate(finalDeliveryCount, generalCount),
  };
}

export function calculatePhotosOnSiteMeanRate(
  indicators: CarrierPerformanceDTO,
) {
  const generalCount = indicators.photos_on_site_general_count;
  const finalPickupCount = indicators.photos_on_site_final_pickup_count;
  const finalDeliveryCount = indicators.photos_on_site_final_delivery_count;

  if (!generalCount) return null;
  if (finalPickupCount == null || finalDeliveryCount == null) return null;

  return calculateAverageRate(
    [finalPickupCount, finalDeliveryCount],
    generalCount,
  );
}

export function calculatePhotosOnSiteRates(indicators: CarrierPerformanceDTO) {
  const generalCount = indicators.photos_on_site_general_count;
  const finalPickupCount = indicators.photos_on_site_final_pickup_count;
  const finalDeliveryCount = indicators.photos_on_site_final_delivery_count;

  if (!generalCount) return null;
  if (finalPickupCount == null || finalDeliveryCount == null) return null;

  return {
    pickupRate: calculateRate(finalPickupCount, generalCount),
    deliveryRate: calculateRate(finalDeliveryCount, generalCount),
  };
}

export function calculateLateCancellationMeanRate(
  indicators: CarrierPerformanceDTO,
) {
  const generalCount = indicators.late_cancellation_general_count;
  const finalCount = indicators.late_cancellation_final_count;

  if (!generalCount || finalCount == null) return null;

  return calculateRate(finalCount, generalCount);
}
