import {
  NavigateFunction,
  NavigateOptions,
  useBlocker,
  useNavigate,
} from 'react-router-dom';

/**
 * Block router navigation or browser tab close.
 * @param message - e.g: "Changes have not been saved. Leaving can result in unsaved changes being lost."
 */

interface PromptProps {
  when: boolean;
  message: string;
}

export function Prompt({ when, message }: PromptProps) {
  useBlocker(({ currentLocation, nextLocation }) => {
    // Don't block if not active or in Cypress
    if (!when || 'Cypress' in window) return false;

    if (
      currentLocation.pathname === nextLocation.pathname &&
      currentLocation.search === nextLocation.search
    ) {
      return false;
    }

    // eslint-disable-next-line no-alert
    const isUserConfirmed = window.confirm(message);
    return !isUserConfirmed;
  });

  return null;
}

/**
 * Same as useNavigate, but the navigation is deferred to the next event loop
 * tick.
 *
 * It should be used in cases where <Prompt> is triggered unexpectedly after a
 * programmatic navigation due to stale `when` prop value.
 *
 * This is a temporary solution, and should be removed in favor of default
 * navigate function when the issue is resolved in a non-hacky way.
 */
export function usePromptNavigate(): NavigateFunction {
  const navigate = useNavigate();

  return (to, options?) => {
    performNavigation(() => {
      if (typeof to === 'number') {
        navigate(to);
      } else {
        navigate(to, options as NavigateOptions);
      }
    });
  };
}

export function performNavigation(callback: () => void) {
  setTimeout(() => {
    callback();
  }, 0);
}
