import {
  Badge as MuiBadge,
  ButtonBase,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { Color, ColorDark, useResponsiveValue } from '@superdispatch/ui';
import { useState } from 'react';
import { useCookie } from 'shared/helpers/StorageHelpers';
import { SidebarLinkLegacy } from 'shared/layout/sidebar/AppSidebarMenuLegacy';
import { useDispatcherProfile } from 'shared/modules/dispatcher/DispatcherAPI';
import { useShowAddBillingAddressDialog } from 'shared/modules/subscription/core/useShowAddBillingAddressDialog';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import styled from 'styled-components';
import { AppCarrierMenuList } from './AppCarrierMenuList';

const ButtonContainer = styled.div<{ hasBorder: boolean }>`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 8px;
  background-color: ${({ theme }) =>
    theme.palette.type === 'dark' ? ColorDark.White : Color.Dark500};
  border-top: ${({ hasBorder }) =>
    hasBorder ? `1px solid rgba(143, 148, 158, 0.3)` : undefined};
`;

const UserMenu = styled(Popover)`
  & .MuiPaper-root {
    min-width: 240px;
    max-width: 250px;
    margin-left: 40px;
    left: 54px !important;
  }

  & .MuiListItemIcon-root {
    min-width: 40px;
  }
`;

const UserMenuToggler = styled(ButtonBase)`
  flex-wrap: wrap;
  max-width: 100%;
`;

const Badge = styled(MuiBadge)`
  & .MuiBadge-dot {
    margin-top: 5px;
    margin-right: 5px;
    height: 16px;
    width: 16px;
    border: 2px solid ${Color.Dark500};
    border-radius: 50%;
  }
`;

const UserMenuText = styled(Typography)`
  color: ${Color.Blue50};
  font-size: 13px;
  line-height: 16px;
  margin-top: 4px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
  }
`;

export interface CarrierMenuItemsLegacy {
  ratingsLink: SidebarLinkLegacy;
  carrierItems: SidebarLinkLegacy[];
  dispatcherItems: SidebarLinkLegacy[];
  accountItems: SidebarLinkLegacy[];
  hasMenuBadge?: boolean;
}

export function AppCarrierMenuLegacy({
  ratingsLink,
  carrierItems,
  dispatcherItems,
  accountItems,
  hasMenuBadge,
}: CarrierMenuItemsLegacy) {
  const { data: dispatcher } = useDispatcherProfile();
  const { data: settings } = useCarrierSettings();
  const { doesBillingAddressNotExist } = useShowAddBillingAddressDialog();

  const screen = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isMobile = screen === 'mobile' || screen === 'tablet';
  const isSuperPayBankError = !!settings?.superpay.payment_error;

  const userName = dispatcher?.name || 'Unknown';
  const companyName =
    useCookie('companyName') || settings?.carrier.name || 'Unknown';
  const [carrierMenuNode, setCarrierMenuNode] = useState<Element>();

  const handleCloseMenu = () => {
    setCarrierMenuNode(undefined);
  };

  const carrierRating = settings?.rating || { count: 0, percentage: 0 };
  const isVerified = settings?.carrier.verification_status === 'approved';

  const color =
    isSuperPayBankError || doesBillingAddressNotExist ? 'error' : 'primary';

  return (
    <>
      <Tooltip title="Click to open menu" placement="right" enterDelay={1000}>
        <ButtonContainer
          hasBorder={!isMobile}
          data-intercom-target="sidebar-menu-profile-section"
        >
          <UserMenuToggler
            onClick={({ target }) => {
              setCarrierMenuNode(target as Element);
            }}
          >
            <Badge
              overlap="rectangular"
              color={color}
              invisible={hasMenuBadge}
              variant="dot"
            >
              <AccountCircle
                htmlColor={Color.Silver500}
                color="inherit"
                fontSize="large"
              />
            </Badge>
            <UserMenuText align="center" noWrap={true}>
              {companyName}
            </UserMenuText>
          </UserMenuToggler>
        </ButtonContainer>
      </Tooltip>

      <UserMenu
        id="carrier-menu"
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorEl={carrierMenuNode}
        open={!!carrierMenuNode}
        onClose={handleCloseMenu}
      >
        <AppCarrierMenuList
          userName={userName}
          companyName={companyName}
          isVerified={isVerified}
          carrierRating={carrierRating}
          ratingsLink={ratingsLink}
          handleCloseMenu={handleCloseMenu}
          carrierItems={carrierItems}
          dispatcherItems={dispatcherItems}
          accountItems={accountItems}
        />
      </UserMenu>
    </>
  );
}
