import { Box } from '@superdispatch/ui-lab';
import { LoadOffersDrawerContent } from './LoadOffersDrawer';

export function LoadOffersPageMobile() {
  return (
    <Box paddingBottom="xxlarge" backgroundColor="White">
      <LoadOffersDrawerContent />
    </Box>
  );
}
