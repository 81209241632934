import {
  EMPTY_ERROR_MESSAGE,
  FormikEnhancedStatus,
} from '@superdispatch/forms';
import { useRef } from 'react';
import { createAPIError } from 'shared/api/APIError';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { useAppFormik } from 'shared/form/AppFormik';
import { useRegisterVerificationError } from 'shared/modules/verified-carrier-application/data/CarrierProfileAPI';
import { useCarrierSettingsCache } from 'shared/settings/CarrierSettingsAPI';
import { yupNumber, yupObject } from 'shared/utils/YupUtils';
import { useCompleteMicroDepositVerification } from '../../data/MoovAPI';
import { MoovActionError } from '../../data/MoovDTO';
import { trackSuperPayEvent } from '../../data/SuperPayAnalytics';
import { useGenerateMoovTokenAndGetAccount } from '../../data/SuperPayAPI';
import { logPaymentError, logPaymentInfo } from '../PaymentLogger';

const isStaging = import.meta.env.VITE_APP_TARGET === 'staging';

interface StatusError {
  isExceeded: boolean;
  isIncorrect: boolean;
  isIncorrectTotalSum: boolean;
  isIncorrectLastValues: boolean;
  isStatusError: boolean;
}

export function getStatusErrors(
  status: Partial<FormikEnhancedStatus<unknown>>,
): StatusError {
  const payload = (status.payload as Error) || {};
  const message = payload.message || '';
  const isExceeded = message.includes('exceeded');
  const isIncorrect = message.includes('incorrect');
  const isIncorrectTotalSum = message.includes('warning_total_sum');
  const isIncorrectLastValues = message.includes('warning_repeated_entry');

  return {
    isExceeded,
    isIncorrect,
    isIncorrectTotalSum,
    isIncorrectLastValues,
    isStatusError:
      isExceeded || isIncorrect || isIncorrectTotalSum || isIncorrectLastValues,
  };
}

function getVerificationErrorText(errors: StatusError): string {
  const {
    isIncorrectTotalSum,
    isIncorrectLastValues,
    isIncorrect,
    isExceeded,
  } = errors;

  switch (true) {
    case isIncorrectTotalSum:
      return 'Incorrect Total Sum';
    case isIncorrectLastValues:
      return 'Repeated Incorrect Entry';
    case isIncorrect:
      return 'Incorrect';
    case isExceeded:
      return 'Exceeded';
    default:
      return 'Failed';
  }
}

function sendVerificationEvent(status?: StatusError) {
  trackSuperPayEvent({
    name: 'Carrier Submitted Bank Account Verification',
    verification_response: status
      ? getVerificationErrorText(status)
      : 'Verified',
  });
}

function isCorrectAmountsValue(values: [number, number, number]) {
  const [first, second, third] = values.sort(
    (firstValue, secondValue) => firstValue - secondValue,
  );

  return {
    isCorrectAmounts: first + second === third,
    minValues: [first, second],
  };
}

const depositAmountsValidationSchema = yupObject({
  firstDeposit: yupNumber()
    .test(
      'isNumberValid',
      'This field is required',
      // Zeros are allowed for staging
      (value) => isStaging || !!value,
    )
    .required(),
  secondDeposit: yupNumber()
    .test(
      'isNumberValid',
      'This field is required',
      (value) => isStaging || !!value,
    )
    .required(),
  thirdDeposit: yupNumber()
    .test(
      'isNumberValid',
      'This field is required',
      (value) => isStaging || !!value,
    )
    .required(),
});

const errorsMessage = {
  firstDeposit: EMPTY_ERROR_MESSAGE,
  secondDeposit: EMPTY_ERROR_MESSAGE,
  thirdDeposit: EMPTY_ERROR_MESSAGE,
};

interface Props {
  onComplete: () => void;
}

export function useBankVerificationFormDrawer({ onComplete }: Props) {
  const { mutateAsync: registerVerificationError } =
    useRegisterVerificationError();
  const { invalidateCarrierSettings } = useCarrierSettingsCache();

  const { mutateAsync: completeMicroDepositVerification } =
    useCompleteMicroDepositVerification();

  const { data: moovInformation } =
    useGenerateMoovTokenAndGetAccount('bank_account');
  const bankAccountID = moovInformation?.moov_bank_account_id;

  const lastValues = useRef<number[] | null>();

  function isCorrectLastValues(values: number[]) {
    if (!lastValues.current) {
      return true;
    }
    const lastValuesSorted = lastValues.current
      .slice()
      .sort((firstValue, secondValue) => firstValue - secondValue);

    const valuesSorted = values.sort(
      (firstValue, secondValue) => firstValue - secondValue,
    );

    return lastValuesSorted.join('') !== valuesSorted.join('');
  }

  const formik = useAppFormik({
    validationSchema: depositAmountsValidationSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      const { firstDeposit, secondDeposit, thirdDeposit } = values;

      const isCorrectLastValue = isCorrectLastValues([
        firstDeposit,
        secondDeposit,
        thirdDeposit,
      ]);

      if (!isCorrectLastValue) {
        return Promise.reject(
          createAPIError({
            message: 'warning_repeated_entry',
            context: errorsMessage,
          }),
        );
      }

      const { isCorrectAmounts, minValues } = isCorrectAmountsValue([
        firstDeposit,
        secondDeposit,
        thirdDeposit,
      ]);

      if (!isCorrectAmounts) {
        return Promise.reject(
          createAPIError({
            message: 'warning_total_sum',
            context: errorsMessage,
          }),
        );
      }

      return completeMicroDepositVerification(
        minValues,
      ) as unknown as APIResponse;
    },
    onSubmitSuccess: (_, values) => {
      lastValues.current = null;
      sendVerificationEvent();
      logPaymentInfo(
        'Carrier Submitted Bank Account Verification',
        'VerifyBankAccountForm',
        { amounts: values },
      );
      invalidateCarrierSettings();
      onComplete();
    },
    onSubmitFailure: (error, values) => {
      const { firstDeposit, secondDeposit, thirdDeposit } =
        depositAmountsValidationSchema.cast(values);
      lastValues.current = [firstDeposit, secondDeposit, thirdDeposit];

      const statusErrors = getStatusErrors({
        payload: error,
      });

      sendVerificationEvent(statusErrors);

      const { isIncorrect, isExceeded, isStatusError } = statusErrors;

      if (isStatusError) {
        formik.setErrors(errorsMessage);
      } else {
        logPaymentError(error, 'MoovAPI.completeMicroDepositVerification', {
          amounts: values,
        });
      }

      if ((isIncorrect || isExceeded) && bankAccountID) {
        void registerVerificationError(bankAccountID);
      }
    },
    getSuccessMessage: () => `Bank account verified`,
    getErrorMessage: (error: MoovActionError) => {
      const { isStatusError } = getStatusErrors({
        payload: error,
      });

      return isStatusError
        ? null
        : error.statusCode === 403
        ? 'Not authorized to verify bank account.'
        : `Failed to submit the form. ${error.message}`;
    },
  });

  return formik;
}
