import { useEffect } from 'react';

export function usePrintHTMLContent(html: string) {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'p' && html) {
        event.preventDefault();
        printHTMLContent(html);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [html]);
}

function printHTMLContent(html: string) {
  // Open a new window for printing
  const printWindow = window.open('', 'print') as Window;
  // Write the entire HTML document to the new window
  printWindow.document.open();
  printWindow.document.write(html);
  printWindow.document.close();
  //Timeout for getting the content css and js
  printWindow.onload = () => {
    printWindow.print();
    printWindow.close();
  };
}
