import { ColorDynamic } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const IntercomChatIcon = createSvgIcon(
  'IntercomChatIcon',
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8039 3H6.19608C4.98322 3 4 4.04467 4 5.33333V17.6667C4 18.9553 4.98322 20 6.19608 20H15.0452L20 22.4614V17.6725C20 17.6705 20 17.6686 20 17.6667V5.33333C20 4.04467 19.0168 3 17.8039 3ZM6.87894 14.7648C7.00125 14.5211 7.2863 14.429 7.51562 14.5589C10.5145 16.2583 13.4856 16.2583 16.4845 14.5589C16.7138 14.429 16.9988 14.5211 17.1212 14.7648C17.2435 15.0085 17.1567 15.3113 16.9274 15.4413C13.6517 17.2975 10.3484 17.2975 7.07271 15.4413C6.84339 15.3113 6.75664 15.0085 6.87894 14.7648Z"
      fill="currentColor"
    />
  </svg>,
  {
    viewBox: '0 0 24 24',
    style: { height: '24px', width: '24px' },
    htmlColor: ColorDynamic.Silver500,
  },
);
