import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FormattedDate } from '@superdispatch/dates';
import { Stack, Tag, useResponsiveValue, useUID } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { isEqual } from 'lodash-es';
import { ReactNode, useEffect, useMemo } from 'react';
import { getUTMTag, trackEvent } from 'shared/helpers/Analytics';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { capitalize } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import {
  LoadGroupByOptionDTO,
  LoadGroupByTab,
} from '../data/LoadGroupByOptionsDTO';
import { useLoadsGroup } from '../data/LoadsAPI';
import {
  formatLoadOrderByField,
  LoadOrderByField,
  LoadsPageParams,
} from '../data/LoadsPageParams';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';

const Wrapper = styled(Paper)`
  overflow: auto;
  width: 300px;
  max-height: calc(100vh - 200px);
`;

function parseOption(
  item: LoadGroupByOptionDTO,
  groupBy: LoadOrderByField,
): [ReactNode, Partial<LoadsPageParams>] {
  if (item.name == null) {
    return ['Empty', { [`is_empty_${groupBy}`]: true }];
  }

  switch (groupBy) {
    case 'pickup_date':
    case 'pickedup_date':
    case 'delivery_date':
    case 'delivered_date': {
      return [
        <FormattedDate
          key={groupBy}
          variant="Date"
          date={item.name as string}
        />,
        { [`${groupBy}_after`]: item.name, [`${groupBy}_before`]: item.name },
      ];
    }
    case 'is_dispatched_to_carrier':
      return [
        item.name ? 'Dispatched to Carrier' : 'Not Dispatched to Carrier',
        { [groupBy]: Boolean(item.name) },
      ];
    default:
      return [item.name, { [groupBy]: item.name }];
  }
}

interface LoadsPageGroupByListProps {
  tab: LoadGroupByTab;
  groupBy: LoadOrderByField;
  selectedParams?: Partial<LoadsPageParams>;
  onSelect?: (option: Partial<LoadsPageParams>) => void;
}

export function LoadsPageGroupByList({
  tab,
  groupBy,
  selectedParams,
  onSelect,
}: LoadsPageGroupByListProps) {
  const uid = useUID();
  const { params, updateParams } = useLoadsPageContext();
  const { data, error, isInitialLoading } = useLoadsGroup({
    tab,
    groupby: groupBy,
    drivers: params.drivers || selectedParams?.drivers,
    terminals: params.terminals || selectedParams?.terminals,
    dispatchers: params.dispatchers || selectedParams?.dispatchers,
  });
  const isMobile = useResponsiveValue(true, false);
  const options = useMemo(
    () =>
      data?.items?.map((item) => {
        const [label, optionParams] = parseOption(item, groupBy);
        const isSelected = !Object.entries(optionParams).some(
          ([key, value]) => {
            const paramKey = key as keyof LoadsPageParams;
            return !isEqual(
              value,
              params[paramKey] || selectedParams?.[paramKey],
            );
          },
        );
        return {
          label,
          isSelected,
          optionParams,
          count: item.count,
        };
      }),
    [groupBy, data, params, selectedParams],
  );

  const selectedOption = useMemo(
    () => options?.find((option) => option.isSelected),
    [options],
  );

  useErrorSnackbar(error);

  useEffect(() => {
    trackEvent('Carrier Filtered Loads', {
      filter_name: selectedOption?.label,
      filter_group: formatLoadOrderByField(groupBy),
      tab: capitalize(tab),
      utm_medium: getUTMTag('utm_medium') || 'Load List',
      loads_count: selectedOption?.count,
    });
  }, [groupBy, tab, selectedOption]);

  const lestElement = (
    <List
      aria-labelledby={uid}
      subheader={
        <ListSubheader id={uid} disableSticky={true}>
          {formatLoadOrderByField(groupBy)}
        </ListSubheader>
      }
    >
      {options?.map(({ count, label, optionParams, isSelected }, index) => (
        <ListItem
          key={`${groupBy}@${index}`}
          button={true}
          selected={isSelected}
          onClick={() => {
            if (isMobile) {
              onSelect?.(optionParams);
            } else {
              updateParams(
                (prev) => ({
                  stage: prev.stage,
                  drivers: prev.drivers,
                  order_by: prev.order_by,
                  dispatchers: prev.dispatchers,
                  terminals: prev.terminals,
                  ...optionParams,
                }),
                { strategy: 'reset' },
              );
            }
          }}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{ variant: 'body2' }}
          />
          <ListItemSecondaryAction>
            <Tag color="grey" variant="subtle">
              {count}
            </Tag>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  if (isMobile) {
    if (isInitialLoading) {
      return (
        <Box padding="small">
          <Stack space="small">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Stack>
        </Box>
      );
    }

    if (options?.length) {
      return lestElement;
    }

    return (
      <Typography color="textSecondary" align="center">
        No available filter options
      </Typography>
    );
  }

  if (!options?.length) return null;

  return <Wrapper>{lestElement}</Wrapper>;
}
