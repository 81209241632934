import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { InviteDispatcherButton } from './DispatcherInviteButton';

interface DispatchersSidebarHeaderProps {
  handleOpen: () => void;
}

export function DispatchersSidebarHeader({
  handleOpen,
}: DispatchersSidebarHeaderProps) {
  const isMobile = useResponsiveValue(true, false);
  const { searchQueryDrivers } = useDispatcherPageContext();
  const [searchText, _, setSearchText] = searchQueryDrivers;

  return (
    <Stack space="small">
      {!isMobile && <InviteDispatcherButton handleOpen={handleOpen} />}
      <TextField
        fullWidth={true}
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value);
        }}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="action" />
            </InputAdornment>
          ),
          endAdornment: !!searchText && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setSearchText('');
                }}
              >
                <Clear color="action" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
