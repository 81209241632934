import { Typography } from '@material-ui/core';
import { parseDate } from '@superdispatch/dates';
import { ColorDynamic, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Link, useMatch, useNavigate, useParams } from 'react-router-dom';
import { startIntercomSurvey } from 'shared/helpers/Intercom';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { ContentProgressIndicator } from 'shared/layout/ContentProgressIndicator';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { useLocationParams } from 'shared/routing/LocationParams';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import emptyPageDraw from './assets/empty-page-draw.svg';
import emptyPageTruckIcon from './assets/empty-trip.png';
import { useTripsCache, useTripsCount, useTripsPage } from './data/TripsAPI';
import {
  setDefaultTripLoadsOrdering,
  tripsPageParamsSchema,
} from './data/TripsDTO';
import { ArchiveTripDialog } from './trips-list-page/ArchiveTripDialog';
import { CreateTripDialog } from './trips-list-page/CreateTripDialog';
import { DeleteTripDialog } from './trips-list-page/DeleteTripDialog';
import { TripsListContent } from './trips-list-page/TripsListContent';
import { TripsListContentLegacy } from './trips-list-page/TripsListContentLegacy';
import { TripsListHeader } from './trips-list-page/TripsListHeader';
import { UnarchiveTripDialog } from './trips-list-page/UnarchiveTripDialog';

const INTERCOM_SURVERY_ID =
  import.meta.env.VITE_APP_TARGET === 'production' ? '43310511' : '43282706';
const INTERCOM_SURVEY_SEEN_STATE_KEY = 'trip_intercom_survey_seen_state';

const EmptyPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  position: relative;
`;

const LinkText = styled(Link)`
  text-decoration: none;
  color: ${ColorDynamic.Blue500};
`;

const EmptyDirection = styled.div`
  position: absolute;
  top: -30px;
  right: -150px;
`;

export function TripsList() {
  const shouldShowTripChanges = useFlag('trip_improvements_v2');
  const navigate = useNavigate();
  const [params] = useLocationParams({
    yupSchema: tripsPageParamsSchema,
  });
  const isMobile = useResponsiveValue(true, false);
  const intercomSurverySeenState = useLocalStore(
    INTERCOM_SURVEY_SEEN_STATE_KEY,
  );

  const {
    data: trips,
    refetch,
    isInitialLoading,
    isFetching,
  } = useTripsPage(params);
  const { invalidateTrips } = useTripsCache();
  const tripsCreateMatch = useMatch('/trips/create');
  const { action, actionGUID } = useParams();
  const { data: tripsCount, isInitialLoading: isTripsCountLoading } =
    useTripsCount();

  useEffect(() => {
    setDefaultTripLoadsOrdering(params.ordering);
  }, [params.ordering]);

  useEffect(() => {
    const isFirstTripCompleted = trips?.data.some((trip) => {
      const isNewTrip =
        parseDate(trip.changed_at).startOf('day') >
        DateTime.fromISO('2024-11-01').startOf('day');

      return trip.loads.delivered === trip.loads.total && isNewTrip;
    });

    if (isFirstTripCompleted && intercomSurverySeenState !== 'seen') {
      startIntercomSurvey(INTERCOM_SURVERY_ID);
      LocalStore.set(INTERCOM_SURVEY_SEEN_STATE_KEY, 'seen');
    }
  }, [intercomSurverySeenState, trips?.data]);

  const closeDialog = () => {
    navigate(-1);
  };

  return (
    <>
      <DocumentTitle title="Trips" />

      <CreateTripDialog
        isOpen={tripsCreateMatch != null}
        onClose={closeDialog}
      />

      {action === 'delete' && actionGUID && (
        <DeleteTripDialog
          onClose={closeDialog}
          tripGUID={actionGUID}
          onSubmitSuccess={() => {
            void refetch();
          }}
        />
      )}

      {action === 'archive' && actionGUID && (
        <ArchiveTripDialog
          onClose={closeDialog}
          tripGUID={actionGUID}
          onSubmitSuccess={invalidateTrips}
        />
      )}

      {action === 'unarchive' && actionGUID && (
        <UnarchiveTripDialog
          onClose={closeDialog}
          tripGUID={actionGUID}
          onSubmitSuccess={() => {
            closeDialog();
            invalidateTrips();
          }}
        />
      )}

      <PageLayout
        stickyHeader={true}
        disablePaddings={true}
        loading={isInitialLoading || isFetching}
        header={<TripsListHeader />}
      >
        {isInitialLoading || isTripsCountLoading ? (
          <ContentProgressIndicator />
        ) : tripsCount?.active === 0 && tripsCount.archived === 0 ? (
          <EmptyPageContainer>
            <Box position="relative" maxWidth="380px">
              <Stack space="medium" align="center">
                <img
                  src={emptyPageTruckIcon}
                  width={204}
                  height={105}
                  alt="empty-page-truck-icon"
                />
                <Stack>
                  <Typography variant="h3" align="center">
                    No Trips
                  </Typography>
                  <Stack>
                    <Typography color="textSecondary" align="center">
                      Group loads into a trip to manage them all in one place.
                    </Typography>
                    <Typography color="textSecondary" align="center">
                      <LinkText to="create">Create a trip </LinkText>
                      by clicking the button at the top right.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              {!isMobile && (
                <EmptyDirection>
                  <img src={emptyPageDraw} alt="empty-page-draw" />
                </EmptyDirection>
              )}
            </Box>
          </EmptyPageContainer>
        ) : shouldShowTripChanges ? (
          <TripsListContent />
        ) : (
          <TripsListContentLegacy counts={tripsCount} />
        )}
      </PageLayout>
    </>
  );
}
