import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const CloudUploadIcon = createSvgIcon(
  'CloudUploadIcon',
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M16.648 4.07612C17.6509 4.97493 18.3067 6.15934 18.5295 7.45714C20.7753 8.03675 22.4168 10.1326 22.4211 12.4888C22.4211 13.7908 21.9282 15.0424 21.0239 16.0168C20.1153 16.9996 18.8938 17.5876 17.5438 17.68L12.9341 17.6817V11.9972L14.3642 13.275C14.4809 13.3855 14.6268 13.4409 14.8021 13.4409C14.9739 13.4409 15.1215 13.3855 15.2447 13.275L15.6095 12.948C15.7295 12.8345 15.7895 12.7038 15.7895 12.5555C15.7895 12.4043 15.7295 12.272 15.6095 12.1586L12.4427 9.31927C12.3227 9.21168 12.1753 9.15789 12 9.15789C11.8217 9.15789 11.6757 9.21168 11.5622 9.31927L8.39539 12.1586C8.27215 12.269 8.21056 12.4014 8.21056 12.5555C8.21056 12.7067 8.27222 12.8375 8.39539 12.948L8.7602 13.275C8.87698 13.3855 9.02293 13.4408 9.19803 13.4408C9.37315 13.4408 9.51907 13.3855 9.63587 13.275L11.066 11.9972V17.6823L5.91198 17.6842C4.74623 17.6212 3.65333 17.0794 2.82616 16.1638C2.02042 15.2692 1.57898 14.1394 1.57898 12.976C1.57898 11.5354 2.29472 10.183 3.47333 9.34296C3.43047 9.12455 3.40475 8.90195 3.40475 8.67095C3.40475 6.88594 4.88766 5.43273 6.70915 5.43273C6.92344 5.43273 7.13773 5.45373 7.34345 5.49153C7.81918 4.67673 8.49206 3.98372 9.30209 3.47132C10.275 2.85392 11.4107 2.52631 12.5765 2.52631C14.0851 2.52631 15.5337 3.07652 16.648 4.07612ZM11.066 17.6823V20.9635C11.066 21.1146 11.1268 21.2375 11.2485 21.332C11.3701 21.4265 11.5168 21.4737 11.6887 21.4737H12.3113C12.4832 21.4737 12.6299 21.4265 12.7516 21.332C12.8732 21.2375 12.9341 21.1146 12.9341 20.9635V17.6817L11.066 17.6823Z"
    fill="currentColor"
  />,
  {
    viewBox: '0 0 24 24',
    style: { height: '24px', width: '24px' },
    fill: Color.Dark100,
  },
);
