import { Menu, MenuItem } from '@material-ui/core';
import { Cancel, MoreHoriz, PowerSettingsNew } from '@material-ui/icons';
import {
  ColorDynamic,
  Inline,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useFlag } from 'shared/settings/FeatureToggles';
import { MenuButton } from 'shared/ui/MenuButton';
import { trackDispatchersEvent } from '../data/DispatchersAnalytics';
import { useReactivateDispatcher } from '../data/DispatchersAPI';
import { useDispatcherPageContext } from '../data/DispatchersProvider';
import { DispatcherPageMutationDialogType } from './DispatchersPageDetailsHeader';

interface DispatcherDetailsMenuButtonProps {
  connectionStatus: 'deactivated' | 'activated';
  onSelect: (modal: DispatcherPageMutationDialogType) => void;
}

export function DispatcherDetailsMenuButton({
  connectionStatus,
  onSelect,
}: DispatcherDetailsMenuButtonProps) {
  const isMobile = useResponsiveValue(true, false);
  const { addSnackbar } = useSnackbarStack();
  const { dispatcher } = useDispatcherPageContext();
  const shouldShowDispatcherDeactivation = useFlag(
    'dispatcher_management_deactivation',
  );
  const { mutate: reactivateDispatcher, isLoading: isReactivating } =
    useReactivateDispatcher({
      onSuccess: () => {
        addSnackbar(`${dispatcher.data?.name || 'Dispacher'} reactivated`, {
          variant: 'success',
        });
        trackDispatchersEvent({ name: 'Carrier Reactivated Dispatcher' });
      },
    });

  const dispatcherGUID = dispatcher.data?.guid;

  if (connectionStatus === 'deactivated') {
    if (isMobile) {
      return (
        <MenuButton
          size="small"
          aria-label="dispatcher deactivation options"
          renderMenu={({ onClose, ...menuProps }) => (
            <Menu {...menuProps} onClose={onClose}>
              {shouldShowDispatcherDeactivation && dispatcherGUID && (
                <MenuItem
                  key="reactivate"
                  onClick={() => {
                    onClose();
                    reactivateDispatcher(dispatcherGUID);
                  }}
                >
                  <TextBox>Reactivate</TextBox>
                </MenuItem>
              )}
              <MenuItem
                key="delete"
                onClick={() => {
                  onClose();

                  onSelect('delete');
                }}
              >
                <TextBox>Remove</TextBox>
              </MenuItem>
            </Menu>
          )}
        >
          <MoreHoriz />
        </MenuButton>
      );
    }
    return (
      <Inline>
        {shouldShowDispatcherDeactivation && dispatcherGUID && (
          <Button
            pending={isReactivating}
            variant="neutral"
            onClick={() => {
              reactivateDispatcher(dispatcherGUID);
            }}
          >
            Reactivate
          </Button>
        )}

        <Button
          key="delete"
          variant="neutral"
          onClick={() => {
            onSelect('delete');
          }}
        >
          <Inline noWrap={true} space="xxsmall" verticalAlign="center">
            <Cancel fontSize="small" htmlColor={ColorDynamic.Red500} />
            Remove
          </Inline>
        </Button>
      </Inline>
    );
  }

  return (
    <Button
      variant="neutral"
      onClick={() => {
        onSelect('deactivate');
      }}
    >
      <Inline noWrap={true} space="xxsmall" verticalAlign="center">
        <PowerSettingsNew fontSize="small" />
        Deactivate
      </Inline>
    </Button>
  );
}
