import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { APIListQueryResult, useAPIListQuery } from 'shared/api/APIListQuery';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import { APIPageQueryResult, useAPIPageQuery } from 'shared/api/APIPageQuery';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import {
  APIErrorResponse,
  APIResponse,
  parseAPIResponse,
  request,
  requestCarrierAPI,
} from 'shared/api/CarrierAPIClient';
import { uploadMedia } from 'shared/data/MediaServiceAPI';
import { Geocoding } from 'shared/geo/GeoHelpers';
import { parseAttachmentFileType } from '../../loads/data/LoadDTO';
import {
  AddressVenueDTO,
  EditTripDTO,
  LoadForTripDTO,
  loadForTripSchema,
  PointDTO,
  ReorderTripLoadDTO,
  TripDTO,
  tripSchema,
  TripsCountResponse,
  tripsCountSchema,
  TripShortDTO,
  tripShortSchema,
  TripsPageParams,
} from './TripsDTO';

export interface CreateTripResponse {
  guid: string;
}

export function useTripsAPI() {
  const { invalidateTrips, invalidateTrip, updateTripCache } = useTripsCache();

  return useMemo(() => {
    function createTrip(
      json: EditTripDTO,
    ): Promise<APIResponse<CreateTripResponse>> {
      return requestCarrierAPI('POST /internal/web/trips/', { json }).then(
        (response: APIResponse<CreateTripResponse>) => {
          invalidateTrips();
          return response;
        },
      );
    }

    function editTrip(
      guid: string,
      json: EditTripDTO,
    ): Promise<APIResponse<EditTripDTO>> {
      return requestCarrierAPI(
        ['PATCH /internal/web/trips/{guid}/edit/', { guid }],
        { json },
      ).then((response: APIResponse<EditTripDTO>) => {
        updateTripCache(guid, {
          name: json.name,
        });
        invalidateTrips();
        invalidateTrip(guid);
        return response;
      });
    }

    function assignDriver(
      tripGUID: string,
      driverGUID: string,
    ): Promise<APIResponse<string>> {
      return requestCarrierAPI(
        ['POST /internal/web/trips/{guid}/assign-driver/', { guid: tripGUID }],
        { json: { driver_guid: driverGUID } },
      );
    }

    return { createTrip, editTrip, assignDriver };
  }, [invalidateTrips, updateTripCache, invalidateTrip]);
}

export function useTripsCache() {
  const queryClient = useQueryClient();

  return useMemo(() => {
    function invalidateTrips() {
      void queryClient.invalidateQueries(['trips']);
    }

    function invalidateTrip(guid: string) {
      void queryClient.invalidateQueries(['trips', 'item', { guid }]);
    }

    function updateTripCache(guid: string, trip: Partial<TripDTO>) {
      void queryClient.setQueryData(
        ['trips', 'item', { guid }],
        (prev: TripDTO | undefined) => {
          if (!prev) return;

          return {
            ...prev,
            name: trip.name,
          };
        },
      );
    }

    return { invalidateTrips, invalidateTrip, updateTripCache };
  }, [queryClient]);
}

export function useTrip(
  guid: null | string | undefined,
  options?: APIQueryOptions<TripDTO>,
): APIQueryResult<TripDTO> {
  return useAPIQuery(
    ['trips', 'item', { guid }],
    () => requestCarrierAPI(['GET /internal/web/trips/{guid}/', { guid }]),
    { enabled: !!guid, schema: tripSchema, ...options },
  );
}

export function useTripsCount(): APIQueryResult<TripsCountResponse> {
  return useAPIQuery(
    ['trips', 'count'],
    () => requestCarrierAPI('GET /internal/web/trips/counts/'),
    { schema: tripsCountSchema },
  );
}

export function useTripsPage(
  params?: TripsPageParams,
): APIPageQueryResult<TripShortDTO> {
  return useAPIPageQuery(
    ['trips', 'list', { params }],
    () => {
      const { query, search_type, ...rest } = params || {};
      return requestCarrierAPI([
        'GET /internal/web/trips/list/{?driver,query,rest*}',
        {
          rest,
          driver: search_type === 'driver' ? query : undefined,
          query: search_type === 'trip' ? query : undefined,
        },
      ]);
    },
    { schema: tripShortSchema },
  );
}

export function useRemoveTrip(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/{guid}/remove/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useArchiveTrip(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/trips/{guid}/archive/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useUnarchiveTrip(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'POST /internal/web/trips/{guid}/unarchive/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useAssignTripDriver(
  tripGUID: string,
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (driverGUID) =>
      requestCarrierAPI(
        ['POST /internal/web/trips/{guid}/assign-driver/', { guid: tripGUID }],
        { json: { driver_guid: driverGUID } },
      ).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useUnassignTripDriver(
  guid: string,
  options?: APIMutationOptions,
): APIMutationResult {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    () =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/{guid}/unassign-driver/',
        { guid },
      ]).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export interface TripLoadsParams {
  page: number;
  trip_guid: string;
  q: string;
  driver_guid?: string;
}

export function useTripLoadsList(
  params: Partial<TripLoadsParams>,
): APIListQueryResult<LoadForTripDTO> {
  return useAPIListQuery(
    ['loads', 'for-trips', { params }],
    (page) => {
      return requestCarrierAPI([
        'GET /internal/web/loads/for-trip/{?page,query*}',
        { page, query: params },
      ]);
    },
    {
      schema: loadForTripSchema,
      cacheTime: 0,
    },
  );
}

export function useAddTripLoad(
  tripGUID: string,
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (loadGUID) =>
      requestCarrierAPI(
        ['POST /internal/web/trips/{guid}/add-load/', { guid: tripGUID }],
        { json: { load_guid: loadGUID } },
      ).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useRemoveTripLoad(
  tripGUID: string,
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const { invalidateTrips } = useTripsCache();
  return useAPIMutation(
    (loadGUID) =>
      requestCarrierAPI(
        ['DELETE /internal/web/trips/{guid}/remove-load/', { guid: tripGUID }],
        { json: { load_guid: loadGUID } },
      ).then((response) => {
        invalidateTrips();
        return response;
      }),
    options,
  );
}

export function useReorderTripLoads(
  guid: string,
  options?: APIMutationOptions<ReorderTripLoadDTO[]>,
): APIMutationResult<ReorderTripLoadDTO[]> {
  const { invalidateTrip } = useTripsCache();
  return useAPIMutation(
    (loads) =>
      requestCarrierAPI(
        ['POST /internal/web/trips/{guid}/reorder-loads/', { guid }],
        { json: { loads } },
      ).then((response) => {
        invalidateTrip(guid);
        return response;
      }),
    options,
  );
}

export interface AddTripAttachmentInput {
  file: File;
  tripGUID: string;
}

export function useAddTripAttachment(
  options?: APIMutationOptions<AddTripAttachmentInput>,
): APIMutationResult<AddTripAttachmentInput> {
  const { invalidateTrip } = useTripsCache();
  return useAPIMutation(
    ({ file, tripGUID }) =>
      uploadMedia(file)
        .then((url) =>
          requestCarrierAPI(
            ['POST /internal/web/trips/{tripGUID}/attachment/', { tripGUID }],
            {
              json: {
                trip_guid: tripGUID,
                url,
                name: file.name,
                type: parseAttachmentFileType(file.name),
              },
            },
          ),
        )
        .then((response) => {
          invalidateTrip(tripGUID);
          return response;
        }),
    options,
  );
}

interface DeleteTripAttachmentInput {
  attachmentGUID: string;
  tripGUID: string;
}

export function useDeleteTripAttachment(
  options?: APIMutationOptions<DeleteTripAttachmentInput>,
): APIMutationResult<DeleteTripAttachmentInput> {
  const { invalidateTrip } = useTripsCache();
  return useAPIMutation(
    ({ tripGUID, attachmentGUID }) =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/{tripGUID}/attachment/{attachmentGUID}/',
        { tripGUID, attachmentGUID },
      ]).then((response) => {
        invalidateTrip(tripGUID);
        return response;
      }),
    options,
  );
}

export function useDeleteTripInternalNote(
  options?: APIMutationOptions<{ id: string }>,
): APIMutationResult<{ id: string }> {
  return useAPIMutation(
    ({ id }) =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/notes/{id}/',
        { id },
      ]).then((response) => {
        return response;
      }),
    options,
  );
}

export function useAddTripInternalNote(
  options?: APIMutationOptions<{ text: string; tripGUID: string }>,
): APIMutationResult<{ text: string; tripGUID: string }> {
  const { invalidateTrip } = useTripsCache();
  return useAPIMutation(
    ({ text, tripGUID }) =>
      requestCarrierAPI(
        ['POST /internal/web/trips/{tripGUID}/add-note/', { tripGUID }],
        {
          json: { text },
        },
      ).then((response) => {
        invalidateTrip(tripGUID);
        return response;
      }),
    options,
  );
}

export function useAddTripRoute(
  options?: APIMutationOptions<[PointDTO, Geocoding], { point_guid: string }>,
): APIMutationResult<[PointDTO, Geocoding], { point_guid: string }> {
  const { invalidateTrip } = useTripsCache();
  const queryClient = useQueryClient();

  return useAPIMutation(
    ([data, geocode]) =>
      requestCarrierAPI<APIResponse<{ point_guid: string }>>(
        'POST /internal/web/trips/points/',
        {
          json: data,
        },
      ).then((response) => {
        invalidateTrip(data.trip_guid);
        queryClient.setQueryData(
          ['trips', 'item', { guid: data.trip_guid }],
          (prev: TripDTO | undefined) => {
            if (!prev) return;

            const address =
              data.type === 'destination' ? 'end_address' : 'start_address';

            return {
              ...prev,
              [address]: geocode,
            };
          },
        );
        return response;
      }),
    options,
  );
}

export function useUpdateTripRoute(
  options?: APIMutationOptions<{
    guid: string;
    tripGUID: string;
    data: AddressVenueDTO;
  }>,
): APIMutationResult<{
  guid: string;
  tripGUID: string;
  data: AddressVenueDTO;
}> {
  const { invalidateTrip } = useTripsCache();

  return useAPIMutation(
    ({ guid, data, tripGUID }) =>
      requestCarrierAPI(['PUT /internal/web/trips/points/{guid}/', { guid }], {
        json: data,
      }).then((response) => {
        invalidateTrip(tripGUID);
        return response;
      }),
    options,
  );
}

export function useDeleteTripRoute(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'DELETE /internal/web/trips/points/{guid}/',
        { guid },
      ]),
    options,
  );
}

export function useEditTrip(
  options?: APIMutationOptions<EditTripDTO & { guid: string }>,
): APIMutationResult<EditTripDTO & { guid: string }> {
  const { editTrip } = useTripsAPI();
  return useAPIMutation(({ guid, ...data }) => editTrip(guid, data), options);
}

export function useTripReport(
  options?: UseMutationOptions<Blob, Error, string>,
): UseMutationResult<Blob> {
  return useMutation(
    (guid) =>
      request(['GET /internal/web/trips/{guid}/report/', { guid }]).then(
        async (response) => {
          const contentType = response.headers.get('Content-Type');

          if (contentType?.includes('application/json')) {
            // when the content type is json, the response is an error
            const json = (await response.json()) as APIErrorResponse;
            const parsedJson = await parseAPIResponse<APIErrorResponse>(
              guid,
              json,
            );
            throw new Error(parsedJson.error.user_message);
          }

          return response.blob();
        },
      ),
    options,
  );
}
