import { Skeleton } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import { useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useInitialValues } from 'shared/form/AppFormik';
import { Prompt } from 'shared/routing/NavigationBlock';
import { useInsurances } from './core/CargoInsuranceList';
import { CargoInsurancePreview } from './core/CargoInsurancePreview';
import { ProfileCargoInsuranceForm } from './core/ProfileCargoInsuranceForm';
import { StepForm } from './core/StepForm';
import { trackCarrierProfileEvent } from './data/CarrierProfileAnalytics';
import { useCarrierProfileAPI } from './data/CarrierProfileAPI';
import { cargoInsuranceSchema } from './data/CarrierProfileDTO';

interface StepCargoInsurancePageProps {
  onComplete: () => void;
  onBack?: () => void;
}

export function StepCargoInsurancePage({
  onComplete,
  onBack,
}: StepCargoInsurancePageProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateInsurance, createInsurance } = useCarrierProfileAPI();

  const { currentInsurance, isInitialLoading } = useInsurances({
    onError() {
      addSnackbar('Failed to fetch carrier information', { variant: 'error' });
    },
  });

  const initialValues = useInitialValues(
    cargoInsuranceSchema,
    currentInsurance,
  );

  const formik = useFormikEnhanced({
    initialValues,
    validationSchema: cargoInsuranceSchema,
    onSubmit: (values) => {
      return values.guid ? updateInsurance(values) : createInsurance(values);
    },
    onSubmitSuccess() {
      trackCarrierProfileEvent({
        name: 'Carrier Updated Carrier Info',
        utm_medium: 'Verified Carrier Application',
        utm_content: 'Cargo Insurance',
      });

      onComplete();
    },
    onSubmitFailure() {
      addSnackbar('Failed to update carrier information. Please try again.', {
        variant: 'error',
      });
    },
  });

  if (isInitialLoading) {
    return (
      <div aria-label="Loading Step 2 Cargo Insurance">
        <Skeleton width={170} height={24} />
        <Skeleton width={270} height={24} />
      </div>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Prompt
        when={
          formik.status.type !== 'submitted' &&
          (formik.dirty || formik.isSubmitting)
        }
        message="Changes have not been saved. Leaving will result in unsaved changes being lost."
      />
      <StepForm
        step={3}
        onBack={onBack}
        formContent={<ProfileCargoInsuranceForm />}
        submitButtonText="Continue to Identification"
        documentPreview={<CargoInsurancePreview />}
      />
    </FormikProvider>
  );
}
