import {
  QueryFunctionContext,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIPageQueryOptions,
  APIPageQueryResult,
  useAPIPageQuery,
} from 'shared/api/APIPageQuery';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import {
  APIPageResponse,
  requestCarrierAPI,
} from 'shared/api/CarrierAPIClient';
import {
  accountsPayableSchema,
  customReportSchema,
  driverPaySchema,
  DriverPayTotalReportDTO,
  driverPayTotalReportSchema,
  ReportsAccountsPayableDTO,
  ReportsAccountsReceivableDTO,
  reportsAccountsReceivableSchema,
  ReportsCompanyRevenueDTO,
  ReportsCompanyRevenueParamsDTO,
  reportsCompanyRevenueSchema,
  ReportsCustomDTO,
  ReportsDriverPayDTO,
  ReportsDriverPayParamsDTO,
  ReportsDriverPayQueryParamsDTO,
  ReportsPeriodDTO,
  SendDriverPayReportRequestDTO,
} from './ReportsDTO';

export class ReportsAPI {
  updateDriversPayRate(guid: string, payRate: number) {
    return requestCarrierAPI(
      ['PATCH /internal/web/reports/driver-pay/{guid}/rate/', { guid }],
      { json: { pay_rate: payRate } },
    );
  }
}

export function useReportsAPI() {
  return useMemo(
    () => ({
      sendDriverPayReport: (values: SendDriverPayReportRequestDTO) =>
        requestCarrierAPI(
          'POST /internal/web/reports/driver-pay/email-report/',
          { json: values },
        ),

      sendCustomReport: (values: ReportsCustomDTO) =>
        requestCarrierAPI('POST /internal/web/reports/custom-report/', {
          json: values,
        }),

      updateCustomReport: (values: ReportsCustomDTO) =>
        requestCarrierAPI(
          [
            'PUT /internal/web/reports/saved-reports/{guid}/',
            { guid: values.guid },
          ],
          {
            json: values,
          },
        ),

      createCustomReport: ({ guid, ...values }: ReportsCustomDTO) =>
        requestCarrierAPI('POST /internal/web/reports/saved-reports/', {
          json: values,
        }),
    }),
    [],
  );
}

export function useAccountsPayableReports(
  params: ReportsPeriodDTO,
  options?: APIQueryOptions<ReportsAccountsPayableDTO>,
): APIQueryResult<ReportsAccountsPayableDTO> {
  return useAPIQuery(
    ['reports', 'accountsPayable', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/reports/accounts-payable/{?params*}',
        { params },
      ]),
    { ...options, schema: accountsPayableSchema },
  );
}

export function useDriverPayTotal(
  params: ReportsDriverPayQueryParamsDTO,
  options?: APIQueryOptions<DriverPayTotalReportDTO>,
): APIQueryResult<DriverPayTotalReportDTO> {
  return useAPIQuery(
    ['reports', 'driverPayTotal', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/reports/driver-pay/total/{?params*}',
        { params },
      ]),
    { ...options, schema: driverPayTotalReportSchema },
  );
}

export function useDriverPayReport(
  params: ReportsDriverPayParamsDTO,
  options?: APIPageQueryOptions<ReportsDriverPayDTO>,
): APIPageQueryResult<ReportsDriverPayDTO> {
  return useAPIPageQuery(
    ['reports', 'driver-pay-report', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/reports/driver-pay/{?params*}',
        { params },
      ]),
    {
      ...options,
      schema: driverPaySchema,
    },
  );
}

interface SavedReportsListParams {
  page?: number;
  page_size?: number;
}

export function useSavedReportsList(
  { page, page_size }: SavedReportsListParams = { page: 1, page_size: 20 },
): UseInfiniteQueryResult<APIPageResponse<ReportsCustomDTO>> {
  return useInfiniteQuery(
    ['custom-report', 'saved-reports-list', { page, page_size }],
    ({ pageParam = page }: QueryFunctionContext<string[], number>) => {
      return requestCarrierAPI([
        'GET /internal/web/reports/saved-reports/{?params*}',
        { params: { page: pageParam, page_size } },
      ]);
    },
    {
      getNextPageParam: (
        lastPage: APIPageResponse<ReportsCustomDTO>,
        allPages,
      ) => {
        const maxPage = Math.ceil(
          lastPage.pagination.count / (page_size || 20),
        );
        if (allPages.length === maxPage) {
          return false;
        }

        return allPages.length + 1;
      },
    },
  );
}

export function useDeleteSavedReport(
  options?: APIMutationOptions<string>,
): APIMutationResult<string> {
  const queryClient = useQueryClient();

  return useAPIMutation(
    (guid) =>
      requestCarrierAPI([
        'DELETE /internal/web/reports/saved-reports/{guid}/',
        { guid },
      ]).then((response) => {
        void queryClient.invalidateQueries([
          'custom-report',
          'saved-reports-list',
        ]);
        return response;
      }),
    options,
  );
}

export function useSavedReportItem(
  guid: string,
): APIQueryResult<ReportsCustomDTO> {
  return useAPIQuery(
    ['custom-report', 'saved-reports-item', { guid }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/reports/saved-reports/{guid}/',
        { guid },
      ]),
    { schema: customReportSchema, enabled: !!guid },
  );
}

export function useAccountsReceivableReports(
  params: ReportsPeriodDTO,
): APIQueryResult<ReportsAccountsReceivableDTO> {
  return useAPIQuery(
    ['reports', 'accountsReceivable', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/reports/accounts-receivable/{?params*}',
        { params },
      ]),
    { schema: reportsAccountsReceivableSchema },
  );
}

export function useCompanyRevenueReports(
  params: ReportsCompanyRevenueParamsDTO,
): APIQueryResult<ReportsCompanyRevenueDTO> {
  return useAPIQuery(
    ['reports', 'companyRevenue', { params }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/reports/company-revenue/{?params*}',
        { params },
      ]),
    { schema: reportsCompanyRevenueSchema },
  );
}
