import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  APIMutationOptions,
  APIMutationResult,
  useAPIMutation,
} from 'shared/api/APIMutation';
import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import {
  CarrierEditFmcsaPhoneDTO,
  CarrierFmcsaPhoneDTO,
  carrierFmcsaPhoneSchema,
  VerificationCodeRequestDTO,
  verificationCodeRequestSchema,
  VerificationCodeSubmittingDTO,
} from './PhoneVerificationDTO';

export function useCarrierProfileApi() {
  const queryClient = useQueryClient();
  return useMemo(
    () => ({
      verifyCode: (values: VerificationCodeSubmittingDTO) =>
        requestCarrierAPI(
          'POST /internal/web/carrier/fmcsa-phone/submit-verification-code/',
          {
            json: values,
          },
        ).then((response) => {
          void queryClient.invalidateQueries(['profile', 'carrier-info']);
          return response;
        }),
      editFMCSAPhone: (values: CarrierEditFmcsaPhoneDTO) =>
        requestCarrierAPI(
          'PATCH /internal/web/carrier/fmcsa-phone/send-phone-number/',
          {
            json: values,
          },
        ).then((response) => {
          void queryClient.invalidateQueries(['carrier', 'fmcsa-phone']);
          return response;
        }),
    }),
    [queryClient],
  );
}

export function useCarrierFmcsaPhoneNumber(
  options?: UseAPIQueryOptions<CarrierFmcsaPhoneDTO>,
): APIQueryResult<CarrierFmcsaPhoneDTO> {
  return useAPIQuery(
    ['carrier', 'fmcsa-phone'],
    () => requestCarrierAPI('PUT /internal/web/carrier/fmcsa-phone/'),
    {
      schema: carrierFmcsaPhoneSchema,
      ...options,
    },
  );
}

export type VerificationCodeMethod = 'SMS' | 'CALL';
export function useSendVerificationCode(
  options?: APIMutationOptions<
    VerificationCodeMethod,
    VerificationCodeRequestDTO
  >,
): APIMutationResult<VerificationCodeMethod, VerificationCodeRequestDTO> {
  return useAPIMutation((method) => {
    return requestCarrierAPI(
      'POST /internal/web/carrier/fmcsa-phone/send-verification-code/',
      { json: { method } },
    ).then((response) => ({
      ...response,
      data: verificationCodeRequestSchema.cast(response.data),
    }));
  }, options);
}
