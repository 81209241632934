import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { APIMutationOptions, useAPIMutation } from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import { requestCarrierAPI } from 'shared/api/CarrierAPIClient';
import { TEN_MINUTES } from 'shared/constants/NumberConstants';
import { shallowDiff } from 'shared/utils/DataUtils';
import {
  BillingSettingsDTO,
  DowngradeRequestDTO,
  DriverAppSettingsDTO,
  EmailNotificationSettingsDTO,
  FactoringSettingsDTO,
  GeneralSettingsDTO,
  generalSettingsSchema,
  LoadOfferNotificationSettingsDTO,
  NotificationSettingsPayloadDTO,
  QBOTaxDTO,
  QuickbookDesktopDTO,
  QuickbookOnlineDTO,
  QuickbookOnlineServiceDTO,
} from './SettingsDTO';

export function useGeneralSettings({
  staleTime = TEN_MINUTES,
  ...options
}: APIQueryOptions<GeneralSettingsDTO> = {}): APIQueryResult<GeneralSettingsDTO> {
  return useAPIQuery(
    ['settings', 'general'],
    () => requestCarrierAPI('GET /internal/web/settings/general/'),
    { ...options, staleTime, schema: generalSettingsSchema },
  );
}

export function useDriverAppSettings(
  options?: APIQueryOptions<DriverAppSettingsDTO>,
): APIQueryResult<DriverAppSettingsDTO> {
  return useAPIQuery(
    ['settings', 'driverApp'],
    () => requestCarrierAPI('GET /internal/web/settings/driver-app/'),
    options,
  );
}

export function useBillingSettings(
  options?: APIQueryOptions<BillingSettingsDTO>,
): APIQueryResult<BillingSettingsDTO> {
  return useAPIQuery(
    ['settings', 'billing'],
    () => requestCarrierAPI('GET /internal/web/settings/billing/'),
    options,
  );
}

export function useEmailNotificationSettings(
  options?: APIQueryOptions<EmailNotificationSettingsDTO>,
): APIQueryResult<EmailNotificationSettingsDTO> {
  return useAPIQuery(
    ['settings', 'emailNotification'],
    () => requestCarrierAPI('GET /internal/web/settings/email-notification/'),
    options,
  );
}

export function useLoadOfferNotificationSettings(
  options?: APIQueryOptions<LoadOfferNotificationSettingsDTO>,
): APIQueryResult<LoadOfferNotificationSettingsDTO> {
  return useAPIQuery(
    ['settings', 'loadOfferNotifications'],
    () => requestCarrierAPI('GET /internal/web/settings/notifications/'),
    options,
  );
}

export function useFactoringSettings(
  options?: APIQueryOptions<FactoringSettingsDTO>,
): APIQueryResult<FactoringSettingsDTO> {
  return useAPIQuery(
    ['settings', 'factoring'],
    () => requestCarrierAPI('GET /internal/web/settings/factoring/'),
    options,
  );
}

export function useSettingsAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      patchGeneralSettings: (
        updated: Partial<GeneralSettingsDTO>,
        original?: GeneralSettingsDTO,
      ) =>
        requestCarrierAPI('PATCH /internal/web/settings/general/', {
          json: shallowDiff(updated, original),
        }).then((response) => {
          void queryClient.invalidateQueries(['settings', 'general']);

          return response;
        }),

      patchDriverAppSettings: (
        updated: DriverAppSettingsDTO,
        original?: DriverAppSettingsDTO,
      ) =>
        requestCarrierAPI('PATCH /internal/web/settings/driver-app/', {
          json: shallowDiff(updated, original),
        }).then((response) => {
          void queryClient.invalidateQueries(['settings', 'driverApp']);

          return response;
        }),

      patchBillingSettings: (
        updated: BillingSettingsDTO,
        original?: BillingSettingsDTO,
      ) =>
        requestCarrierAPI('PATCH /internal/web/settings/billing/', {
          json: shallowDiff(updated, original),
        }).then((response) => {
          void queryClient.invalidateQueries(['settings', 'billing']);

          return response;
        }),

      patchEmailNotificationSettings: (
        updated: EmailNotificationSettingsDTO,
        original?: EmailNotificationSettingsDTO,
      ) =>
        requestCarrierAPI('PATCH /internal/web/settings/email-notification/', {
          json: shallowDiff(updated, original),
        }).then((response) => {
          void queryClient.invalidateQueries(['settings', 'emailNotification']);

          return response;
        }),

      updateLoadOfferNotificationSettings: (
        values: NotificationSettingsPayloadDTO,
      ) =>
        requestCarrierAPI('POST /internal/web/settings/notifications/', {
          json: values,
        }).then((response) => {
          void queryClient.invalidateQueries([
            'settings',
            'loadOfferNotifications',
          ]);

          return response;
        }),

      updateSubscriptionSettings: (paymentMethodNonce: string) =>
        requestCarrierAPI('POST /internal/web/settings/subscription/', {
          json: { payment_method_nonce: paymentMethodNonce },
        }),

      resubscribeLoadOfferEmails: () =>
        requestCarrierAPI(
          'POST /internal/web/notifications/resubscribe-to-load-offer-emails/',
        ).then((response) => {
          void queryClient.invalidateQueries([
            'settings',
            'loadOfferNotifications',
          ]);

          return response;
        }),

      downgradeSubscription: (json: DowngradeRequestDTO) =>
        requestCarrierAPI(
          'POST /internal/web/settings/subscription/request-to-downgrade-to-free-plan/',
          { json },
        ),

      createFactoringSettings: (json: FactoringSettingsDTO) =>
        requestCarrierAPI('POST /internal/web/settings/factoring/', {
          json,
        }).then((response) => {
          void queryClient.invalidateQueries(['settings', 'factoring']);

          return response;
        }),

      updateFactoringSettings: (json: FactoringSettingsDTO) =>
        requestCarrierAPI('PUT /internal/web/settings/factoring/', {
          json,
        }).then((response) => {
          void queryClient.invalidateQueries(['settings', 'factoring']);

          return response;
        }),

      disconnectQbo: () => {
        return requestCarrierAPI('GET /quickbooks/online/disconnect/').then(
          (res) => {
            void queryClient.invalidateQueries(['quickbooks', 'online']);
            return res;
          },
        );
      },

      disconnectQbd: () => {
        return requestCarrierAPI('GET /quickbooks/desktop/disconnect/').then(
          (res) => {
            void queryClient.invalidateQueries(['quickbooks', 'desktop']);
            return res;
          },
        );
      },

      createQBDConnection: () => {
        return requestCarrierAPI(
          'POST /quickbooks/desktop/connection/create/',
        ).then((res) => {
          void queryClient.invalidateQueries(['quickbooks', 'desktop']);
          return res;
        });
      },

      checkServiceItemsList: () => {
        return requestCarrierAPI(
          'POST /quickbooks/desktop/items/create-request/',
        ).then((res) => {
          void queryClient.invalidateQueries(['quickbooks', 'desktop']);
          return res;
        });
      },

      importQBDContacts: () => {
        return requestCarrierAPI(
          'POST /quickbooks/desktop/customers/create-request/',
        ).then((res) => {
          void queryClient.invalidateQueries(['quickbooks', 'desktop']);
          return res;
        });
      },
    }),
    [queryClient],
  );
}

export function useResubscribeLoadOfferEmailsMutation(
  options?: APIMutationOptions,
) {
  const { resubscribeLoadOfferEmails } = useSettingsAPI();

  return useAPIMutation(() => resubscribeLoadOfferEmails(), options);
}

export function useQuickbooksOnlineConnectionInfo(
  options?: APIQueryOptions<QuickbookOnlineDTO>,
): APIQueryResult<QuickbookOnlineDTO> {
  return useAPIQuery(
    ['quickbooks', 'online'],
    () => requestCarrierAPI('GET /quickbooks/online/connection-details/'),
    options,
  );
}

export function useQBUpdateTaxMutation() {
  const queryClient = useQueryClient();

  return useAPIMutation(
    (id: string) =>
      requestCarrierAPI('POST /quickbooks/online/taxes/', { json: { id } }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(['quickbooks', 'taxes']);
      },
    },
  );
}

export function useQuickbooksOnlineTaxes(
  options?: APIQueryOptions<QBOTaxDTO[]>,
): APIQueryResult<QBOTaxDTO[]> {
  return useAPIQuery(
    ['quickbooks', 'taxes'],
    () => requestCarrierAPI('GET /quickbooks/online/taxes/'),
    options,
  );
}

export function useQuickbooksOnlineServiceList(
  options?: APIQueryOptions<QuickbookOnlineServiceDTO[]>,
): APIQueryResult<QuickbookOnlineServiceDTO[]> {
  return useAPIQuery(
    ['quickbooks', 'services'],
    () => requestCarrierAPI('GET /quickbooks/online/import-items/'),
    options,
  );
}

export function useDisconnectQboMutation(options?: APIMutationOptions) {
  const { disconnectQbo } = useSettingsAPI();

  return useAPIMutation(() => disconnectQbo(), options);
}

export function useImportQBOContacts(options?: APIMutationOptions) {
  return useAPIMutation(
    () => requestCarrierAPI('GET /quickbooks/online/customers/import/'),
    options,
  );
}

export function useUpdateServiceItem(
  options?: APIMutationOptions<number>,
  type: 'desktop' | 'online' = 'online',
) {
  return useAPIMutation(
    (id) =>
      requestCarrierAPI(`POST /quickbooks/${type}/service-items/`, {
        json: {
          id,
        },
      }),
    options,
  );
}

export function useQBCompanyMutation(
  options?: APIMutationOptions<boolean>,
  type: 'desktop' | 'online' = 'online',
) {
  return useAPIMutation(
    (isAllowedToCreateCustomer: boolean) =>
      requestCarrierAPI(`PATCH /quickbooks/${type}/company/`, {
        json: { is_allowed_to_create_customer: isAllowedToCreateCustomer },
      }),
    options,
  );
}

export function useQBOGrantURL(
  options?: APIQueryOptions<{ grant_url: string; tax: string }>,
): APIQueryResult<{ grant_url: string; tax: string }> {
  return useAPIQuery(
    ['quickbooks', 'grant_url'],
    () => requestCarrierAPI('GET /quickbooks/online/carrier-connection-info/'),
    options,
  );
}

export function useQuickbookDesktopConnectionDetails(
  options?: APIQueryOptions<QuickbookDesktopDTO>,
): APIQueryResult<QuickbookDesktopDTO> {
  return useAPIQuery(
    ['quickbooks', 'desktop'],
    () => requestCarrierAPI('GET /quickbooks/desktop/connection-details/'),
    options,
  );
}

export function useDisconnectQbdMutation(options?: APIMutationOptions) {
  const { disconnectQbd } = useSettingsAPI();

  return useAPIMutation(() => disconnectQbd(), options);
}

export function useCreateQBDConnectionMutation(options?: APIMutationOptions) {
  const { createQBDConnection } = useSettingsAPI();

  return useAPIMutation(() => createQBDConnection(), options);
}

export function useQuickbookDesktopConnectionInfo(
  options?: APIQueryOptions<{ invoice_item: string }>,
): APIQueryResult<{ invoice_item: string }> {
  return useAPIQuery(
    ['quickbooks', 'desktop-carrier-info'],
    () => requestCarrierAPI('GET /quickbooks/desktop/carrier-connection-info/'),
    options,
  );
}

export function useCheckServiceItemsListMutation(options?: APIMutationOptions) {
  const { checkServiceItemsList } = useSettingsAPI();

  return useAPIMutation(() => checkServiceItemsList(), options);
}

export function useImportQBDContactsMutation(options?: APIMutationOptions) {
  const { importQBDContacts } = useSettingsAPI();

  return useAPIMutation(() => importQBDContacts(), options);
}
