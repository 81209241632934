import { useResponsiveValue } from '@superdispatch/ui';
import { SidebarContainer } from '@superdispatch/ui-lab';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { ProfileACHPaymentInfoPage } from './ProfileACHPaymentInfoPage';
import { ProfileBillingInfoPage } from './ProfileBillingInfoPage';
import { ProfileBusinessLicensePage } from './ProfileBusinessLicensePage';
import { ProfileCargoInsurancePage } from './ProfileCargoInsurancePage';
import { ProfileCarrierInfoPage } from './ProfileCarrierInfoPage';
import { ProfileOverviewPage } from './ProfileOverviewPage';
import { ProfilePhoneVerificationPage } from './ProfilePhoneVerificationPage';
import { ProfileSidebar } from './ProfileSidebar';
import { ProfileStateMotorCarrierPermitPage } from './ProfileStateMotorCarrierPermit';
import { superPayRoutes } from './ProfileSuperPayPage';
import { ProfileTermsAndConditionsPage } from './ProfileTermsAndConditionsPage';
import { ProfileTransporterPlateRegistrationPage } from './ProfileTransporterPlateRegistrationPage';
import { ProfileUSDOTPage } from './ProfileUSDOTPage';
import { ProfileW9Page } from './ProfileW9Page';
import { verifiedCarrierApplicationRoutes } from './verified-carrier-application/ProfileVerifiedCarrierApplicationOutlet';

function ProfilePage() {
  const isMobile = useResponsiveValue(true, false);

  if (isMobile) {
    return <SidebarContainer sidebar={<ProfileSidebar />} />;
  }

  return <Navigate to="/profile/overview" replace={true} />;
}

function ProfileContainerOutlet() {
  const isMobile = useResponsiveValue(true, false);

  if (isMobile) {
    return <Outlet />;
  }

  return (
    <SidebarContainer sidebar={<ProfileSidebar />}>
      <Outlet />
    </SidebarContainer>
  );
}

export const profileRoutes: RouteObject[] = [
  {
    path: 'profile',
    children: [
      ...verifiedCarrierApplicationRoutes,
      {
        path: 'phone-verification',
        element: <ProfilePhoneVerificationPage />,
      },
      {
        path: '*',
        element: <ProfileContainerOutlet />,
        children: [
          ...superPayRoutes,
          {
            path: 'overview',
            element: <ProfileOverviewPage />,
          },
          {
            path: 'carrier_info',
            element: <ProfileCarrierInfoPage />,
          },
          {
            path: 'cargo_insurance',
            element: <ProfileCargoInsurancePage />,
          },
          {
            path: 'w9',
            element: <ProfileW9Page />,
          },
          {
            path: 'usdot',
            element: <ProfileUSDOTPage />,
          },
          {
            path: 'transporter_plate_registration',
            element: <ProfileTransporterPlateRegistrationPage />,
          },
          {
            path: 'state_motor_carrier_permit',
            element: <ProfileStateMotorCarrierPermitPage />,
          },
          {
            path: 'business_license',
            element: <ProfileBusinessLicensePage />,
          },
          {
            path: 'billing_info',
            element: <ProfileBillingInfoPage />,
          },
          {
            path: 'ach_payment',
            element: <ProfileACHPaymentInfoPage />,
          },
          {
            path: 'terms_and_conditions',
            element: <ProfileTermsAndConditionsPage />,
          },
        ],
      },
      {
        index: true,
        element: <ProfilePage />,
      },
    ],
  },
];
