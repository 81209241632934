import { useResponsiveValue } from '@superdispatch/ui';
import { SidebarContainer } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ContactsDeleteSnackbar } from './ContactsDeleteSnackbar';
import { ContactsPageList } from './ContactsPageList';
import { trackContactsEvent } from './data/ContactsAnalytics';

export function ContactsPage() {
  const { contactGUID } = useParams();
  const isMobile = useResponsiveValue(true, false);

  const [selectedContacts, setSelectedContacts] = useState(
    () => new Set<string>(),
  );

  useEffect(() => {
    trackContactsEvent({ name: 'CTMS: Opened React Contacts Page' });
  }, []);

  if (contactGUID && isMobile) {
    return <Outlet />;
  }

  return (
    <SidebarContainer
      sidebar={
        <ContactsPageList
          selectedContacts={selectedContacts}
          onSelectedContactsChange={setSelectedContacts}
        />
      }
    >
      <ContactsDeleteSnackbar
        contacts={selectedContacts}
        onClose={() => {
          setSelectedContacts(new Set());
        }}
      />

      {!selectedContacts.size && !isMobile && <Outlet />}
    </SidebarContainer>
  );
}
