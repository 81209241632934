import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import {
  Cancel,
  Comment,
  Edit,
  Email,
  MoreHoriz,
  Person,
  Phone,
  Room,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  InfoCard,
  Inline,
  Stack,
  useResponsiveValue,
  useUID,
} from '@superdispatch/ui';
import { Box, DescriptionItem, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageHeaderContent, PageLayout } from 'shared/layout/PageLayout';
import { BackButton } from 'shared/ui/BackButton';
import { MenuButton } from 'shared/ui/MenuButton';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import emptyListArrow from './assets/empty-list-arrow.png';
import { ContactDeleteDialog } from './ContactDeleteDialog';
import { ContactFormDrawer } from './ContactFormDrawer';
import { useContactsPageRouter } from './ContactsPageRouter';
import { useContact } from './data/ContactsAPI';

const EmptyArrowBox = styled(Box)`
  position: absolute;
  top: -200px;
  left: -50px;
`;

export function ContactsPageDetails() {
  const isMobile = useResponsiveValue(true, false);
  const { currentContactGUID, openList } = useContactsPageRouter();
  const [currentModal, setCurrentModal] = useState<'edit' | 'delete'>();
  const navigate = useNavigate();

  const uid = useUID();
  const labelID = `${uid}-label`;
  const editIconID = `${uid}-edit-icon`;
  const descriptionID = `${uid}-description`;

  const { data: currentContact, isFetching } = useContact(currentContactGUID);

  const closeModal = () => {
    setCurrentModal(undefined);
  };

  if (!currentContactGUID) {
    return (
      <Box
        height="100%"
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <Box position="relative" width={isMobile ? '100%' : '500px'}>
          <EmptyArrowBox>
            <img
              src={emptyListArrow}
              width="213px"
              height="225px"
              alt="empty list arrow"
            />
          </EmptyArrowBox>
          <Stack align="center">
            <Typography variant="h3" align="center">
              No Contacts
            </Typography>
            <Box maxWidth="350px">
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                Add contacts to autofill them when creating a load. Let&apos;s
                add them at the top left.
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <ContactFormDrawer
        type="edit"
        onClose={closeModal}
        guid={currentContactGUID}
        open={currentModal === 'edit'}
      />

      <ContactDeleteDialog
        onDismiss={closeModal}
        guid={currentContactGUID}
        open={currentModal === 'delete'}
        onSuccess={() => {
          closeModal();
          openList();
        }}
      />

      <PageLayout
        loading={isFetching}
        header={
          <PageHeaderContent>
            {!currentContact ? (
              <TextBox variant="heading-2">
                <Skeleton width="200px" />
              </TextBox>
            ) : (
              <Columns space="small" align="bottom">
                <DocumentTitle title={`${currentContact.name} - Contacts`} />

                <Column width="fluid">
                  <Inline verticalAlign="center" space="small">
                    {isMobile && (
                      <BackButton
                        onClick={() => {
                          navigate('/contacts', { replace: true });
                        }}
                      />
                    )}
                    <TextBox id={labelID} variant="heading-2" noWrap={true}>
                      {currentContact.name}
                    </TextBox>
                  </Inline>
                </Column>

                <Column width="content">
                  <MenuButton
                    aria-label="contact options"
                    renderMenu={({ onClose, ...menuProps }) => (
                      <Menu {...menuProps} onClose={onClose}>
                        <MenuItem
                          key="delete"
                          onClick={() => {
                            onClose();
                            setCurrentModal('delete');
                          }}
                        >
                          <TextBox color="red">
                            <Inline
                              noWrap={true}
                              space="xxsmall"
                              verticalAlign="center"
                            >
                              <Cancel fontSize="small" />
                              Delete
                            </Inline>
                          </TextBox>
                        </MenuItem>{' '}
                      </Menu>
                    )}
                  >
                    <MoreHoriz />
                  </MenuButton>
                </Column>
              </Columns>
            )}
          </PageHeaderContent>
        }
      >
        {currentContact != null && (
          <Box
            width={isMobile ? '100%' : '440px'}
            role="description"
            aria-labelledby={labelID}
            aria-describedby={descriptionID}
          >
            <InfoCard>
              <Stack space="small">
                <Columns align="top">
                  <Column>
                    <TextBox variant="heading-3">Contact Info</TextBox>
                  </Column>

                  <Column width="content">
                    <IconButton
                      size="small"
                      aria-labelledby={`${editIconID} ${labelID}`}
                      onClick={() => {
                        setCurrentModal('edit');
                      }}
                    >
                      <Edit id={editIconID} aria-label="edit" />
                    </IconButton>
                  </Column>
                </Columns>

                <DescriptionItem
                  wrap={true}
                  icon={<Room />}
                  aria-label="address"
                  fallback="No address"
                >
                  {joinStrings(
                    ', ',
                    currentContact.address,
                    currentContact.city,
                    currentContact.state,
                    currentContact.zip,
                  )}
                </DescriptionItem>

                <DescriptionItem
                  icon={<Person />}
                  aria-label="contact name"
                  fallback="No contact name"
                >
                  {currentContact.contact}
                </DescriptionItem>

                <DescriptionItem
                  icon={<Email />}
                  aria-label="email"
                  fallback="No email"
                >
                  {currentContact.email}
                </DescriptionItem>

                <DescriptionItem
                  icon={<Phone />}
                  aria-label="phone"
                  fallback="No phone"
                >
                  {currentContact.phone}
                </DescriptionItem>

                <DescriptionItem
                  icon={<Comment />}
                  aria-label="notes"
                  fallback="No notes"
                >
                  {currentContact.notes}
                </DescriptionItem>
              </Stack>
            </InfoCard>
          </Box>
        )}
      </PageLayout>
    </>
  );
}
