import { IconButton, Link, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { useValueObserver } from '@superdispatch/hooks';
import {
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { isAPIError } from 'shared/api/APIError';
import { LoginErrorDialog } from 'shared/auth/core/LoginErrorDialog';
import { LoginLayoutContent } from 'shared/auth/core/LoginLayoutContent';
import { isInactiveAccountError, useAuthAPI } from 'shared/auth/data/AuthAPI';
import { resetPasswordSchema } from 'shared/auth/data/AuthDTO';
import { trackLoginEvent } from 'shared/auth/data/LoginAnalytics';
import { isEmpty } from 'shared/helpers/CommonHelpers';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';

interface RequestPasswordResetLinkSuccessContentProps {
  message: string;
  onBack: () => void;
}

function RequestPasswordResetLinkSuccessContent({
  message,
  onBack,
}: RequestPasswordResetLinkSuccessContentProps) {
  const { showLauncherMessanger } = useCustomerSupport();
  return (
    <LoginLayoutContent>
      <Stack space="small">
        <Inline space="xsmall" verticalAlign="center">
          <IconButton
            edge="start"
            onClick={() => {
              onBack();
              trackLoginEvent({
                name: 'CTMS: Clicked Back to Forgot Password',
              });
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h2">Check Your Email</Typography>
        </Inline>

        <Stack space="large">
          <Typography>{message}</Typography>

          <Typography>
            If you can’t find your email, check your spam, junk, or other
            folders.
          </Typography>

          <Link
            component="button"
            type="button"
            color="primary"
            onClick={() => {
              onBack();
              trackLoginEvent({
                name: 'CTMS: Clicked "I didn\'t receive the email" link',
              });
            }}
          >
            I didn’t receive the email
          </Link>

          <Link
            component="button"
            type="button"
            color="primary"
            onClick={() => {
              showLauncherMessanger();
            }}
          >
            Contact support
          </Link>
        </Stack>
      </Stack>
    </LoginLayoutContent>
  );
}

export interface ForgotPasswordPageProps {
  isResetLinkExpiredPage?: boolean;

  onOpenLogin: () => void;

  initialEmail?: string;
  onEmailChange?: (email: string) => void;
}

export function ForgotPasswordPage({
  onOpenLogin,
  initialEmail,
  onEmailChange,
  isResetLinkExpiredPage,
}: ForgotPasswordPageProps) {
  const { addSnackbar } = useSnackbarStack();
  const { resetPassword } = useAuthAPI();
  const { showLauncherMessanger } = useCustomerSupport();

  const formik = useFormikEnhanced({
    enableReinitialize: false,
    initialValues: { email: initialEmail || '' },
    validationSchema: resetPasswordSchema,
    onSubmit(values) {
      return resetPassword(values.email);
    },
    onSubmitFailure: (e) => {
      if (!isInactiveAccountError(e) && isAPIError(e) && isEmpty(e.context)) {
        addSnackbar(e.message || 'Error occurred, contact support.', {
          variant: 'error',
        });
      }
    },
  });

  useValueObserver(formik.values.email, () => {
    onEmailChange?.(formik.values.email);
  });

  if (formik.status.type === 'submitted') {
    return (
      <RequestPasswordResetLinkSuccessContent
        message={formik.status.payload.user_message}
        onBack={() => {
          formik.resetForm({ values: formik.values });
        }}
      />
    );
  }

  return (
    <LoginLayoutContent>
      <LoginErrorDialog
        title="Account Deactivated"
        message={
          formik.status.type !== 'rejected' ||
          !isInactiveAccountError(formik.status.payload)
            ? undefined
            : formik.status.payload.message
        }
        onClose={() => {
          formik.resetForm({ values: formik.values });
        }}
      />

      <FormikProvider value={formik}>
        <Form aria-label="forgot password">
          <Stack space="large">
            <Stack space="small">
              {isResetLinkExpiredPage ? (
                <Typography variant="h2">
                  Your password has already been reset or the link has not been
                  found
                </Typography>
              ) : (
                <Inline space="xsmall" verticalAlign="center">
                  <IconButton
                    edge="start"
                    onClick={onOpenLogin}
                    aria-label="go back"
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h2">Forgot Password?</Typography>
                </Inline>
              )}

              <Typography>
                {isResetLinkExpiredPage
                  ? 'To reset your password, enter account email below. '
                  : 'Enter account email below.'}
                We will send you a link to reset your password.
              </Typography>
            </Stack>

            <FormikTextField label="Email" name="email" fullWidth={true} />

            <Button
              type="submit"
              size="large"
              fullWidth={true}
              pending={formik.isSubmitting}
            >
              Send Me Reset Link
            </Button>

            <Columns>
              <Column>
                {isResetLinkExpiredPage && (
                  <Link
                    type="button"
                    component="button"
                    color="primary"
                    onClick={onOpenLogin}
                  >
                    Go to login
                  </Link>
                )}
              </Column>

              <Column width="content">
                <Link
                  type="button"
                  component="button"
                  align="center"
                  color="primary"
                  onClick={() => {
                    showLauncherMessanger();
                    trackLoginEvent({ name: 'CTMS: Clicked Contact Support' });
                  }}
                >
                  Contact support
                </Link>
              </Column>
            </Columns>
          </Stack>
        </Form>
      </FormikProvider>
    </LoginLayoutContent>
  );
}
