import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {
  SuperPayVerificationStatus,
  useCarrierSettings,
} from 'shared/settings/CarrierSettingsAPI';
import { useSuperPaySettings } from '../data/SuperPayAPI';

const isWebView =
  import.meta.env.VITE_APP_NAME === 'ctmsw' ||
  import.meta.env.VITE_APP_NAME === 'slbm';

export const SUPERPAY_PROFILE_ROUTES = {
  promo: '/profile/superpay',
  businessDetails: '/profile/superpay/onboarding/create-account',
  bankDetails: '/profile/superpay/onboarding/bank-account',
  accountDetails: '/profile/superpay/onboarding/account-details',
  bankVerification:
    '/profile/superpay/onboarding/account-details/verify-bank-account',
};

export const SUPERPAY_WEBVIEW_ROUTES = {
  promo: '/superpay',
  businessDetails: '/superpay/onboarding/create-account',
  bankDetails: '/superpay/onboarding/bank-account',
  microDepositInitiated: '/superpay/onboarding/micro-deposit-initiated',
  bankVerification: '/superpay/onboarding/account-details/verify-bank-account',
  accountDetails: '/superpay/onboarding/account-details',
  requestShipper: '/superpay/request-shipper',
};

interface SuperPayRouteProps {
  children: ReactElement;
  path: string;
  getPathByStatus: (
    verificationStatus?: SuperPayVerificationStatus | null,
  ) => string[];
}

export function SuperPayRoute({
  children,
  path,
  getPathByStatus,
}: SuperPayRouteProps) {
  const { search } = useLocation();
  const superPaySetting = useSuperPaySettings();
  const driverSetting = useCarrierSettings();

  if (superPaySetting.isLoading || driverSetting.isLoading) return null;

  if (isWebView && !driverSetting.data?.drivers_can_edit_carrier_info) {
    return <Navigate replace={true} to="/superpay/error" />;
  }

  const pathsFromStatus = getPathByStatus(
    superPaySetting.data?.verification_step,
  );
  if (!pathsFromStatus.includes(path)) {
    const [pathname] = pathsFromStatus;
    if (!pathname) return null;
    return <Navigate replace={true} to={{ pathname, search }} />;
  }

  return children;
}
