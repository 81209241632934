import {
  Card,
  CardContent,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FormikCheckboxField, FormikNumberField } from '@superdispatch/forms';
import { Inline, Stack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { FeatureFlag } from 'shared/settings/FeatureToggles';
import {
  setThemeModeSetting,
  useThemeModeSetting,
} from 'shared/theme/AppThemeProvider';
import { CopyableLinkedInput } from 'shared/ui/CopyableLinkedInput';
import { SettingsAppearance } from './core/SettingsAppearance';
import { SettingsPageForm } from './core/SettingsPageLayout';
import { SettingTimezoneFormikField } from './core/SettingsTimezoneFormikField';
import { useGeneralSettings, useSettingsAPI } from './data/SettingsAPI';
import { generalSettingsEditSchema } from './data/SettingsDTO';

export function SettingsGeneralPage() {
  const { data } = useGeneralSettings();
  const themeSetting = useThemeModeSetting();
  const { patchGeneralSettings } = useSettingsAPI();
  const formik = useAppFormik({
    initialValues: { ...data, theme_mode: themeSetting },
    validationSchema: generalSettingsEditSchema,
    onSubmit({ theme_mode, ...values }) {
      setThemeModeSetting(theme_mode);
      return patchGeneralSettings(values, data);
    },
    getSuccessMessage() {
      return 'General settings successfully updated';
    },
  });

  return (
    <>
      <DocumentTitle title="General - Settings" />

      <SettingsPageForm title="General" formik={formik} loading={!data}>
        <Card>
          <CardContent>
            <Stack space="large">
              <FeatureFlag feature="ctms_dark_mode">
                <Stack space="small">
                  <Typography variant="h3">My Settings</Typography>
                  <SettingsAppearance />
                </Stack>
              </FeatureFlag>

              <SettingTimezoneFormikField
                name="timezone"
                TextFieldProps={{
                  label: (
                    <Inline space="xxsmall">
                      Timezone
                      <Typography color="textSecondary">(Required)</Typography>
                    </Inline>
                  ),
                  helperText:
                    'Date and time in your dashboard is represented in this timezone.',
                }}
              />
              <Stack space="small">
                <Typography variant="h3">Loads Setup</Typography>

                <Stack space="xxsmall">
                  {data?.inbox && (
                    <CopyableLinkedInput
                      label={
                        <Typography>
                          Import loads by sending dispatch sheets to the email
                          <Tooltip title="Learn more how it works">
                            <IconButton
                              color="inherit"
                              target="_blank"
                              size="small"
                              rel="noopener noreferrer"
                              href="https://support.superdispatch.com/hc/en-us/articles/35697042924563-How-To-Create-Orders-By-Email-In-Carrier-TMS"
                            >
                              <Info
                                aria-label="import loads info"
                                fontSize="small"
                                color="action"
                              />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      }
                      fullWidth={true}
                      href={`mailto:${data.inbox}`}
                      value={data.inbox}
                      copyMessage="Email copied!"
                    />
                  )}
                </Stack>

                <FormikNumberField
                  name="initial_load_id"
                  label="Initial Load ID"
                  helperText="Used as an initial value on calculating Internal Load ID and Invoice ID."
                  inputProps={{ thousandSeparator: '' }}
                  fullWidth={true}
                />

                <Stack>
                  <Typography variant="body1" color="textSecondary">
                    BOL Template
                  </Typography>

                  <FormikCheckboxField
                    name="multiple_units_bol"
                    label={
                      <>
                        Use multiple unit BOL for loads with more than one
                        vehicle.
                        <br />
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://support.superdispatch.com/hc/en-us/articles/35697139356819-Multiple-Unit-BOL-Template-For-Carrier-TMS"
                        >
                          Learn more...
                        </Link>
                      </>
                    }
                  />
                </Stack>
              </Stack>
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.superdispatch.com/terms-of-service"
              >
                Super Dispatch Terms &amp; Conditions
              </Link>
            </Stack>
          </CardContent>
        </Card>
      </SettingsPageForm>
    </>
  );
}
