import { CircularProgress, Fade } from '@material-ui/core';
import { Alert } from '@superdispatch/ui-lab';
import { lazy, Suspense } from 'react';
import { CelebrationIcon } from 'shared/icons/CelebrationIcon';
import { useSubscriptionCelebrationState } from 'shared/modules/subscription/core/useSubscriptionCelebrationState';
import styled from 'styled-components';

const Confetti = lazy(() => import('react-confetti'));

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  @media (max-width: 768px) {
    height: auto;
  }
`;

export function SubscriptionCelebration() {
  const { bannerStatus, confettiStatus, setBannerStatus, setConfettiStatus } =
    useSubscriptionCelebrationState();

  if (bannerStatus !== 'unseen') {
    return null;
  }

  return (
    <>
      <Fade
        in={confettiStatus !== 'seen'}
        unmountOnExit={true}
        onEntered={() => {
          setTimeout(() => {
            setConfettiStatus('seen');
          }, 5000);
        }}
      >
        <Suspense fallback={<CircularProgress />}>
          <Confetti />
        </Suspense>
      </Fade>

      <Alert
        icon={<CelebrationIcon />}
        onClose={() => {
          setBannerStatus('seen');
        }}
      >
        <Content>
          Congratulations! You have successfully upgraded to Carrier TMS Pro.
        </Content>
      </Alert>
    </>
  );
}
