import { useResponsiveValue } from '@superdispatch/ui';
import { SidebarContainer } from '@superdispatch/ui-lab';
import { Navigate, RouteObject } from 'react-router-dom';
import { useFlag } from 'shared/settings/FeatureToggles';
import { SettingsBillingPage } from './SettingsBillingPage';
import {
  SettingsContainerOutlet,
  SettingsSidebar,
} from './SettingsContainerOutlet';
import { SettingsDriverAppPage } from './SettingsDriverAppPage';
import { SettingsFactoringPage } from './SettingsFactoringPage';
import { SettingsGeneralPage } from './SettingsGeneralPage';
import { SettingsNotificationsPage } from './SettingsNotificationsPage';
import { SettingsQuickbooksIntegrationPage } from './SettingsQuickbooksIntegrationPage';
import { SettingsSubscriptionLegacyPage } from './SettingsSubscriptionDetailsLegacyPage';
import { SettingsSubscriptionPage } from './SettingsSubscriptionDetailsPage';
import { SettingsTerminalPage } from './SettingsTerminalsPage';

export const settingsRoutes: RouteObject[] = [
  {
    path: 'settings/:page?',
    element: <SettingsContainerOutlet />,
    children: [
      {
        path: 'general',
        element: <SettingsGeneralPage />,
      },
      {
        path: 'terminals/:action?',
        element: <SettingsTerminalPage />,
      },
      {
        path: 'driver-app',
        element: <SettingsDriverAppPage />,
      },
      {
        path: 'billing',
        element: <SettingsBillingPage />,
      },
      {
        path: 'factoring',
        element: <SettingsFactoringPage />,
      },
      {
        path: 'quickbooks',
        element: <SettingsQuickbooksIntegrationPage />,
      },
      {
        path: 'subscription/:action?',
        element: <SettingsSubscriptionRoute />,
      },
      {
        path: 'notifications',
        element: <SettingsNotificationsPage />,
      },
      {
        path: 'notification-emails',
        element: <Navigate to="/settings/notifications/" />,
      },
      {
        index: true,
        element: <SettingsPage />,
      },
    ],
  },
];

function SettingsSubscriptionRoute() {
  const isSeatBasedPriceEnabled = useFlag('seats_based_pricing');

  if (isSeatBasedPriceEnabled) {
    return <SettingsSubscriptionPage />;
  }

  return <SettingsSubscriptionLegacyPage />;
}

function SettingsPage() {
  const isMobile = useResponsiveValue(true, true, false);

  if (isMobile) {
    return <SidebarContainer sidebar={<SettingsSidebar />} />;
  }

  return <Navigate to="/settings/general" />;
}
