import { Color } from '@superdispatch/ui';
import { createSvgIcon } from 'shared/icons/IconUtils';

export const FilterIcon = createSvgIcon(
  'FilterIcon',
  <path
    d="M5 2V11.9H3V14.1H5V21.8H7V14.1H9V11.9H7V2H5ZM11 2V7.5H9V9.7H11V21.8H13V9.7H15V7.5H13V2H11ZM17 2V14.1H15V16.3H17V21.8H19V16.3H21V14.1H19V2"
    fill="currentColor"
  />,
  {
    viewBox: '0 0 24 24',
    style: { height: '24px', width: '24px' },
    fill: Color.Dark100,
  },
);
