import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';
import { useUID } from '@superdispatch/ui';
import { useIsMutating } from '@tanstack/react-query';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useLocationChangeEffect } from 'shared/routing/LocationChangeEffect';
import { Prompt } from 'shared/routing/NavigationBlock';

interface MutationDialogContext {
  titleID: string;
  isLoading: boolean;
}

const context = createContext<MutationDialogContext>({
  titleID: '',
  isLoading: false,
});

export function MutationDialog({
  onClose,
  children,
  PaperProps,
  ...props
}: DialogProps) {
  const titleID = useUID();
  const mutationCount = useIsMutating();
  const isLoading = mutationCount > 0;
  const ctx = useMemo<MutationDialogContext>(
    () => ({ titleID, isLoading }),
    [titleID, isLoading],
  );

  return (
    <Dialog
      {...props}
      PaperProps={{ ...PaperProps, 'aria-labelledby': titleID }}
      onClose={(event, reason) => {
        if (!isLoading) {
          onClose?.(event, reason);
        }
      }}
    >
      <context.Provider value={ctx}>{children}</context.Provider>
    </Dialog>
  );
}

interface BaseMutationDialogContentProps {
  title?: ReactNode;
  actions?: ReactNode;
  children?: ReactNode;
  disableDialogContent?: boolean;
}

export type MutationDialogContentProps = BaseMutationDialogContentProps &
  (
    | { onClose?: () => void; disableAutoClose: true }
    | { onClose: () => void; disableAutoClose?: undefined }
  );

export function MutationDialogContent({
  title,
  actions,
  onClose,
  children,
  disableAutoClose,
  disableDialogContent,
}: MutationDialogContentProps) {
  const { titleID, isLoading } = useContext(context);

  useLocationChangeEffect(() => {
    if (!disableAutoClose) onClose();
  });

  return (
    <>
      <Prompt
        when={isLoading}
        message="Changes have not been saved. Leaving can result in unsaved changes being lost."
      />
      {!!title && <DialogTitle id={titleID}>{title}</DialogTitle>}

      {!children || disableDialogContent ? (
        children
      ) : (
        <DialogContent>{children}</DialogContent>
      )}

      {actions}
    </>
  );
}
