import { AppBar, Dialog, IconButton, Toolbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Color } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { usePrintHTMLContent } from 'shared/hooks/usePrintHTMLContent';
import styled from 'styled-components';

interface LoadInvoicePreviewDialogProps {
  html: string | null;
  open: boolean;
  onClose: () => void;
}

export function LoadInvoicePreviewDialog({
  html,
  open,
  onClose,
}: LoadInvoicePreviewDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={true}>
      <LoadInvoicePreviewDialogContent html={html} onClose={onClose} />
    </Dialog>
  );
}

interface LoadInvoicePreviewDialogContentProps {
  html: string | null;
  onClose: () => void;
}

export const PreviewBox = styled.div`
  .page {
    background: ${Color.White};
    color: ${Color.Dark500};
  }
`;

function LoadInvoicePreviewDialogContent({
  html,
  onClose,
}: LoadInvoicePreviewDialogContentProps) {
  usePrintHTMLContent(html || '');

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <TextBox variant="heading-3">Invoice Preview</TextBox>
        </Toolbar>
      </AppBar>

      <Box
        width="100%"
        height="100%"
        marginLeft="auto"
        marginRight="auto"
        marginTop="xxlarge"
        marginBottom="small"
        paddingTop="xxlarge"
        overflowX="hidden"
      >
        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        {html && <PreviewBox dangerouslySetInnerHTML={{ __html: html }} />}
      </Box>
    </>
  );
}
