import { createSvgIcon } from 'shared/icons/IconUtils';

export const CrownIcon = createSvgIcon(
  'CrownIcon',
  <svg>
    <path
      d="M3.33334 13.3333V12H12.6667V13.3333H3.33334ZM3.33334 11L2.48334 5.65C2.46112 5.65 2.43612 5.65277 2.40834 5.65833C2.38057 5.66388 2.35557 5.66666 2.33334 5.66666C2.05557 5.66666 1.81945 5.56944 1.62501 5.375C1.43057 5.18055 1.33334 4.94444 1.33334 4.66666C1.33334 4.38888 1.43057 4.15277 1.62501 3.95833C1.81945 3.76388 2.05557 3.66666 2.33334 3.66666C2.61112 3.66666 2.84723 3.76388 3.04168 3.95833C3.23612 4.15277 3.33334 4.38888 3.33334 4.66666C3.33334 4.74444 3.32501 4.81666 3.30834 4.88333C3.29168 4.95 3.27223 5.01111 3.25001 5.06666L5.33334 6L7.41668 3.14999C7.29446 3.06111 7.19446 2.94444 7.11668 2.79999C7.0389 2.65555 7.00001 2.49999 7.00001 2.33333C7.00001 2.05555 7.09723 1.81944 7.29168 1.62499C7.48612 1.43055 7.72223 1.33333 8.00001 1.33333C8.27779 1.33333 8.5139 1.43055 8.70834 1.62499C8.90279 1.81944 9.00001 2.05555 9.00001 2.33333C9.00001 2.49999 8.96112 2.65555 8.88334 2.79999C8.80557 2.94444 8.70557 3.06111 8.58334 3.14999L10.6667 6L12.75 5.06666C12.7278 5.01111 12.7083 4.95 12.6917 4.88333C12.675 4.81666 12.6667 4.74444 12.6667 4.66666C12.6667 4.38888 12.7639 4.15277 12.9583 3.95833C13.1528 3.76388 13.3889 3.66666 13.6667 3.66666C13.9445 3.66666 14.1806 3.76388 14.375 3.95833C14.5695 4.15277 14.6667 4.38888 14.6667 4.66666C14.6667 4.94444 14.5695 5.18055 14.375 5.375C14.1806 5.56944 13.9445 5.66666 13.6667 5.66666C13.6445 5.66666 13.6195 5.66388 13.5917 5.65833C13.5639 5.65277 13.5389 5.65 13.5167 5.65L12.6667 11H3.33334Z"
      fill="currentColor"
    />
  </svg>,
  { viewBox: '0 0 16 16', style: { width: '16px', height: '16px' } },
);
