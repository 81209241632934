import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { APIMutationOptions, useAPIMutation } from '../../api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from '../../api/APIQuery';
import { requestCarrierAPI } from '../../api/CarrierAPIClient';
import {
  BillingAddressDTO,
  SubscriptionSettingsDTO,
  subscriptionSettingsSchema,
  TaxPriceDTO,
  UpdateSubscriptionDTO,
} from './SubscriptionDTO';

export function useSubscriptionAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      updateSubscriptionSettings: (json: UpdateSubscriptionDTO) =>
        requestCarrierAPI('POST /internal/web/settings/subscription/', {
          json,
        }),

      saveBillingAddress: (json: BillingAddressDTO) =>
        requestCarrierAPI('POST /internal/web/carrier/billing-address/', {
          json,
        }).then((res) => {
          void queryClient.invalidateQueries(['settings', 'billingAddress']);
          return res;
        }),

      reactivateSubscription: () =>
        requestCarrierAPI(
          'POST /internal/web/settings/subscription/reactivate/',
        ).then((res) => {
          void queryClient.invalidateQueries(['settings', 'subscription']);
          return res;
        }),
      upgradeSeats: (seats_count: number) =>
        requestCarrierAPI(
          'POST /internal/web/settings/subscription/upgrade-seats/',
          {
            json: { seats_count },
          },
        ).then((res) => {
          void queryClient.invalidateQueries(['settings', 'subscription']);
          return res;
        }),
    }),
    [queryClient],
  );
}

export function useUpdateSubscriptionSettingsMutation(
  options?: APIMutationOptions<UpdateSubscriptionDTO>,
) {
  const { updateSubscriptionSettings } = useSubscriptionAPI();

  return useAPIMutation(
    (json: UpdateSubscriptionDTO) => updateSubscriptionSettings(json),
    options,
  );
}

export function useReactivateSubscriptionMutation(
  options?: APIMutationOptions,
) {
  const { reactivateSubscription } = useSubscriptionAPI();

  return useAPIMutation(reactivateSubscription, options);
}

export function useSubscriptionSettings(
  options?: APIQueryOptions<SubscriptionSettingsDTO>,
): APIQueryResult<SubscriptionSettingsDTO> {
  return useAPIQuery(
    ['settings', 'subscription'],
    () => requestCarrierAPI('GET /internal/web/settings/subscription/'),
    { schema: subscriptionSettingsSchema, ...options },
  );
}

export function useEstimateTaxPrice(
  options?: APIQueryOptions<TaxPriceDTO>,
  seats_count?: number,
): APIQueryResult<TaxPriceDTO> {
  return useAPIQuery(
    ['settings', 'estimateTaxPrice', { seats_count }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/settings/subscription/estimate-invoice/?seats_count={seats_count}',
        { seats_count },
      ]),
    options,
  );
}

export function useEstimateSeatsUpgrade(
  seats_count?: number,
  options?: APIQueryOptions<TaxPriceDTO>,
): APIQueryResult<TaxPriceDTO | null> {
  return useAPIQuery(
    ['settings', 'estimateSeatsUpgrade', { seats_count }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/settings/subscription/estimate-seats-upgrade/?seats_count={seats_count}',
        { seats_count },
      ]),
    options,
  );
}

export function useBillingAddress(
  options?: APIQueryOptions<BillingAddressDTO>,
): APIQueryResult<BillingAddressDTO> {
  return useAPIQuery(
    ['settings', 'billingAddress'],
    () => requestCarrierAPI('GET /internal/web/carrier/billing-address/'),
    options,
  );
}

export function useSubscriptionSettingsMutation() {
  return useAPIMutation<undefined, SubscriptionSettingsDTO>(() =>
    requestCarrierAPI('GET /internal/web/settings/subscription/'),
  );
}

export function useSaveBillingAddressMutation(
  options?: APIMutationOptions<BillingAddressDTO>,
) {
  const { saveBillingAddress } = useSubscriptionAPI();
  return useAPIMutation((json) => saveBillingAddress(json), options);
}
