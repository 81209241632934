import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Delete, DirectionsCar, Info } from '@material-ui/icons';
import {
  FormikCheckboxField,
  FormikCurrencyField,
  FormikNumberField,
  FormikTextField,
} from '@superdispatch/forms';
import { formatVehicleType, VEHICLE_TYPES } from '@superdispatch/sdk';
import { Inline, useUID } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { fromPairs, intersectionBy, merge } from 'lodash-es';
import { Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { InputTableCell } from 'shared/ui/Table';
import { joinStrings } from 'shared/utils/StringUtils';
import { LoadBulkVinDecodeDrawer } from '../add/LoadBulkVinDecodeDrawer';
import {
  isEmptyLoadVehicleDTO,
  LoadVehicleEditDTO,
  loadVehicleEditSchema,
} from '../data/LoadActionsDTO';
import { LoadVehicleDTO } from '../data/LoadDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import { DuplicatedLoad } from '../data/VINsAPI';
import { DuplicatedVINsDialog } from './DuplicatedVINsDialog';
import { FormikVehicleMakeField } from './FormikVehicleMakeField';
import { FormikVehicleModelField } from './FormikVehicleModelField';
import { FormikVINDecoder } from './FormikVINDecoder';
import { LoadVehicleDisabledTooltip } from './LoadVehicleDisabledTooltip';

interface VehicleDeleteDialogProps {
  vehicle?: LoadVehicleDTO;
  onDismiss: () => void;
  onConfirm: () => void;
}

function VehicleDeleteDialog({
  vehicle,
  onDismiss,
  onConfirm,
}: VehicleDeleteDialogProps) {
  const vehicleName = useMemo(
    () =>
      vehicle && joinStrings(' ', vehicle.year, vehicle.make, vehicle.model),
    [vehicle],
  );

  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      onClose={onDismiss}
      open={vehicle != null}
    >
      <DialogTitle>Delete {vehicleName || 'vehicle'}?</DialogTitle>
      <DialogContent>
        All vehicle information including inspection details will be deleted.
      </DialogContent>
      <DialogActions>
        <Button variant="neutral" onClick={onDismiss}>
          Cancel
        </Button>

        <Button type="submit" variant="critical" onClick={onConfirm}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface LoadVehiclesCardProps {
  hasInspections?: boolean;
  nodeRef?: Ref<HTMLElement>;
  isEnabledEditLoad?: boolean;
}

type ModalState =
  | { type: 'bulk_vin_decode'; payload?: never }
  | { type: 'delete_vehicle'; payload: LoadVehicleDTO };

export function LoadVehiclesCard({
  nodeRef,
  hasInspections,
  isEnabledEditLoad = true,
}: LoadVehiclesCardProps) {
  const uid = useUID();
  const vehicleVINFieldRef = useRef<HTMLElement>(null);
  const { values, setValues } = useFormikContext<{
    vehicles?: LoadVehicleEditDTO[];
  }>();
  const shouldCheckDuplicatedVins = useRef(false);
  const [modalState, setModalState] = useState<ModalState>();
  const closeModal = () => {
    setModalState(undefined);
  };
  const vehicles = useMemo(() => values.vehicles || [], [values.vehicles]);
  const { duplicateOrders, setDuplicateOrders } = useDuplicatedVins({
    vehicles,
    shouldCheckDuplicatedVins: shouldCheckDuplicatedVins.current,
  });
  const setVehicles = useCallback(
    (updater: (prev: LoadVehicleEditDTO[]) => LoadVehicleEditDTO[]): void => {
      void setValues((previousValues) => ({
        ...previousValues,
        vehicles: updater(previousValues.vehicles || []),
      }));
    },
    [setValues],
  );

  const shouldFocusFirstVinRef = useRef(false);
  useEffect(() => {
    if (vehicles.length > 1 && shouldFocusFirstVinRef.current) {
      vehicleVINFieldRef.current?.focus();
      shouldFocusFirstVinRef.current = false;
    }
  }, [vehicles.length]);

  return (
    <>
      <DuplicatedVINsDialog
        duplicateVINs={duplicateOrders}
        onClose={() => {
          setDuplicateOrders(undefined);
        }}
      />

      <VehicleDeleteDialog
        onDismiss={closeModal}
        vehicle={
          modalState?.type !== 'delete_vehicle' ? undefined : modalState.payload
        }
        onConfirm={() => {
          closeModal();

          if (modalState?.type === 'delete_vehicle') {
            const { guid } = modalState.payload;

            if (guid) {
              setVehicles((prevVehicles) =>
                prevVehicles.filter((vehicle) => vehicle.guid !== guid),
              );
            }
          }
        }}
      />

      <LoadBulkVinDecodeDrawer
        onClose={closeModal}
        open={modalState?.type === 'bulk_vin_decode'}
        onSubmitSuccess={(decodedVehicles, duplicateLoads) => {
          const duplicatedVins = intersectionBy(
            vehicles,
            decodedVehicles,
            'vin',
          );

          const duplicatedVinsMap: Record<string, DuplicatedLoad[]> = fromPairs(
            duplicatedVins.map((vehicle) => [vehicle.vin, []]),
          );

          setDuplicateOrders(merge(duplicatedVinsMap, duplicateLoads));

          setVehicles((prevVehicles) => {
            const nextVehicles = prevVehicles.filter(
              (vehicle) => !isEmptyLoadVehicleDTO(vehicle),
            );

            nextVehicles.unshift(
              ...decodedVehicles.map((vehicle) =>
                loadVehicleEditSchema.cast(vehicle),
              ),
            );

            return nextVehicles;
          });

          shouldCheckDuplicatedVins.current = true;
        }}
      />

      <Card ref={nodeRef} aria-label="Vehicles Card">
        <CardContent>
          <Inline verticalAlign="center" space="xsmall" noWrap={true}>
            <DirectionsCar />
            <Typography aria-label="Vehicles Header" variant="h3" id={uid}>
              {vehicles.length === 0
                ? 'No Vehicles'
                : `${vehicles.length} ${formatPlural(
                    vehicles.length,
                    'Vehicle',
                    'Vehicles',
                  )}`}
            </Typography>

            <LoadVehicleDisabledTooltip isEnabledEditLoad={isEnabledEditLoad}>
              <Button
                variant="text"
                startIcon={<Add />}
                disabled={!isEnabledEditLoad}
                onClick={() => {
                  trackLoadsEvent({ name: 'CTMS: Clicked Add Vehicle' });
                  shouldFocusFirstVinRef.current = true;
                  setVehicles((prevVehicles) => [
                    loadVehicleEditSchema.cast({}),
                    ...prevVehicles,
                  ]);
                }}
              >
                Add
              </Button>
            </LoadVehicleDisabledTooltip>
            <LoadVehicleDisabledTooltip isEnabledEditLoad={isEnabledEditLoad}>
              <Button
                variant="text"
                disabled={!isEnabledEditLoad}
                onClick={() => {
                  setModalState({ type: 'bulk_vin_decode' });
                }}
              >
                Bulk VIN Decode
              </Button>
            </LoadVehicleDisabledTooltip>
          </Inline>
        </CardContent>

        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <InputTableCell>VIN</InputTableCell>
                <InputTableCell>Year</InputTableCell>
                <InputTableCell>Make</InputTableCell>
                <InputTableCell>Model</InputTableCell>
                <InputTableCell>Type</InputTableCell>
                <InputTableCell>Color</InputTableCell>
                <InputTableCell>Lot number</InputTableCell>
                <InputTableCell>Price</InputTableCell>
                <InputTableCell width="50px">INOP</InputTableCell>
                <InputTableCell width="90px">
                  <Inline verticalAlign="center" space="xsmall" noWrap={true}>
                    Enclosed
                    <Tooltip
                      placement="top"
                      enterTouchDelay={0}
                      title="The vehicle requires enclosed trailer"
                    >
                      <Info fontSize="small" color="action" />
                    </Tooltip>
                  </Inline>
                </InputTableCell>
                <InputTableCell width="50px" />
              </TableRow>
            </TableHead>
            <TableBody>
              {vehicles.map((vehicle, index) => (
                <TableRow aria-label={`vehicle ${index}`} key={index}>
                  <InputTableCell width="216px">
                    <FormikVINDecoder
                      fullWidth={true}
                      disabled={!isEnabledEditLoad}
                      name={`vehicles[${index}].vin`}
                      inputRef={index === 0 ? vehicleVINFieldRef : undefined}
                      inputProps={{
                        'aria-label': `vehicle vin (${index + 1})`,
                        maxLength: 17,
                      }}
                      onDecodeSuccess={(decodedVehicle, duplicatedLoads) => {
                        if (duplicatedLoads.length) {
                          setDuplicateOrders({
                            [decodedVehicle.vin]: duplicatedLoads,
                          });
                        }

                        if (index === 0) {
                          shouldFocusFirstVinRef.current = true;
                        }

                        setVehicles((prevVehicles) => {
                          const nextVehicles = prevVehicles.map(
                            (currentVehicle) => {
                              if (currentVehicle.vin !== decodedVehicle.vin) {
                                return currentVehicle;
                              }

                              return loadVehicleEditSchema.cast({
                                ...currentVehicle,
                                ...decodedVehicle,
                              });
                            },
                          );

                          if (index === 0) {
                            nextVehicles.unshift(
                              loadVehicleEditSchema.cast({}),
                            );
                          }

                          return nextVehicles;
                        });

                        shouldCheckDuplicatedVins.current = true;
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell width="72px">
                    <FormikNumberField
                      fullWidth={true}
                      name={`vehicles[${index}].year`}
                      disabled={!isEnabledEditLoad}
                      inputProps={{
                        thousandSeparator: '',
                        'aria-label': `vehicle year (${index + 1})`,
                        maxLength: 4,
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell width="96px">
                    <FormikVehicleMakeField
                      fullWidth={true}
                      disabled={!isEnabledEditLoad}
                      name={`vehicles[${index}].make`}
                      inputProps={{
                        'aria-label': `vehicle make (${index + 1})`,
                        maxLength: 200,
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell width="96px">
                    <FormikVehicleModelField
                      fullWidth={true}
                      disabled={!isEnabledEditLoad}
                      vehicleMake={vehicles[index]?.make || ''}
                      name={`vehicles[${index}].model`}
                      inputProps={{
                        'aria-label': `vehicle model (${index + 1})`,
                        maxLength: 200,
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell width="128px">
                    <FormikTextField
                      select={true}
                      fullWidth={true}
                      disabled={!isEnabledEditLoad}
                      name={`vehicles[${index}].type`}
                      inputProps={{
                        'aria-label': `vehicle type (${index + 1})`,
                      }}
                    >
                      {VEHICLE_TYPES.map((key) => (
                        <MenuItem key={key} value={key}>
                          {formatVehicleType(key)}
                        </MenuItem>
                      ))}
                    </FormikTextField>
                  </InputTableCell>
                  <InputTableCell width="96px">
                    <FormikTextField
                      disabled={!isEnabledEditLoad}
                      name={`vehicles[${index}].color`}
                      inputProps={{
                        'aria-label': `vehicle color (${index + 1})`,
                        maxLength: 200,
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell width="96px">
                    <FormikTextField
                      disabled={!isEnabledEditLoad}
                      name={`vehicles[${index}].lot_number`}
                      inputProps={{
                        'aria-label': `vehicle lot number (${index + 1})`,
                        maxLength: 200,
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell width="84px">
                    <FormikCurrencyField
                      disabled={!isEnabledEditLoad}
                      name={`vehicles[${index}].price`}
                      inputProps={{
                        'aria-label': `vehicle price (${index + 1})`,
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell>
                    <FormikCheckboxField
                      disabled={!isEnabledEditLoad}
                      label=""
                      name={`vehicles[${index}].is_inoperable`}
                      inputProps={{
                        'aria-label': `vehicle is inoperable (${index + 1})`,
                      }}
                    />
                  </InputTableCell>
                  <InputTableCell>
                    <FormikCheckboxField
                      disabled={!isEnabledEditLoad}
                      label=""
                      name={`vehicles[${index}].requires_enclosed_trailer`}
                      inputProps={{
                        'aria-label': `vehicle requires enclosed trailer (${
                          index + 1
                        })`,
                      }}
                    />
                  </InputTableCell>
                  {isEnabledEditLoad && (
                    <InputTableCell padding="none">
                      <IconButton
                        edge="end"
                        aria-label={`remove vehicle (${index + 1})`}
                        onClick={() => {
                          shouldCheckDuplicatedVins.current = false;
                          if (vehicle.guid && hasInspections) {
                            trackLoadsEvent({
                              page: 'edit_load',
                              name: 'CTMS: Clicked Delete Vehicle',
                            });

                            setModalState({
                              payload: vehicle,
                              type: 'delete_vehicle',
                            });
                          } else {
                            setVehicles((prevVehicles) =>
                              prevVehicles.filter(
                                (_vehicle, idx) => idx !== index,
                              ),
                            );
                          }
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </InputTableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

interface DuplicatedVins {
  vehicles: LoadVehicleEditDTO[];
  shouldCheckDuplicatedVins: boolean;
}

function useDuplicatedVins({
  vehicles,
  shouldCheckDuplicatedVins,
}: DuplicatedVins) {
  const [duplicateOrders, setDuplicateOrders] =
    useState<Record<string, DuplicatedLoad[]>>();

  const hasDuplicatedVinLoadForm = useMemo(() => {
    if (shouldCheckDuplicatedVins) {
      return vehicles
        .filter((vehicle) => vehicle.vin)
        .some(
          (vehicle, index, array) =>
            array.findIndex((v) => v.vin === vehicle.vin) !== index,
        );
    }

    return false;
  }, [vehicles, shouldCheckDuplicatedVins]);

  const setDuplicateOrdersCallback = useCallback(() => {
    if (hasDuplicatedVinLoadForm) {
      const duplicatedVinsMap = vehicles
        .filter((vehicle) => vehicle.vin)
        .reduce<Record<string, DuplicatedLoad[]>>((acc, vehicle) => {
          const duplicates = vehicles.filter((v) => v.vin === vehicle.vin);
          if (duplicates.length > 1) acc[vehicle.vin] = [];
          return acc;
        }, {});

      setDuplicateOrders(duplicatedVinsMap);
    }
  }, [hasDuplicatedVinLoadForm, vehicles]);

  useEffect(() => {
    setDuplicateOrdersCallback();
  }, [setDuplicateOrdersCallback]);

  return { duplicateOrders, setDuplicateOrders };
}
