import { HTTPRequestJSONOptions } from '@superdispatch/http';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
} from '@tanstack/react-query';
import { APIQueryResult } from 'shared/api/APIQuery';
import { requestJSON } from 'shared/api/CarrierAPIClient';
import { MEDIA_SERVICE_API_HOST } from 'shared/constants/ServerConstants';
import {
  MediaServiceDTO,
  mediaServiceSchema,
} from 'shared/data/MediaServiceDTO';

export function uploadMedia(
  file: File,
  options?: HTTPRequestJSONOptions<unknown>,
): Promise<string> {
  const body = new FormData();
  body.append('file', file, file.name);
  return requestJSON('POST /v1/upload/', {
    ...options,
    body,
    baseURL: MEDIA_SERVICE_API_HOST,
  }).then((data) => {
    if (typeof data == 'object' && data) {
      const { url } = data as { url?: unknown };
      if (typeof url == 'string' && url) return url;
    }
    return Promise.reject(new Error('Failed to upload media file.'));
  });
}

export function useUploadMedia(
  options?: UseMutationOptions<string, Error, File> & {
    isRestricted?: boolean;
  },
): UseMutationResult<string, Error, File> {
  return useMutation((file: File) => {
    return uploadMedia(file, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ...(options?.isRestricted && { 'X-Is-Restricted': 'true' }),
      },
    });
  }, options);
}

export function useSignMedia(
  url: string | null | undefined,
): APIQueryResult<MediaServiceDTO> {
  return useQuery(
    ['media', 'sign', { url }],
    () =>
      requestJSON('POST /v1/sign-url/', {
        baseURL: MEDIA_SERVICE_API_HOST,
        json: { url },
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'X-Auth-Scheme': 'carrier',
        },
      }).then((response) => {
        // media service have different response structure from carrier backend
        // so we use plain useQuery and casting response directly
        return mediaServiceSchema.cast(response);
      }),
    {
      enabled: !!url,
      refetchOnWindowFocus: false,
    },
  );
}
