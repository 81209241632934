import createGeocodingClient, {
  GeocodeQueryType,
  GeocodeRequest,
  GeocodeResponse,
} from '@mapbox/mapbox-sdk/services/geocoding';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { uniqBy } from 'lodash-es';
import { USA_STATES } from 'shared/form/USAState';
import { logWarning } from 'shared/helpers/ErrorTracker';
import { Geocoding } from './GeoHelpers';
import { parseGeocodeFeature } from './MapboxHelpers';

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjazV4czQ5b2kwNnB6M2RsbWYzcnhwenliIn0.OJ306ETXiAQL0lDUz4wUJw';

const geocodingService = createGeocodingClient({
  accessToken: MAPBOX_ACCESS_TOKEN,
});

function sortByCountryFn(list: Geocoding[], country: string) {
  return list.sort((a, b) => {
    const isASortByCountry =
      a.country_short?.toLowerCase() === country.toLowerCase();
    const isBSortByCountry =
      b.country_short?.toLowerCase() === country.toLowerCase();

    if (isASortByCountry && !isBSortByCountry) {
      return -1;
    }

    if (!isASortByCountry && isBSortByCountry) {
      return 1;
    }

    return 0;
  });
}

function searchByStates(list: Geocoding[], query: string): Geocoding[] {
  const states = USA_STATES.filter((state) =>
    state.abbreviation.toLowerCase().includes(query.toLowerCase()),
  ).map((state) => ({
    place_name: `${state.name}, United States`,
    region: state.name,
    region_short: state.abbreviation,
    country: 'United States',
    country_short: 'US',
  }));

  return uniqBy([...states, ...list], 'place_name');
}

export function useMapboxPredictions(
  query: string | undefined,
  types: GeocodeQueryType[],
  countries: string[] = ['US', 'CA'],
  sortByCountry: 'US' | 'CA' = 'US',
): UseQueryResult<Geocoding[], Error> {
  return useQuery(
    ['mapbox', 'predictions', { query, types, countries }],
    () => {
      const params: GeocodeRequest = {
        types,
        countries,
        autocomplete: true,
        query: query as string,
        proximity: 'ip',
      };

      return forwardGeocode(params)
        .then((data) => {
          let result = data.features.map(parseGeocodeFeature);

          // Sometimes Mapbox doesn't return the state in the results,
          // so we need to add them manually
          if (query && types.includes('region')) {
            result = searchByStates(result, query);
          }

          return sortByCountryFn(result, sortByCountry);
        })

        .catch((error: Error) => {
          logWarning(`Failed to run Mapbox geocode`, {
            extraInfo: { query, types, countries, namespace: 'MapBox', error },
          });
          return Promise.reject(error);
        });
    },
    {
      enabled: !!query && query.length <= 256,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
}

export function useMapboxReversePredictions(
  query: [number, number] | undefined,
  types: GeocodeQueryType[],
  options?: UseQueryOptions<GeocodeResponse, Error>,
): UseQueryResult<GeocodeResponse, Error> {
  return useQuery(
    ['mapbox', 'predictions', 'reverse', { query, types }],
    () =>
      reverseGeocode({
        query: query as [number, number],
        types,
      }),
    {
      enabled: !!query,
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
}

export function reverseGeocode(
  request: GeocodeRequest,
): Promise<GeocodeResponse> {
  return geocodingService
    .reverseGeocode({
      mode: 'mapbox.places',
      ...request,
    })
    .send()
    .catch(handleError)
    .then((res) => {
      return res.body;
    });
}

export function forwardGeocode(
  request: GeocodeRequest,
): Promise<GeocodeResponse> {
  return geocodingService
    .forwardGeocode({
      mode: 'mapbox.places',
      ...request,
    })
    .send()
    .catch(handleError)
    .then((res) => {
      return res.body;
    });
}

function handleError(payload: unknown): never {
  if (payload instanceof Error) {
    throw payload;
  }

  throw Object.assign(new Error(), {
    name: 'MapboxError',
    data: payload,
  });
}
