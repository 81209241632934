import { IconButton, Link } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { FormikContextTypeEnhanced } from '@superdispatch/forms';
import {
  Color,
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';

export interface StepProps {
  onClose?: () => void;
  onNext: () => void;
  formik: FormikContextTypeEnhanced<
    {
      reasons: Map<string, { reason: string; comment?: string }>;
      feedback: string;
    },
    unknown
  >;
}

function DowngradeConfirmationItem({
  value,
  has,
}: {
  value: string;
  has: boolean;
}) {
  return (
    <Inline verticalAlign="center">
      {has ? <Check htmlColor={Color.Green300} /> : <Close color="error" />}
      <TextBox>{value}</TextBox>
    </Inline>
  );
}

export function DowngradeConfirmationStep({ onNext, onClose }: StepProps) {
  const { showLauncherMessanger } = useCustomerSupport();
  return (
    <>
      <DrawerTitle
        title="Downgrade to Free Plan?"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />

      <Stack space="small">
        <DrawerContent>
          <Stack space="small">
            <TextBox variant="heading-4">
              Features Available to Free Users
            </TextBox>

            <DowngradeConfirmationItem
              value="Access free Loadboard and Offers"
              has={true}
            />
            <DowngradeConfirmationItem
              value="Manage Aging and Delayed Loads"
              has={false}
            />
            <DowngradeConfirmationItem
              value="Manage Loads and Billing"
              has={false}
            />
            <DowngradeConfirmationItem
              value="Build and Plan Trips"
              has={false}
            />
            <DowngradeConfirmationItem
              value="Track Drivers with GPS"
              has={false}
            />
            <DowngradeConfirmationItem value="Save Contacts" has={false} />
            <DowngradeConfirmationItem
              value="Add Drivers From Desktop"
              has={false}
            />
            <DowngradeConfirmationItem
              value="Create and Customize Reports"
              has={false}
            />

            <Link
              href="https://support.superdispatch.com/hc/articles/35612448514195-How-To-Manage-Your-Subscription-Plan-In-Carrier-TMS"
              color="primary"
            >
              Learn more about plans
            </Link>
          </Stack>
        </DrawerContent>

        <DrawerActions>
          <Stack space="large">
            <Inline>
              <Button
                onClick={() => {
                  onClose?.();
                }}
              >
                Keep My Subscription
              </Button>
              <Button onClick={onNext} variant="neutral">
                Continue Downgrading
              </Button>
            </Inline>

            <TextBox color="secondary">
              For any questions or feedback, please{' '}
              <Link
                component="button"
                type="button"
                align="center"
                onClick={() => {
                  showLauncherMessanger();
                }}
              >
                contact support
              </Link>
            </TextBox>
          </Stack>
        </DrawerActions>
      </Stack>
    </>
  );
}
