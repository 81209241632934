import { Typography } from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import { Column, Columns, Inline, Stack, useUID } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { formatNumber } from 'shared/helpers/IntlHelpers';
import { ShipperAvatar } from 'shared/modules/shipper/ShipperAvatar';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { ShipperProfileLink } from '../shipper/ShipperProfileLink';
import { UnratedShipperDTO } from './data/CarrierRatingsDTO';

const StyledThumbUp = styled(ThumbUp)`
  font-size: 16px;
`;

interface Props {
  unratedShipper: UnratedShipperDTO;
  onClickRate: () => void;
}

export function UnratedShipper({ unratedShipper, onClickRate }: Props) {
  const uid = useUID();
  const isRestrictRating = useFlag('restrict_rating');
  const { data: carrierSettings } = useCarrierSettings();
  const isRateAllowed =
    !isRestrictRating ||
    carrierSettings?.carrier.verification_status === 'approved';

  return (
    <Columns
      aria-labelledby={uid}
      key={unratedShipper.guid}
      space="small"
      align="top"
    >
      <Column width="content">
        <ShipperAvatar
          name={unratedShipper.name}
          logo={unratedShipper.logo}
          size="small"
        />
      </Column>

      <Column>
        <Columns space="small" align={['top', 'center']} collapseBelow="tablet">
          <Column>
            <Stack space="xsmall">
              <Columns
                space="xsmall"
                align={['top', 'center']}
                collapseBelow="tablet"
              >
                <Column width="content">
                  <ShipperProfileLink
                    id={uid}
                    aria-label="shipper name"
                    guid={unratedShipper.guid}
                    medium="Ratings To Give"
                  >
                    {unratedShipper.name}
                  </ShipperProfileLink>
                </Column>

                <Column>
                  <Inline space="xxsmall" verticalAlign="center" noWrap={true}>
                    <StyledThumbUp color="action" />

                    <ShipperProfileLink
                      aria-label="rating percentage"
                      guid={unratedShipper.guid}
                      medium="Ratings To Give"
                      tab="ratings"
                    >
                      {unratedShipper.overall_rating || 0}%
                    </ShipperProfileLink>

                    <Typography
                      aria-label="ratings count"
                      color="textSecondary"
                      component="span"
                    >
                      ({formatNumber(unratedShipper.total_rating_count || 0)})
                    </Typography>
                  </Inline>
                </Column>
              </Columns>

              <Typography color="textSecondary">
                {unratedShipper.accepted_loads_count} loads accepted by you
              </Typography>
            </Stack>
          </Column>

          <Column width="content">
            <Button
              variant="neutral"
              onClick={onClickRate}
              disabled={!isRateAllowed}
            >
              Rate Shipper
            </Button>
          </Column>
        </Columns>
      </Column>
    </Columns>
  );
}
