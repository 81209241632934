import { Box } from '@superdispatch/ui-lab';
import { useRef } from 'react';
import { LoadSuggestionsDrawerContent } from './LoadSuggestionsDrawer';

export function LoadSuggestionsPageMobile() {
  const visibleLoadSuggestionIds = useRef(new Set<number>([]));

  return (
    <Box
      paddingBottom="xxlarge"
      backgroundColor="White"
      height="calc(100vh - 48px)"
    >
      <LoadSuggestionsDrawerContent
        onView={(load) => {
          if (!load.is_seen) {
            visibleLoadSuggestionIds.current.add(load.suggestion_id);
          }
        }}
      />
    </Box>
  );
}
