import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Column, Columns, Inline } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { TimeIcon } from 'shared/icons/TimeIcon';

interface DriverPendingBannerProps {
  onClose: () => void;
}

export function DriverPendingBanner({ onClose }: DriverPendingBannerProps) {
  return (
    <Box
      width="100%"
      borderTopWidth="small"
      borderTopColor="Silver400"
      paddingLeft={['xxsmall', 'large']}
      paddingRight={['none', 'large']}
    >
      <Columns space="small" align={['top', 'center']}>
        <Column width="fluid">
          <Inline
            noWrap={true}
            verticalAlign={['top', 'center']}
            horizontalAlign="left"
            space="xxsmall"
          >
            <TimeIcon />

            <TextBox color="primary" variant="body">
              Driver was sent a text and email with instructions to download the
              Super Dispatch driver app and activate their account.
            </TextBox>
          </Inline>
        </Column>

        <Column width="content">
          <Inline horizontalAlign="right">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Inline>
        </Column>
      </Columns>
    </Box>
  );
}
