import { Drawer, IconButton, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import {
  formatLoadsPageField,
  LOAD_SEARCH_FIELDS,
} from '../../../loads/data/LoadsPageParams';

const StyledDrawerContent = styled(DrawerContent)`
  height: 100%;
`;

interface LoadsPageFilterSearchMobileProps {
  open?: boolean;
  onClose?: () => void;
}

export function LoadsPageFilterSearchMobile({
  open,
  onClose,
}: LoadsPageFilterSearchMobileProps) {
  const formik = useFormikContext<{
    name: string;
    value: string;
  }>();

  const { values, handleBlur, handleChange } = formik;

  return (
    <Drawer open={open} onClose={onClose}>
      <DrawerTitle
        title="Search"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />
      <StyledDrawerContent title="Search">
        <Stack space="small">
          <FormikTextField
            name="name"
            select={true}
            aria-label="search name"
            fullWidth={true}
          >
            {LOAD_SEARCH_FIELDS.map((field) => (
              <MenuItem key={field} value={field}>
                {formatLoadsPageField(field)}
              </MenuItem>
            ))}
          </FormikTextField>

          <FormikTextField
            name="value"
            value={values.value}
            placeholder="Search..."
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth={true}
          />
        </Stack>
      </StyledDrawerContent>

      <DrawerActions>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
          pending={formik.isSubmitting}
          disabled={!formik.dirty}
          fullWidth={true}
        >
          Search
        </Button>
      </DrawerActions>
    </Drawer>
  );
}
