import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Add, Clear, CloudUpload, Search } from '@material-ui/icons';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { ContactFormDrawer } from './ContactFormDrawer';
import { ContactsImportDrawer } from './ContactsImportDrawer';
import { useContactsPageRouter } from './ContactsPageRouter';

export function ContactsPageListHeader() {
  const isMobile = useResponsiveValue(true, false);
  const {
    currentModal,
    setCurrentModal,
    openContact,
    searchText,
    setSearchText,
  } = useContactsPageRouter();

  const closeModal = () => {
    setCurrentModal(undefined);
  };

  return (
    <>
      <ContactFormDrawer
        type="add"
        onClose={closeModal}
        open={currentModal === 'add'}
        onSuccess={(contact) => {
          closeModal();

          openContact(contact.guid);
        }}
      />

      <ContactsImportDrawer
        onClose={closeModal}
        open={currentModal === 'import'}
      />

      <Stack space="small">
        {!isMobile && (
          <>
            <Button
              fullWidth={true}
              startIcon={<CloudUpload />}
              variant="neutral"
              onClick={() => {
                setCurrentModal('import');
              }}
            >
              Import Contacts
            </Button>

            <Button
              startIcon={<Add />}
              fullWidth={true}
              onClick={() => {
                setCurrentModal('add');
              }}
            >
              Add Contact
            </Button>
          </>
        )}

        <TextField
          fullWidth={true}
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
          placeholder="Search for contact…"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
            endAdornment: !!searchText && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearchText('');
                  }}
                >
                  <Clear color="action" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
}
