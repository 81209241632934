import {
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  InputAdornment,
  Radio,
  Typography,
} from '@material-ui/core';
import { formatDate } from '@superdispatch/dates';
import {
  FormikCheckboxField,
  FormikRadioGroupField,
  FormikTextField,
  useFormikEnhanced,
} from '@superdispatch/forms';
import {
  Column,
  Columns,
  Inline,
  Stack,
  Tiles,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { APIResponse } from 'shared/api/CarrierAPIClient';
import { trackEvent } from 'shared/helpers/Analytics';
import {
  EMAIL_VALIDATION_PATTERN,
  validateString,
} from 'shared/helpers/ValidationHelpers';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import { useAllSearchDispatchersList } from '../dispatchers/data/DispatchersAPI';
import { getDriverLabel } from '../drivers/data/DriverPageUtils';
import { useDriverList } from '../drivers/data/DriversAPI';
import { ReportsNotAvailable } from './core/ReportNotAvailbale';
import { ReportsCheckboxGroup } from './core/ReportsCheckboxGroup';
import { ReportsDateRange } from './core/ReportsDateRangeField';
import { ReportsPageHeader } from './core/ReportsPageHeader';
import {
  useReportsAPI,
  useSavedReportItem,
  useSavedReportsList,
} from './data/ReportsAPI';
import { useReportsContext } from './data/ReportsContext';
import {
  customReportFields,
  customReportFilters,
  customReportSchema,
  ReportsCustomDTO,
} from './data/ReportsDTO';

type SubmissionType = 'save' | 'save_as_new' | 'send';

export function ReportsCustomPage() {
  const navigate = useNavigate();
  const { sendCustomReport, updateCustomReport, createCustomReport } =
    useReportsAPI();
  const { addSnackbar } = useSnackbarStack();
  const { reportGUID = '' } = useParams();
  const submissionType = useRef<SubmissionType>();
  const { refetch } = useSavedReportsList();
  const savedReport = useSavedReportItem(reportGUID);
  const { isAvailable } = useReportsContext();
  const { data: driversPage } = useDriverList({
    page_size: 5000,
  });

  const dispatchers = useAllSearchDispatchersList();

  const [dispatchersMap, dispatchersGuids] = useMemo(() => {
    const nextDispatchers = new Map<string, string>();
    const nextDispatchersGuids = [];

    for (const dispatcher of dispatchers.data || []) {
      nextDispatchers.set(dispatcher.guid, dispatcher.name);
      nextDispatchersGuids.push(dispatcher.guid);
    }

    return [nextDispatchers, nextDispatchersGuids];
  }, [dispatchers.data]);

  const [activeDrivers, inactiveDrivers, activeDriversGuids] = useMemo(() => {
    const nextActiveDrivers = new Map<string, string>();
    const nextInactiveDrivers = new Map<string, string>();
    const nextActiveDriverGuids: string[] = [];

    if (driversPage) {
      for (const driverPage of driversPage.pages) {
        for (const driver of driverPage.data) {
          const driverLabel = getDriverLabel(driver);

          if (!driver.is_suspended) {
            nextActiveDrivers.set(driver.guid, driverLabel);
            nextActiveDriverGuids.push(driver.guid);
          }

          if (driver.is_suspended) {
            nextInactiveDrivers.set(driver.guid, driverLabel);
          }
        }
      }
    }

    return [nextActiveDrivers, nextInactiveDrivers, nextActiveDriverGuids];
  }, [driversPage]);

  const exportFieldsOptions = useMemo(() => {
    const {
      generalInfo,
      vehicleInfo,
      brokerInfo,
      deliveryInfo,
      driverInfo,
      payment,
      pickupInfo,
    } = customReportFields;

    return [
      ...generalInfo.keys(),
      ...vehicleInfo.keys(),
      ...brokerInfo.keys(),
      ...deliveryInfo.keys(),
      ...driverInfo.keys(),
      ...payment.keys(),
      ...pickupInfo.keys(),
    ];
  }, []);

  const initialValues = useMemo(() => {
    const shortDate = formatDate(new Date(), { variant: 'ShortDate' });

    if (savedReport.data) {
      return customReportSchema.cast(savedReport.data);
    }

    return customReportSchema.cast({
      name: `Report ${shortDate}`,
      drivers: activeDriversGuids,
      dispatchers: dispatchersGuids,
      include_not_assigned: true,
      include_removed_drivers: true,
      fields: exportFieldsOptions,
    });
  }, [
    activeDriversGuids,
    savedReport.data,
    exportFieldsOptions,
    dispatchersGuids,
  ]);

  const formik = useFormikEnhanced<ReportsCustomDTO, APIResponse>({
    initialValues,
    onSubmit: (values) => {
      switch (submissionType.current) {
        case 'save':
          return updateCustomReport(values);
        case 'save_as_new':
          return createCustomReport(values);
        case 'send':
        default:
          return sendCustomReport(values);
      }
    },
    onSubmitFailure: (error) => {
      addSnackbar(
        error.message || 'Error occurred while sending report, try again.',
        {
          variant: 'error',
        },
      );
    },
    onSubmitSuccess: ({ data }: APIResponse<ReportsCustomDTO>, values) => {
      void refetch();

      const isSelectedSpecificDispatchers =
        values.dispatchers?.length !== 0 &&
        values.dispatchers?.length !== dispatchersGuids.length;

      switch (submissionType.current) {
        case 'save': {
          addSnackbar(`Saved report ${values.name} successfully updated.`, {
            variant: 'success',
          });
          break;
        }

        case 'save_as_new': {
          addSnackbar(`Saved report ${values.name} successfully created.`, {
            variant: 'success',
          });
          trackEvent('CTMS: Saved Report', {
            name: data.name,
            guid: data.guid,
            for_specific_dispatchers: isSelectedSpecificDispatchers,
          });
          navigate(`/reports/custom-report/${data.guid}`);
          break;
        }
        case 'send':
        default: {
          if (reportGUID) {
            trackEvent('CTMS: Saved Report Sent', {
              name: initialValues.name,
              guid: initialValues.guid,
              for_specific_dispatchers: isSelectedSpecificDispatchers,
            });
          } else {
            trackEvent('CTMS: Custom Report Sent', {
              for_specific_dispatchers: isSelectedSpecificDispatchers,
            });
          }

          addSnackbar(
            `Please check your email ${formik.values.report_email} in a minute.`,
            { variant: 'success' },
          );
        }
      }
    },
    validate: (values) => {
      if (values.fields?.length === 0) {
        return { fields: 'Please select fields to export' };
      }
      return undefined;
    },
  });

  const handleSubmit = (type: SubmissionType) => {
    submissionType.current = type;
    void formik.submitForm();
  };

  const isLoading =
    !driversPage || formik.isSubmitting || savedReport.isInitialLoading;

  return (
    <>
      <DocumentTitle
        title={(formik.values.name || 'Custom Report') + ' - Reports'}
      />

      <FormikProvider value={formik}>
        <Form>
          <PageLayout
            stickyHeader={true}
            loading={isLoading}
            header={
              <ReportsPageHeader
                title={
                  isAvailable
                    ? formik.values.name || 'Custom Report'
                    : 'Custom Report'
                }
                actions={
                  isAvailable && (
                    <Inline>
                      <FormikTextField
                        name="name"
                        id="reportName"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              component="label"
                              htmlFor="reportName"
                            >
                              <TextBox
                                noWrap={true}
                                color="secondary"
                                variant="body-semibold"
                              >
                                Report Name
                              </TextBox>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {reportGUID && (
                        <Button
                          variant="neutral"
                          pending={
                            formik.isSubmitting &&
                            submissionType.current === 'save'
                          }
                          disabled={isLoading}
                          onClick={() => {
                            handleSubmit('save');
                          }}
                        >
                          Save
                        </Button>
                      )}
                      <Button
                        variant="neutral"
                        pending={
                          formik.isSubmitting &&
                          submissionType.current === 'save_as_new'
                        }
                        disabled={isLoading}
                        onClick={() => {
                          handleSubmit('save_as_new');
                        }}
                      >
                        {reportGUID ? 'Save as New' : 'Save'}
                      </Button>
                      <Button
                        pending={
                          formik.isSubmitting &&
                          submissionType.current === 'send'
                        }
                        disabled={isLoading}
                        onClick={() => {
                          handleSubmit('send');
                        }}
                      >
                        Send Report
                      </Button>
                    </Inline>
                  )
                }
              />
            }
          >
            {isAvailable ? (
              <Columns space="small" collapseBelow="desktop">
                <Column width={['fluid', '1/3']}>
                  <Card>
                    <CardContent>
                      <Stack space="medium">
                        <FormikTextField
                          fullWidth={true}
                          name="report_email"
                          label={
                            <Inline space="xxsmall">
                              Email
                              <Typography color="textSecondary">
                                (Required)
                              </Typography>
                            </Inline>
                          }
                          helperText="Generated Excel file will be sent to this email."
                          validate={(value) =>
                            validateString(value, {
                              required: true,
                              pattern: EMAIL_VALIDATION_PATTERN,
                              patternMessage: 'Invalid Email',
                            })
                          }
                          disabled={isLoading}
                        />

                        <ReportsDateRange
                          enableClearable={true}
                          fullWidth={true}
                          name="creation_period"
                          label="Filter by Creation Period"
                          validate={(range) =>
                            !!range?.start_date && !range.end_date
                              ? 'Please select creation period end date'
                              : undefined
                          }
                        />

                        <ReportsDateRange
                          enableClearable={true}
                          fullWidth={true}
                          name="delivery_period"
                          label="Filter by Actual Delivery Period"
                          validate={(range) =>
                            !!range?.start_date && !range.end_date
                              ? 'Please select delivery period end date'
                              : undefined
                          }
                        />

                        <FormikRadioGroupField name="order_by" label="Order By">
                          {Array.from(
                            customReportFilters.orderByFilters.entries(),
                            ([key, label]) => (
                              <FormControlLabel
                                key={key}
                                value={key}
                                control={<Radio />}
                                label={label}
                              />
                            ),
                          )}
                        </FormikRadioGroupField>

                        <FormikRadioGroupField
                          name="load_filter"
                          label="Include"
                        >
                          {Array.from(
                            customReportFilters.loadTypeFilters.entries(),
                            ([key, label]) => (
                              <FormControlLabel
                                key={key}
                                value={key}
                                control={<Radio />}
                                label={label}
                              />
                            ),
                          )}
                        </FormikRadioGroupField>

                        <FormikRadioGroupField
                          name="load_status"
                          label="Status"
                        >
                          {Array.from(
                            customReportFilters.loadStatusFilters.entries(),
                            ([key, label]) => (
                              <FormControlLabel
                                key={key}
                                value={key}
                                control={<Radio />}
                                label={label}
                              />
                            ),
                          )}
                        </FormikRadioGroupField>

                        <Stack space="none">
                          <FormikCheckboxField
                            label="No Driver (Loads without assigned driver)"
                            name="include_not_assigned"
                          />
                          <FormikCheckboxField
                            label="Deleted Drivers"
                            name="include_removed_drivers"
                          />
                        </Stack>

                        <ReportsCheckboxGroup
                          label="Assigned to Active Drivers"
                          name="drivers"
                          options={activeDrivers}
                          hasActions={true}
                        />

                        <ReportsCheckboxGroup
                          label="Assigned to Inactive Drivers"
                          name="drivers"
                          options={inactiveDrivers}
                          hasActions={true}
                        />

                        <ReportsCheckboxGroup
                          label="Dispatchers"
                          name="dispatchers"
                          options={dispatchersMap}
                          hasActions={true}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Column>
                <Column width={['fluid', '2/3']}>
                  <Card>
                    <CardContent>
                      <Stack space="medium">
                        <Columns aria-label="fields actions">
                          <Column width="fluid">
                            <Typography variant="h4">Export Fields</Typography>
                          </Column>
                          <Column width="content">
                            <Inline space="xsmall" horizontalAlign="center">
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => {
                                  void formik.setFieldValue(
                                    'fields',
                                    exportFieldsOptions,
                                  );
                                }}
                              >
                                Select All
                              </Button>
                              /
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => {
                                  void formik.setFieldValue('fields', []);
                                }}
                              >
                                Clear All
                              </Button>
                            </Inline>
                          </Column>
                        </Columns>

                        <Divider />

                        <Tiles columns={2} space={{ sm: 2, xs: 2 }}>
                          <ReportsCheckboxGroup
                            label="General Info"
                            name="fields"
                            options={customReportFields.generalInfo}
                          />
                          <ReportsCheckboxGroup
                            label="Vehicle"
                            name="fields"
                            options={customReportFields.vehicleInfo}
                          />
                          <ReportsCheckboxGroup
                            label="Pickup"
                            name="fields"
                            options={customReportFields.pickupInfo}
                          />
                          <ReportsCheckboxGroup
                            label="Delivery"
                            name="fields"
                            options={customReportFields.deliveryInfo}
                          />
                          <ReportsCheckboxGroup
                            label="Shipper"
                            name="fields"
                            options={customReportFields.brokerInfo}
                          />
                          <ReportsCheckboxGroup
                            label="Driver"
                            name="fields"
                            options={customReportFields.driverInfo}
                          />
                          <ReportsCheckboxGroup
                            label="Payment"
                            name="fields"
                            options={customReportFields.payment}
                          />
                        </Tiles>
                      </Stack>
                    </CardContent>
                  </Card>
                </Column>
              </Columns>
            ) : (
              <ReportsNotAvailable />
            )}
          </PageLayout>
        </Form>
      </FormikProvider>
    </>
  );
}
