import { Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DateConfigProvider } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Stack,
} from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useLazyRef } from 'shared/hooks/useLazyRef';
import styled from 'styled-components';
import { toLoadGroupByTab } from '../../../loads/data/LoadGroupByOptionsDTO';
import { trackLoadsEvent } from '../../../loads/data/LoadsAnalytics';
import {
  LoadFilterField,
  LoadsPageParams,
  LOAD_FILTER_FIELDS,
} from '../../../loads/data/LoadsPageParams';
import { useLoadsPageContext } from '../data/LoadsPageParamsContext';
import { LoadsPageFilterOrderBy } from '../LoadsPageFilterOrderBy';
import { LoadsPageFilterResourceField } from '../LoadsPageFilterResourceField';
import { LoadsPageGroupByList } from '../LoadsPageGroupByList';

const StyledDrawerContent = styled(DrawerContent)`
  height: 100%;
  overflow: auto;
`;
const StyledDrawerActions = styled(DrawerActions)`
  padding-bottom: 24px;
`;

export function LoadsPageFilterMobile({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const getLazyRef = useLazyRef<HTMLInputElement, LoadFilterField>();
  const { params, updateParams, isEmptyFilterParams } = useLoadsPageContext();

  const formik = useFormikEnhanced<Partial<LoadsPageParams>, unknown>({
    initialValues: {
      ...params,
    },
    onSubmit: (values) => {
      updateParams(
        (prev) => ({
          stage: prev.stage,
          ...values,
        }),
        { strategy: 'reset' },
      );
      onClose();
    },
  });

  const groupListTab = toLoadGroupByTab(params.stage);
  const shouldShowGroupByList = !!formik.values.order_by && !!groupListTab;

  return (
    <FormikProvider value={formik}>
      <Form>
        <Drawer open={open} onClose={onClose}>
          <DrawerTitle
            title="Filters"
            endAction={
              <IconButton edge="end" onClick={onClose}>
                <Close aria-label="close" />
              </IconButton>
            }
          />
          <StyledDrawerContent title="Search">
            <Stack space="small">
              {LOAD_FILTER_FIELDS.map((filter) => {
                const ref = getLazyRef(filter);

                if (params.stage !== 'in_terminal' && filter === 'terminals') {
                  return null;
                }

                return (
                  <LoadsPageFilterResourceField
                    key={filter}
                    name={filter}
                    inputRef={ref}
                    value={formik.values[filter]}
                    onChange={(guid) => {
                      void formik.setValues({
                        ...formik.values,
                        [filter]: guid,
                      });
                    }}
                  />
                );
              })}

              <LoadsPageFilterOrderBy
                stage={params.stage}
                orderBy={formik.values.order_by ?? null}
                onChange={(orderBy) => {
                  void formik.setFieldValue('order_by', orderBy);

                  if (orderBy) {
                    trackLoadsEvent({
                      name: 'CTMS: Sorted Loads',
                      field: orderBy,
                    });
                  }
                }}
              />

              {shouldShowGroupByList &&
                Boolean(groupListTab) &&
                formik.values.order_by && (
                  <DateConfigProvider format="DateISO">
                    <LoadsPageGroupByList
                      tab={groupListTab}
                      groupBy={formik.values.order_by}
                      selectedParams={{
                        ...formik.values,
                      }}
                      onSelect={(optionParams) => {
                        void formik.setValues({
                          ...formik.values,
                          ...optionParams,
                        });
                      }}
                    />
                  </DateConfigProvider>
                )}
            </Stack>
          </StyledDrawerContent>

          <StyledDrawerActions>
            <Stack>
              {(!isEmptyFilterParams || params.order_by) && (
                <Button
                  variant="neutral"
                  fullWidth={true}
                  onClick={() => {
                    updateParams((prev) => ({ stage: prev.stage }), {
                      strategy: 'reset',
                    });
                    formik.resetForm();
                    onClose();
                  }}
                >
                  Clear Filters
                </Button>
              )}
              <Button
                onClick={() => {
                  formik.handleSubmit();
                }}
                pending={formik.isSubmitting}
                disabled={!formik.dirty}
                fullWidth={true}
              >
                Apply Filters
              </Button>
            </Stack>
          </StyledDrawerActions>
        </Drawer>
      </Form>
    </FormikProvider>
  );
}
