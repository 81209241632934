import { AppBar, Dialog, IconButton, Toolbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { usePrintHTMLContent } from 'shared/hooks/usePrintHTMLContent';
import { LoadBOLPReviewParams, useLoadBOLPreview } from '../data/LoadsAPI';
import { PreviewBox } from './LoadInvoicePreviewDialog';

interface LoadBOLPreviewDialogContentProps {
  onClose: () => void;
  params: LoadBOLPReviewParams;
  loadGUID: null | string | undefined;
}

function LoadBOLPreviewDialogContent({
  params,
  onClose,
  loadGUID,
}: LoadBOLPreviewDialogContentProps) {
  const { data } = useLoadBOLPreview(loadGUID, params);

  usePrintHTMLContent(data?.html || '');

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <TextBox variant="heading-3">BOL Preview</TextBox>
        </Toolbar>
      </AppBar>

      <Box
        width="21cm"
        height="100%"
        marginLeft="auto"
        marginRight="auto"
        marginTop="xxlarge"
        marginBottom="small"
        paddingTop="xxlarge"
      >
        <PreviewBox
          id="bol-preview"
          //  eslint-disable-next-line @typescript-eslint/naming-convention
          dangerouslySetInnerHTML={{ __html: data?.html || '' }}
        />
      </Box>
    </>
  );
}

export interface LoadBOLPreviewDialogProps
  extends LoadBOLPreviewDialogContentProps {
  open: boolean;
}

export function LoadBOLPreviewDialog({
  open,
  onClose,
  ...props
}: LoadBOLPreviewDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={true}>
      <LoadBOLPreviewDialogContent {...props} onClose={onClose} />
    </Dialog>
  );
}
