import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  APIListQueryOptions,
  APIListQueryResult,
  findAPIListQueryItem,
  useAPIListQuery,
} from 'shared/api/APIListQuery';
import {
  APIPageQueryOptions,
  APIPageQueryResult,
  findAPIPageQueryItem,
  useAPIPageQuery,
} from 'shared/api/APIPageQuery';
import {
  APIQueryResult,
  useAPIQuery,
  UseAPIQueryOptions,
} from 'shared/api/APIQuery';
import {
  APIPageResponse,
  requestCarrierAPI,
} from 'shared/api/CarrierAPIClient';
import { TerminalDTO, terminalSchema } from './TerminalDTO';

export function useTerminal(
  guid: null | string | undefined,
  options?: UseAPIQueryOptions<TerminalDTO>,
): APIQueryResult<TerminalDTO> {
  const queryClient = useQueryClient();
  return useAPIQuery(
    ['terminals', 'item', { guid }],
    () =>
      requestCarrierAPI([
        'GET /internal/web/terminals/{?guids}',
        { guids: guid },
      ]).then((response: APIPageResponse) => ({
        ...response,
        data: terminalSchema.cast(response.data[0]),
      })),
    {
      ...options,
      enabled: !!guid,
      schema: terminalSchema,
      initialData() {
        return (
          findAPIListQueryItem<TerminalDTO>(
            ['terminals', 'list'],
            queryClient,
            (item) => item.guid === guid,
          ) ||
          findAPIPageQueryItem<TerminalDTO>(
            ['terminals', 'page'],
            queryClient,
            (item) => item.guid === guid,
          )
        );
      },
    },
  );
}

export interface TerminalPageParams {
  q?: string;
  guids?: string;
  page?: number;
  page_size?: number;
}

export function useTerminalPage(
  params?: TerminalPageParams,
  options?: APIPageQueryOptions<TerminalDTO>,
): APIPageQueryResult<TerminalDTO> {
  return useAPIPageQuery(
    ['terminals', 'page', params],
    () =>
      requestCarrierAPI(['GET /internal/web/terminals/{?params*}', { params }]),
    { ...options, schema: terminalSchema },
  );
}

export function useTerminalsList(
  params?: Omit<TerminalPageParams, 'page'>,
  options?: APIListQueryOptions<TerminalDTO>,
): APIListQueryResult<TerminalDTO> {
  return useAPIListQuery(
    ['terminals', 'list', { params }],
    (page) =>
      requestCarrierAPI([
        'GET /internal/web/terminals/{?page,params*}',
        { page, params },
      ]),
    { ...options, schema: terminalSchema },
  );
}

export function useTerminalsAPI() {
  const queryClient = useQueryClient();

  return useMemo(
    () => ({
      createTerminal: (json: TerminalDTO) =>
        requestCarrierAPI('POST /internal/web/terminals/', { json }).then(
          (response) => {
            void queryClient.invalidateQueries(['terminals']);

            return response;
          },
        ),
      deleteTerminal: (guid: string) =>
        requestCarrierAPI([
          'DELETE /internal/web/terminals/{guid}/',
          { guid },
        ]).then((response) => {
          void queryClient.invalidateQueries(['terminals']);

          return response;
        }),
    }),
    [queryClient],
  );
}
