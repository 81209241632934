import { Drawer, IconButton, Link } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useFormikEnhanced } from '@superdispatch/forms';
import { DrawerContent, DrawerTitle, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { Prompt } from 'shared/routing/NavigationBlock';
import { useSettingsAPI } from '../data/SettingsAPI';
import { downgradeRequestSchema } from '../data/SettingsDTO';
import { DowngradeConfirmationStep } from './downgrade-steps/DowngradeConfirmationStep';
import { DowngradeFeedbackStep } from './downgrade-steps/DowngradeFeedbackStep';
import { DowngradeSelectReasonsStep } from './downgrade-steps/DowngradeSelectReasonsStep';

interface SubscriptionDowngradeProps {
  open?: boolean;
  onClose: () => void;
  onSubmitSuccess: () => void;
}

export function SubscriptionDowngrade({
  open,
  onClose,
  onSubmitSuccess,
}: SubscriptionDowngradeProps) {
  const [stepCount, setStepCount] = useState(1);

  const [isFailedToDowngrade, setIsFailedToDowngrade] = useState(false);
  const { downgradeSubscription } = useSettingsAPI();
  const { showLauncherMessanger } = useCustomerSupport();

  const formik = useFormikEnhanced<
    {
      reasons: Map<string, { reason: string; comment?: string }>;
      feedback: string;
    },
    unknown
  >({
    initialValues: {
      reasons: new Map([]),
      feedback: '',
    },
    onSubmit: (values) => {
      return downgradeSubscription(
        /* `comment` field is optional on the UI but should be sent as empty string, so we have cast here */
        downgradeRequestSchema.cast({
          reasons: Array.from(values.reasons.values()),
          feedback: values.feedback,
        }),
      );
    },
    onSubmitSuccess,
    onSubmitFailure: () => {
      setIsFailedToDowngrade(true);
    },
  });

  const handleMoveNextStep = () => {
    setStepCount(stepCount + 1);
  };

  const isSubmitted = formik.status.type === 'submitted';

  return (
    <Drawer open={open} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form>
          <Prompt
            when={formik.dirty && !isSubmitted}
            message="Changes have not been saved. Leaving can result in unsaved changes being lost."
          />
          {isFailedToDowngrade ? (
            <>
              <DrawerTitle
                title="Downgrading Plan"
                endAction={
                  <IconButton edge="end" onClick={onClose}>
                    <Close aria-label="close" />
                  </IconButton>
                }
              />

              <DrawerContent>
                <Box maxWidth="360px">
                  <Stack space="large">
                    <TextBox>
                      Unknown issue happened while downgrading your account. Try
                      again later.
                    </TextBox>
                    <TextBox>
                      If you keep getting this issue please contact us at{' '}
                      <Link href="mailto:support@superdispatch.com">
                        support@superdispatch.com
                      </Link>{' '}
                      or click the chat icon in the bottom right corner.
                    </TextBox>

                    <Button
                      onClick={() => {
                        void formik.submitForm();
                      }}
                      pending={formik.isSubmitting}
                      disabled={!formik.values.reasons.size}
                    >
                      Retry
                    </Button>

                    <TextBox color="secondary">
                      For any questions or feedback, please{' '}
                      <Link
                        component="button"
                        type="button"
                        align="center"
                        onClick={() => {
                          showLauncherMessanger();
                        }}
                      >
                        contact support
                      </Link>
                    </TextBox>
                  </Stack>
                </Box>
              </DrawerContent>
            </>
          ) : (
            <>
              {stepCount === 1 && (
                <DowngradeConfirmationStep
                  onNext={handleMoveNextStep}
                  onClose={onClose}
                  formik={formik}
                />
              )}

              {stepCount === 2 && (
                <DowngradeSelectReasonsStep
                  onNext={handleMoveNextStep}
                  onClose={onClose}
                  formik={formik}
                />
              )}

              {stepCount === 3 && (
                <DowngradeFeedbackStep
                  onNext={() => {
                    void formik.submitForm();
                  }}
                  onClose={onClose}
                  formik={formik}
                />
              )}
            </>
          )}
        </Form>
      </FormikProvider>
    </Drawer>
  );
}
