import { Divider, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  Color,
  ColorDynamic,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { trackEvent } from 'shared/helpers/Analytics';
import { useSearchParam } from 'shared/helpers/SearchParamsHelpers';
import { openExternalURL } from 'shared/helpers/URLHelpers';
import { LoadsSidebarIcon } from 'shared/icons/LoadsSidebarIcon';
import { MobileAddIcon } from 'shared/icons/MobileAddIcon';
import { OrganizationIcon } from 'shared/icons/OrganizationIcon';
import { UploadCloudIcon } from 'shared/icons/UploadCloudIcon';
import { CustomerComments } from 'shared/modules/subscription/core/CustomerComments';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import styled from 'styled-components';
import { PaywallDialogDescription } from './core/PaywallDialogDescription';

const StyledBox = styled(Box)`
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 16px;
  background-color: ${ColorDynamic.White};
`;

export function TrialExpiredPage() {
  const isMobile = useResponsiveValue(true, true, false);
  const [pageName] = useSearchParam('page');
  const [type] = useSearchParam('type');
  const { data: subscription } = useSubscriptionSettings();

  const page = useMemo(() => {
    if (!pageName) return 'Loads';
    const name = pageName.split('/').pop();
    return name || 'Loads';
  }, [pageName]);

  if (!isMobile) {
    return <Navigate to="/loads" />;
  }

  if (
    subscription?.status === 'Active' ||
    subscription?.status === 'In Trial'
  ) {
    return <Navigate to="/loads" />;
  }

  return (
    <Box position="relative">
      {type === 'cancelled' ? (
        <PaywallCancelledContent />
      ) : (
        <PaywallDialogDescription pageName={page} />
      )}
      <StyledBox>
        <Stack space="small">
          <Button
            fullWidth={true}
            onClick={() => {
              if (type === 'cancelled') {
                trackEvent('Carrier Clicked Reactivate Now', {
                  utm_medium: 'Mobile',
                });
              } else {
                trackEvent('Carrier Clicked Continue to Upgrade', {
                  utm_medium: 'Mobile',
                });
              }

              openExternalURL('/tms/settings/subscription');
            }}
          >
            {type === 'cancelled' ? 'Reactivate Now' : 'Continue to Upgrade'}
          </Button>
          <Button
            variant="neutral"
            fullWidth={true}
            onClick={() => {
              openExternalURL('/loadboard');
              trackEvent('Carrier Clicked Access Free Loadboard', {
                utm_medium: 'Mobile',
              });
            }}
          >
            Access Free Loadboard
          </Button>
        </Stack>
      </StyledBox>
    </Box>
  );
}

const items = [
  {
    icon: <MobileAddIcon fontSize="large" htmlColor={Color.Dark300} />,
    title: 'Create & Import from Driver App',
    description: 'Create and Import loads from Super Dispatch Driver App',
  },
  {
    icon: <LoadsSidebarIcon fontSize="large" htmlColor={Color.Dark300} />,
    title: 'Advanced Load Management',
    description:
      'Effortlessly create, import, request, negotiate, and book loads without making a single phone call.',
  },
  {
    icon: <OrganizationIcon htmlColor={Color.Dark300} />,
    title: 'Unified Load Organization',
    description:
      'Keep track of all your loads from any load board in one convenient place.',
  },
  {
    icon: <UploadCloudIcon htmlColor={Color.Dark300} />,
    title: 'Boost Productivity',
    description:
      'Speed up your process by importing loads from PDF or CSV files, minimizing manual tasks and errors.',
  },
];

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

function PaywallCancelledContent() {
  const shouldShowMonetizationExperiment = useFlag('monetization_experiment');

  const listItems = useMemo(() => {
    if (shouldShowMonetizationExperiment) {
      return items;
    }

    return items.slice(1);
  }, [shouldShowMonetizationExperiment]);

  const onClose = () => {
    openExternalURL('/loadboard');
  };

  return (
    <Box padding="medium" paddingBottom="xlarge">
      <Stack space="medium">
        <Stack space="small">
          <Columns align="top" space="small">
            <Column width="fluid">
              <TextBox variant="heading-2">
                Your Trial of Carrier TMS Pro has Ended
              </TextBox>
            </Column>
            <Column width="content">
              <Inline horizontalAlign="right">
                <StyledIconButton onClick={onClose}>
                  <Close fontSize="small" />
                </StyledIconButton>
              </Inline>
            </Column>
          </Columns>
          <TextBox variant="body">
            Hope you enjoyed your Carrier TMS Pro trial! Browsing Super
            Loadboard is still free, but advanced features are now locked.
            Upgrade today for full access to:
          </TextBox>
        </Stack>

        {listItems.map((item) => (
          <Columns align="top" key={item.title} space="medium">
            <Column width="content">
              <Inline horizontalAlign="right">{item.icon}</Inline>
            </Column>
            <Column>
              <Stack space="xxsmall">
                <TextBox variant="heading-4">{item.title}</TextBox>
                <TextBox variant="body" color="secondary">
                  {item.description}
                </TextBox>
              </Stack>
            </Column>
          </Columns>
        ))}

        <Divider />

        <CustomerComments source="canceled" />
      </Stack>
    </Box>
  );
}
