import { IconButton, Slide } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { DateConfigProvider } from '@superdispatch/dates';
import {
  Column,
  Columns,
  ExitTransitionPlaceholder,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useEffect, useState } from 'react';
import { APIError } from 'shared/api/APIError';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import { useQuery } from 'shared/helpers/RoutingHelpers';
import { LocalStore, useLocalStore } from 'shared/helpers/Store';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import { PageHeader, PageLayout } from 'shared/layout/PageLayout';
import { PageToolbar } from 'shared/layout/PageToolbar';
import { ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY } from 'shared/modules/onboarding/data/OnboardingConstants';
import { useOnboardingTourHandler } from 'shared/modules/onboarding/OnboardingIntercomTours';
import { ExpeditedPayBanner } from 'shared/modules/superpay/expedited-payment-reminder/ExpeditedPayBanner';
import { SuperPayExpeditedPayDialog } from 'shared/modules/superpay/SuperPayExpeditedPayDialog';
import { SuperPayYouGotPaidBanner } from 'shared/modules/superpay/SuperPayYouGotPaidBanner';
import { ScrollToTop } from 'shared/routing/ScrollToTop';
import { useCarrierSettings } from 'shared/settings/CarrierSettingsAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import {
  getLoadActionThreeModesVariants,
  LoadActionThreeModes,
} from '../core/LoadActionThreeModes';
import { LoadAddTerminalDrawer } from '../core/LoadAddTerminalDrawer';
import { LoadAssign } from '../core/LoadAssign';
import {
  isLoadInstantActionType,
  useLoadInstantAction,
} from '../core/LoadInstantAction';
import { LoadMarkAsPaidDrawer } from '../core/LoadMarkAsPaidDrawer';
import { LoadNoteAddDialog } from '../core/LoadNoteAddDialog';
import { LoadSendBOLDrawer } from '../core/LoadSendBOLDrawer';
import { LoadSendInvoiceDrawer } from '../core/LoadsendInvoiceDrawer';
import { UpgradePlanDialog } from '../core/UpgradePlanDialog';
import {
  formatLoadStage,
  formatLoadStatus,
  LoadDTO,
  LoadUpdateTerminalDTO,
} from '../data/LoadDTO';
import { toLoadGroupByTab } from '../data/LoadGroupByOptionsDTO';
import { trackLoadsEvent } from '../data/LoadsAnalytics';
import {
  useLoadsCache,
  useLoadsPage,
  useSuggestedLoads,
} from '../data/LoadsAPI';
import { LoadsPageParams } from '../data/LoadsPageParams';
import { useLoadsPageContext } from './data/LoadsPageParamsContext';
import { useSendInvoiceDrawerTour } from './data/useSendInvoiceDrawerTour';
import { LoadCancelDialog } from './LoadCancelDialog';
import { LoadsPageEmptyResult } from './LoadsPageEmptyResult';
import { LoadsPageFilter } from './LoadsPageFilter';
import { LoadsPageGroupByList } from './LoadsPageGroupByList';
import { LoadsPageHeaderActions } from './LoadsPageHeaderActions';
import { LoadsPageLoad } from './LoadsPageLoad';
import { LoadsPageLoadAction } from './LoadsPageLoadActions';
import { LoadsPagePagination, LOADS_PAGE_SIZE } from './LoadsPagePagination';
import { LoadsPageSuggestedLoadsList } from './LoadsPageSuggestedLoadsList';
import { LoadsPageTabs } from './LoadsPageTabs';

export function LoadsPage() {
  useSendInvoiceDrawerTour();
  const [{ can_import, launch_create_import_loads_tour }, setQuery] =
    useQuery();
  const { addSnackbar } = useSnackbarStack();
  const { params, updateParams } = useLoadsPageContext();
  const [previousPageParams, setPreviousPageParams] =
    useState<LoadsPageParams>();
  const isMobile = useResponsiveValue(true, false, false);
  const loads = useLoadsPage(params, {
    onError({ type, message }: APIError) {
      /* Server returns error ObjectDoesNotExist when there are no loads on non-first page.
      For example: delete or move to another tab the last load on the page.
      In that case previous page should be opened. */
      if (type === 'ObjectDoesNotExist' && params.page > 1) {
        updateParams({ page: params.page - 1 });
      } else if (
        message !== 'subscription_cancelled' &&
        message !== 'trial_expired'
      ) {
        addSnackbar(message, { variant: 'error' });
      }
    },
  });
  const { data: settings } = useCarrierSettings();
  const suggestedLoads = useSuggestedLoads();
  const { invalidateLoads, setLoadsPageItems } = useLoadsCache();
  const shouldShowSuggestedLoads = useFlag('ctms_suggested_loads');
  const isSuggestedLoadsSecondIterationEnabled = useFlag(
    'ctms_suggested_loads_second_iteration',
  );

  const groupListTab = toLoadGroupByTab(params.stage);
  const shouldShowGroupByList = !!params.order_by && !!groupListTab;

  const onboardingAssignDriverTourState = useLocalStore(
    ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY,
  );

  const { startTour } = useOnboardingTourHandler({
    tourName: 'tour_create_or_import_load',
  });
  const { showLauncher, hideLauncher } = useCustomerSupport();
  useOnboardingTourHandler({
    tourName: 'tour_assign_driver',
    enabled:
      onboardingAssignDriverTourState === 'pending' && groupListTab === 'new',
    onSettled: () => {
      LocalStore.delete(ONBOARDING_FIRST_LOAD_TOUR_STORAGE_KEY);
    },
  });

  useEffect(() => {
    if (launch_create_import_loads_tour) {
      startTour();
      setQuery({ launch_create_import_loads_tour: undefined });
    }
  }, [launch_create_import_loads_tour, setQuery, startTour]);

  const [modalState, setModalState] = useState<
    undefined | { type: LoadsPageLoadAction; load?: LoadDTO }
  >();

  const getModalLoad = (variant: LoadsPageLoadAction): undefined | LoadDTO =>
    modalState?.type !== variant ? undefined : modalState.load;
  const getModalLoadGUID = (variant: LoadsPageLoadAction): undefined | string =>
    modalState?.type !== variant ? undefined : modalState.load?.guid;

  const closeModal = () => {
    setModalState(undefined);
  };

  const closeModalAndRefetch = () => {
    closeModal();
    invalidateLoads();
  };

  const mutateLoad = (guid: string, values: Partial<LoadDTO>) => {
    setLoadsPageItems(params, (items) =>
      items.map((x) => (x.guid !== guid ? x : { ...x, ...values })),
    );
  };

  const removeLoad = (guid: string) => {
    setLoadsPageItems(params, (items) => items.filter((x) => x.guid !== guid));
  };

  const { submitInstantAction, isSubmitting } = useLoadInstantAction({
    onActionComplete: (type, load) => {
      if (type === 'unarchive') {
        removeLoad(load.guid);
      }

      if (type === 'mark_as_unpaid') {
        invalidateLoads();
      }
    },
  });

  const isSendInvoiceDrawerOpened = getModalLoad('send_invoice_drawer');

  const shouldRestrictImport = !settings?.can_import_load;

  useEffect(() => {
    if (isSendInvoiceDrawerOpened) {
      hideLauncher();
    } else {
      showLauncher();
    }
  }, [isSendInvoiceDrawerOpened, showLauncher, hideLauncher]);

  useEffect(() => {
    if (can_import === 'disabled' && shouldRestrictImport) {
      setModalState(() => ({
        type: 'upgrade_plan',
      }));
    } else {
      setModalState(undefined);
    }

    if (params.stage !== 'active') {
      setPreviousPageParams(params);
    }
  }, [params, can_import, shouldRestrictImport]);

  const shouldRenderSuggestedLoads =
    !!suggestedLoads.data?.length &&
    shouldShowSuggestedLoads &&
    !isSuggestedLoadsSecondIterationEnabled &&
    params.stage !== 'active' &&
    params.stage !== 'deleted' &&
    params.stage !== 'archived';

  return (
    <>
      <DocumentTitle title={formatLoadStage(params.stage) + ' Loads'} />

      <ScrollToTop key={params.page} />

      <LoadNoteAddDialog
        onClose={closeModal}
        load={getModalLoad('add_note')}
        onSubmitSuccess={({ note, loadGUID }) => {
          closeModal();
          mutateLoad(loadGUID, { latest_internal_note: note });
        }}
      />

      <LoadAssign
        onClose={closeModal}
        load={getModalLoad('assign')}
        onSubmitSuccess={({ loadGUID, currentDriver }) => {
          closeModal();
          if (currentDriver) {
            trackLoadsEvent({
              name: 'CTMS: Driver Reassigned',
              page: 'loads_list',
            });
            removeLoad(loadGUID);
          } else {
            trackLoadsEvent({
              name: 'CTMS: Driver Assigned',
              page: 'loads_list',
            });
            invalidateLoads();
          }
        }}
      />

      <LoadSendBOLDrawer
        onClose={closeModal}
        pageType="loads_list"
        loadGUID={getModalLoadGUID('send_bol')}
        onSubmitSuccess={closeModalAndRefetch}
      />

      <LoadMarkAsPaidDrawer
        onClose={closeModal}
        load={getModalLoad('mark_as_paid')}
        onSubmitSuccess={() => {
          trackLoadsEvent({ name: 'CTMS: Marked as Paid', page: 'loads_list' });
          closeModalAndRefetch();
        }}
      />

      <LoadCancelDialog
        onClose={closeModalAndRefetch}
        load={getModalLoad('cancel')}
        disableAutoclose={true}
      />

      <UpgradePlanDialog
        onClose={closeModal}
        open={modalState?.type === 'upgrade_plan'}
      />

      {getLoadActionThreeModesVariants().map((variant) => (
        <LoadActionThreeModes
          key={variant}
          variant={variant}
          onClose={closeModal}
          load={getModalLoad(variant)}
          onSubmitSuccess={(data, { load }) => {
            closeModal();

            switch (variant) {
              case 'delete':
                trackLoadsEvent({
                  name: 'Carrier Deleted Load',
                  page: 'loads_list',
                  utm_medium: formatLoadStage(params.stage),
                  load_status: formatLoadStatus(load.status),
                  is_created_by_broker: !!load.is_created_by_broker,
                });
                break;
              case 'archive':
                trackLoadsEvent({
                  name: 'CTMS: Load Archived',
                  page: 'loads_list',
                });
                break;
              case 'restore':
                trackLoadsEvent({
                  name: 'CTMS: Restored Load',
                  page: 'loads_list',
                });
                break;

              case 'mark_as_picked_up':
                trackLoadsEvent({
                  name: 'CTMS: Marked as Picked Up',
                  page: 'loads_list',
                });
                break;

              case 'mark_as_delivered':
                trackLoadsEvent({
                  name: 'CTMS: Marked as Delivered',
                  page: 'loads_list',
                });
                break;

              case 'mark_as_new':
                trackLoadsEvent({
                  name: 'CTMS: Marked as New',
                  page: 'loads_list',
                });
                break;

              case 'dispatch_to_carrier':
                trackLoadsEvent({
                  name: 'CTMS: Dispatched To Carrier',
                  page: 'loads_list',
                });
                break;

              case 'unassign':
                trackLoadsEvent({
                  name: 'CTMS: Driver Unassigned',
                  page: 'loads_list',
                });
                break;
            }

            if (
              variant === 'delete' ||
              variant === 'archive' ||
              variant === 'restore' ||
              variant === 'unassign' ||
              variant === 'mark_as_in_terminal'
            ) {
              removeLoad(load.guid);
            } else if (variant === 'remove_terminal') {
              const { terminals_diagram } = data as LoadUpdateTerminalDTO;

              mutateLoad(load.guid, { terminals_diagram });
            } else {
              invalidateLoads();
            }
          }}
        />
      ))}

      <LoadSendInvoiceDrawer
        onClose={closeModal}
        load={getModalLoad('send_invoice_drawer')}
      />

      <SuperPayExpeditedPayDialog
        source="Load List"
        onClose={closeModalAndRefetch}
        load={getModalLoad('expedite_payment')}
      />

      <LoadAddTerminalDrawer
        onClose={closeModal}
        load={getModalLoad('add_terminal')}
        onSubmitSuccess={(response, { loadGUID }) => {
          trackLoadsEvent({ name: 'CTMS: Terminal Added', page: 'loads_list' });
          closeModal();
          mutateLoad(loadGUID, {
            terminals_diagram: response.terminals_diagram,
          });
        }}
      />
      <PageLayout
        loading={loads.isLoading || isSubmitting}
        header={
          <>
            <PageHeader
              title={params.stage === 'active' ? 'Search result' : 'Loads'}
              startAction={
                params.stage === 'active' && (
                  <IconButton
                    edge="start"
                    size="small"
                    aria-label="go back"
                    onClick={() => {
                      updateParams(previousPageParams || {}, {
                        strategy: 'reset',
                      });
                    }}
                  >
                    <ArrowBack />
                  </IconButton>
                )
              }
              endActions={
                <LoadsPageHeaderActions
                  onImportedLoads={() => {
                    if (params.stage === 'new') {
                      invalidateLoads();
                    } else {
                      updateParams({}, { strategy: 'reset' });
                    }
                  }}
                  onUpgradePlan={() => {
                    setModalState(() => ({
                      type: 'upgrade_plan',
                    }));
                  }}
                />
              }
            />

            {!isMobile && (
              <PageToolbar>
                <LoadsPageFilter />
              </PageToolbar>
            )}

            {params.stage !== 'active' && <LoadsPageTabs />}
          </>
        }
      >
        <Stack space="small">
          <ExpeditedPayBanner source="Load List" />
          {params.stage === 'paid' && (
            <SuperPayYouGotPaidBanner source="Load List Banner" />
          )}
          <Columns space="small">
            <Column width="fluid">
              {shouldRenderSuggestedLoads && !!suggestedLoads.data && (
                <LoadsPageSuggestedLoadsList loads={suggestedLoads.data} />
              )}

              <Stack space="small">
                {shouldRenderSuggestedLoads &&
                  !!suggestedLoads.data?.length && (
                    <TextBox variant="heading-6">YOUR LOADS</TextBox>
                  )}
                <Stack space="xsmall">
                  {loads.data?.data.length === 0 ? (
                    <>
                      {!shouldRenderSuggestedLoads && (
                        <Box marginTop="xxlarge" />
                      )}
                      <LoadsPageEmptyResult />
                    </>
                  ) : (
                    loads.data?.data.map((load, index) => (
                      <Box
                        aria-label={`${
                          load.number ? load.number : 'Empty Number'
                        } Load Card #${index + 1}`}
                        key={load.guid}
                      >
                        <LoadsPageLoad
                          load={load}
                          onAction={(type) => {
                            if (isLoadInstantActionType(type)) {
                              submitInstantAction(type, load);
                            } else {
                              setModalState({ type, load });
                            }
                          }}
                        />
                      </Box>
                    ))
                  )}

                  {loads.data != null &&
                    loads.data.pagination.count > LOADS_PAGE_SIZE && (
                      <LoadsPagePagination
                        currentPage={params.page}
                        count={loads.data.pagination.count}
                        onPageChange={(nextPage) => {
                          updateParams({ page: nextPage });
                        }}
                      />
                    )}
                </Stack>
              </Stack>
            </Column>
            <Slide
              direction="left"
              in={shouldShowGroupByList && !isMobile}
              mountOnEnter={true}
              unmountOnExit={true}
              timeout={300}
            >
              <Column width="content">
                <ExitTransitionPlaceholder in={shouldShowGroupByList}>
                  {shouldShowGroupByList &&
                    Boolean(groupListTab) &&
                    params.order_by && (
                      <DateConfigProvider format="DateISO">
                        <LoadsPageGroupByList
                          tab={groupListTab}
                          groupBy={params.order_by}
                        />
                      </DateConfigProvider>
                    )}
                </ExitTransitionPlaceholder>
              </Column>
            </Slide>
          </Columns>
        </Stack>
      </PageLayout>
    </>
  );
}
