import { Link, Typography } from '@material-ui/core';
import { Column, Columns, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { useCustomerSupport } from 'shared/helpers/CustomerSupport';
import operatorDark from 'shared/modules/carrier-profile/assets/operator-dark.png';
import operatorWarningDark from 'shared/modules/carrier-profile/assets/operator-warning-dark.svg';
import operatorWarning from 'shared/modules/carrier-profile/assets/operator-warning.png';
import operator from 'shared/modules/carrier-profile/assets/operator.png';
import { WebViewLink } from 'shared/routing/WebViewLinks';
import { Image } from 'shared/ui/Image';
import styled from 'styled-components';
import { trackPhoneVerificationEvent } from './data/PhoneVerificationAnalytics';
import {
  useCarrierFmcsaPhoneNumber,
  VerificationCodeMethod,
} from './data/PhoneVerificationAPI';
import { PhoneVerificationPlaceholder } from './PhoneVerificationPlaceholder';
import { USDOTPinHelpDrawer } from './USDOTPinHelpDrawer';

const MainImage = styled(Image)`
  max-width: 120px;
`;

interface PhoneVerificationRequestProps {
  onCodeSend: (method: VerificationCodeMethod) => void;
  onPhoneChange: () => void;
  isCodeRequestLoading: boolean;
}

export function PhoneVerificationRequest({
  onCodeSend,
  onPhoneChange,
  isCodeRequestLoading,
}: PhoneVerificationRequestProps) {
  const isMobile = useResponsiveValue(true, false);
  const [shouldShowHelpDrawer, setShowHelpDrawer] = useState(false);

  const { data: fmcsaPhoneInfo, error } = useCarrierFmcsaPhoneNumber();

  if (!fmcsaPhoneInfo && !error) {
    return <PhoneVerificationPlaceholder />;
  }

  if (error) {
    return <PhoneVerificationRequestFailure />;
  }

  return (
    <>
      <USDOTPinHelpDrawer
        open={shouldShowHelpDrawer}
        onClose={() => {
          setShowHelpDrawer(false);
        }}
      />
      <Stack space="large">
        <Stack space="small">
          <MainImage src={operator} srcDark={operatorDark} />
          <Stack space="xxsmall">
            <Typography variant="h4">
              Let&apos;s verify your phone number
            </Typography>
            <Typography color="textSecondary">
              Verifying your phone number helps us keep our platform secure and
              your identity safe.
            </Typography>
          </Stack>
        </Stack>
        {fmcsaPhoneInfo.source === 'fmcsa_database' && (
          <Stack>
            <Typography variant="h5" color="textSecondary">
              Your phone on FMCSA record
            </Typography>
            <Typography variant="h2">{fmcsaPhoneInfo.number}</Typography>
            <Link
              color="primary"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                setShowHelpDrawer(true);
              }}
            >
              Is this not your number?
            </Link>
          </Stack>
        )}
        {fmcsaPhoneInfo.source === 'manual' && (
          <Stack>
            <Typography variant="h2">{fmcsaPhoneInfo.number}</Typography>
            <Link
              color="primary"
              href="#"
              onClick={(event) => {
                event.preventDefault();
                onPhoneChange();
              }}
            >
              Edit phone number
            </Link>
          </Stack>
        )}
        <Stack>
          <Typography variant="h5">Receive code via:</Typography>
          <Columns collapseBelow="tablet" space="small">
            <Column width="content">
              <Button
                disabled={isCodeRequestLoading}
                onClick={() => {
                  onCodeSend('CALL');
                }}
                variant="neutral"
                fullWidth={isMobile}
              >
                Call
              </Button>
            </Column>
            <Column width="content">
              <Button
                disabled={isCodeRequestLoading}
                onClick={() => {
                  onCodeSend('SMS');
                }}
                fullWidth={isMobile}
              >
                Text (SMS)
              </Button>
            </Column>
          </Columns>
          <Typography color="textSecondary">Standard rates apply.</Typography>
        </Stack>
      </Stack>
    </>
  );
}

export function PhoneVerificationRequestFailure() {
  const { showLauncherMessanger } = useCustomerSupport();
  const isMobile = useResponsiveValue(true, false);
  return (
    <Stack space="large">
      <Stack space="small">
        <MainImage src={operatorWarning} srcDark={operatorWarningDark} />
        <Stack space="xxsmall">
          <Typography variant="h4">
            We couldn&apos;t retrieve a phone number
          </Typography>
          <Typography color="textSecondary">
            Please check your USDOT registration or contact support for further
            assistance.
          </Typography>
        </Stack>
      </Stack>
      <Stack space="small">
        <Stack>
          <Typography variant="h4">Do you have your USDOT PIN?</Typography>
          <Typography>
            <WebViewLink
              color="primary"
              href="https://support.superdispatch.com/hc/en-us/articles/38186411472403-Update-Your-Phone-Number-With-The-FMCSA"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                trackPhoneVerificationEvent({
                  name: 'Carrier Clicked Instruction Fmcsa Usdot Pin',
                  utm_content: 'Phone Number Retrieve Error Screen',
                });
              }}
            >
              Click here
            </WebViewLink>{' '}
            for detailed instructions
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h4">You don’t have your USDOT PIN?</Typography>
          <Typography>
            <WebViewLink
              color="primary"
              href="https://li-public.fmcsa.dot.gov/LIVIEW/PKG_PIN_START.PRC_INTRO"
              rel="noreferrer"
              target="_blank"
              onClick={() => {
                trackPhoneVerificationEvent({
                  name: 'Carrier Clicked Request Fmcsa Usdot Pin',
                  utm_content: 'Phone Number Retrieve Error Screen',
                });
              }}
            >
              Click here
            </WebViewLink>{' '}
            to request a new PIN
          </Typography>
        </Stack>
      </Stack>
      <Button
        onClick={() => {
          showLauncherMessanger();
        }}
        variant="neutral"
        fullWidth={isMobile}
      >
        Contact Compliance
      </Button>
    </Stack>
  );
}
