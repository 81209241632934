import {
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Add, CloudUpload } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Column,
  Columns,
  Inline,
  useResponsiveValue,
  VisibilityObserver,
} from '@superdispatch/ui';
import {
  Box,
  Button,
  Sidebar,
  SidebarMenuItem,
  SidebarMenuItemAvatar,
  TextBox,
} from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { ContactsPageListHeader } from './ContactsPageListHeader';
import { useContactsPageRouter } from './ContactsPageRouter';
import { useContactsCache, useContactsList } from './data/ContactsAPI';

export interface ContactsPageListProps {
  selectedContacts: Set<string>;
  onSelectedContactsChange: (value: Set<string>) => void;
}

export function ContactsPageList({
  selectedContacts,
  onSelectedContactsChange,
}: ContactsPageListProps) {
  const isMobile = useResponsiveValue(true, false);
  const {
    openContact,
    currentContactGUID,
    setCurrentModal,
    searchQuery,
    searchText,
  } = useContactsPageRouter();
  const contactList = useContactsList({ q: searchQuery, page_size: 20 });
  const { findNextContact } = useContactsCache();

  useEffect(() => {
    if (currentContactGUID || isMobile) return;

    const nextGUID = findNextContact();
    if (nextGUID) {
      openContact(nextGUID, true);
    }
  }, [currentContactGUID, findNextContact, openContact, contactList, isMobile]);

  return (
    <Sidebar
      title={
        <Columns align="center">
          <Column width="1/2">
            <Typography variant="h3">Contacts</Typography>
          </Column>
          {isMobile && (
            <Column>
              <Inline
                space="small"
                horizontalAlign="right"
                verticalAlign="center"
                noWrap={true}
              >
                <IconButton
                  onClick={() => {
                    setCurrentModal('import');
                  }}
                >
                  <CloudUpload />
                </IconButton>
                <Button
                  startIcon={<Add />}
                  onClick={() => {
                    setCurrentModal('add');
                  }}
                >
                  Add
                </Button>
              </Inline>
            </Column>
          )}
        </Columns>
      }
      header={<ContactsPageListHeader />}
    >
      {!contactList.data?.pages.length ? (
        <ListItem>
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
      ) : !contactList.data.pages[0]?.data.length ? (
        <Box padding="small">
          <TextBox color="secondary" align="center">
            {searchText ? 'No search results' : 'No contacts'}
          </TextBox>
        </Box>
      ) : (
        contactList.data.pages.flatMap((page) =>
          page.data.map((contact) => {
            const isCurrent = currentContactGUID === contact.guid;

            return (
              <SidebarMenuItem
                key={contact.guid}
                selected={isCurrent}
                onClick={() => {
                  openContact(contact.guid);
                }}
                avatar={
                  <SidebarMenuItemAvatar
                    value={selectedContacts.has(contact.guid)}
                    onChange={(checked) => {
                      const nextSelectedContacts = new Set(selectedContacts);

                      if (checked) {
                        nextSelectedContacts.add(contact.guid);
                      } else {
                        nextSelectedContacts.delete(contact.guid);
                      }

                      onSelectedContactsChange(nextSelectedContacts);
                    }}
                  >
                    {contact.name || ''}
                  </SidebarMenuItemAvatar>
                }
              >
                {contact.name}
              </SidebarMenuItem>
            );
          }),
        )
      )}

      {contactList.hasNextPage && (
        <VisibilityObserver
          onChange={(visibility) => {
            if (!contactList.isFetchingNextPage && visibility === 'visible') {
              void contactList.fetchNextPage();
            }
          }}
          render={({ ref }) => (
            <ListItem ref={ref}>
              <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
            </ListItem>
          )}
        />
      )}
    </Sidebar>
  );
}
