import {
  FormControl,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core';
import { FormikRadioGroupField } from '@superdispatch/forms';
import { Stack } from '@superdispatch/ui';

export function SettingsAppearance() {
  return (
    <Stack space="xsmall">
      <Typography>Appearance</Typography>
      <Stack space="xxsmall">
        <Typography variant="body2">
          Customize how Carrier TMS looks on your device.
        </Typography>
      </Stack>
      <FormControl component="fieldset">
        <FormikRadioGroupField name="theme_mode">
          <Stack space={['xsmall', 'none']}>
            <FormControlLabel
              value="system"
              control={<Radio />}
              label="Use system settings"
            />
            <FormControlLabel value="light" control={<Radio />} label="Light" />
            <FormControlLabel value="dark" control={<Radio />} label="Dark" />
          </Stack>
        </FormikRadioGroupField>
      </FormControl>
    </Stack>
  );
}
