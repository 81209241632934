import { Badge, SvgIconProps } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import {
  FunctionComponent,
  Key,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const MenuContainer = styled.div`
  flex: 1;
  padding: 0 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar-thumb {
    background: #47505b;
  }
`;

export const StyledBadge = styled(Badge)<{ $highlighted: boolean }>`
  & .MuiBadge-badge {
    border: 2px solid
      ${(props) => (props.$highlighted ? Color.Dark400 : Color.Dark500)};
    top: 2px;
  }

  & .MuiBadge-dot {
    margin-top: 2px;
    margin-right: 2px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }
`;

export interface SidebarLinkLegacy {
  key: Key;
  href: string;
  label: string;
  hidden?: boolean;
  localPath?: string | undefined;
  Icon?: FunctionComponent<SvgIconProps>;
  RightIcon?: FunctionComponent<SvgIconProps>;
  badgeContent?: number | string;
  badgeType?: 'dot' | 'standard';
  RenderComponent?: FunctionComponent<{ link?: SidebarLinkLegacy }>;
  target?: '_blank';
  onClick?: () => void;
  hasError?: boolean;
  tag?: ReactElement;
  disabledLink?: boolean;
  dataIntercomTarget?: string;
}

export const SidebarMenuLinkLegacy = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 4px;
  padding: 8px;

  width: 100%;
  border-radius: 3px;

  color: ${Color.Silver500};
  text-decoration: none;
  font-size: 13px;
  letter-spacing: -0.5px;
  line-height: 16px;

  &:hover {
    color: ${Color.White};
    background-color: rgba(91, 99, 113, 0.2);
  }

  &[aria-current='page'] {
    color: ${Color.White};
    background-color: rgba(91, 99, 113, 0.5);
  }
`;

export const SidebarMenuBoxLegacy = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 4px;
  padding: 8px;

  width: 100%;
  border-radius: 3px;

  color: ${Color.Silver500};
  text-decoration: none;
  font-size: 13px;
  letter-spacing: -0.5px;
  line-height: 16px;

  &:hover {
    color: ${Color.White};
    background-color: rgba(91, 99, 113, 0.2);
  }

  &[aria-current='page'] {
    color: ${Color.White};
    background-color: rgba(91, 99, 113, 0.5);
  }
`;

interface SidebarMenuItemProps {
  link: SidebarLinkLegacy;
  children: ReactNode;
  onClick?: () => void;
  dataIntercomTarget?: string;
}

export function SidebarMenuItem({
  link,
  children,
  dataIntercomTarget,
  onClick,
}: SidebarMenuItemProps) {
  const { RenderComponent } = link;
  return (
    <li
      onClick={onClick}
      data-cy={`${link.key}_sidebar_item`}
      data-intercom-target={dataIntercomTarget}
    >
      {RenderComponent ? (
        <RenderComponent link={link} />
      ) : link.disabledLink ? (
        <SidebarMenuBoxLegacy onClick={link.onClick}>
          {children}
        </SidebarMenuBoxLegacy>
      ) : link.localPath ? (
        <SidebarMenuLinkLegacy
          as={NavLink}
          to={link.localPath}
          onClick={link.onClick}
        >
          {children}
        </SidebarMenuLinkLegacy>
      ) : (
        <SidebarMenuLinkLegacy href={link.href} onClick={link.onClick}>
          {children}
        </SidebarMenuLinkLegacy>
      )}
    </li>
  );
}

const SidebarMenuContainer = styled.ul`
  padding: 0;
  width: 100%;
  list-style: none;

  &[data-disablegutter='false'] {
    margin: 0 0 32px;
    &:last-child {
      margin: 0;
    }
  }
`;

export const StyledLinkLegacyIcon = styled.a`
  width: 24px;
  height: 24px;
  margin-bottom: 4px;

  path {
    fill: ${Color.Silver500};
  }
`;

export function SidebarMenu({
  links,
  disableGutter,
}: {
  links: SidebarLinkLegacy[];
  disableGutter?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <SidebarMenuContainer
      data-intercom-target="siebar-menu-section"
      data-disablegutter={!!disableGutter}
      onMouseOver={() => {
        setIsHovered(true);
      }}
      onMouseOut={() => {
        setIsHovered(false);
      }}
    >
      {links.map(
        (link) =>
          !link.hidden && (
            <SidebarMenuItem
              key={link.key}
              link={link}
              dataIntercomTarget={link.dataIntercomTarget}
            >
              <StyledBadge
                badgeContent={link.badgeContent}
                color="error"
                overlap="rectangular"
                max={999}
                $highlighted={isHovered}
                variant={link.badgeType}
                invisible={!link.badgeContent}
              >
                <StyledLinkLegacyIcon as={link.Icon} />
              </StyledBadge>
              {link.label}
            </SidebarMenuItem>
          ),
      )}
    </SidebarMenuContainer>
  );
}

export interface AppSidebarMenuLegacyProps {
  offersLinks: SidebarLinkLegacy[];
  primaryLinks: SidebarLinkLegacy[];
  secondaryLinks: SidebarLinkLegacy[];
}

export function AppSidebarMenuLegacy({
  offersLinks,
  primaryLinks,
  secondaryLinks,
}: AppSidebarMenuLegacyProps) {
  return (
    <MenuContainer>
      <SidebarMenu links={offersLinks} />
      <SidebarMenu links={primaryLinks} />
      <SidebarMenu links={secondaryLinks} />
    </MenuContainer>
  );
}
