import { Skeleton } from '@material-ui/lab';
import { Column, Columns, Inline, useResponsiveValue } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddDriverIcon } from 'shared/icons/AddDriverIcon';
import { RemoveIcon } from 'shared/icons/RemoveIcon';
import { DocumentTitle } from 'shared/layout/DocumentTitle';
import {
  useNextPlanEstimate,
  useShowPlanUpdateDrawer,
} from 'shared/modules/subscription/core/PlanUpdateDrawer';
import { useSubscriptionSettings } from 'shared/modules/subscription/SubscriptionAPI';
import { useFlag } from 'shared/settings/FeatureToggles';
import { BackButton } from 'shared/ui/BackButton';
import { getDriverLabel } from '../data/DriverPageUtils';
import { trackDriversEvent } from '../data/DriversAnalytics';
import { useDriversPageContext } from '../DriversPageContext';
import { DriversPageMutationDialogType } from '../DriversPageMutationDialog';
import { DriverDetailsMenuButton } from './DriverDetailMenuButton';
import { DriversPageHeaderInviteAction } from './DriversPageHeaderInviteAction';
import { DriverStatus } from './DriverStatus';

interface DriversPageDetailsHeaderProps {
  labelID: string;
  descriptionID: string;
  setCurrentModal: (modal: DriversPageMutationDialogType | 'edit') => void;
}

export function DriversPageDetailsHeader({
  labelID,
  descriptionID,
  setCurrentModal,
}: DriversPageDetailsHeaderProps) {
  const navigate = useNavigate();
  const isMobile = useResponsiveValue(true, false);
  const { driver } = useDriversPageContext();
  const { data: currentDriver } = driver;

  const driverLabel = getDriverLabel({
    name: currentDriver?.name || '',
    email: currentDriver?.email || '',
  });

  if (!currentDriver) {
    return (
      <TextBox variant="heading-2">
        <Skeleton width="200px" />
      </TextBox>
    );
  }

  if (isMobile) {
    return (
      <Columns align="center" space="xsmall">
        <DocumentTitle title={`${driverLabel} - Drivers`} />

        <Column width="content">
          <BackButton
            onClick={() => {
              navigate('/drivers', { replace: true });
            }}
          />
        </Column>

        <Column width="fluid">
          <Inline>
            <TextBox id={labelID} variant="heading-2" noWrap={true}>
              {driverLabel}
            </TextBox>

            <DriverStatus
              descriptionID={descriptionID}
              driver={currentDriver}
            />
          </Inline>
        </Column>

        <Column width="content">
          <DriverPageDetailsHeaderActions setCurrentModal={setCurrentModal} />
        </Column>
      </Columns>
    );
  }

  return (
    <Columns space="small" align="bottom">
      <DocumentTitle title={`${driverLabel} - Drivers`} />

      <Column width="fluid">
        <Columns align="bottom" space="xsmall">
          <Column width="adaptive">
            <TextBox id={labelID} variant="heading-2" noWrap={true}>
              {driverLabel}
            </TextBox>
          </Column>

          <Column width="content">
            <DriverStatus
              descriptionID={descriptionID}
              driver={currentDriver}
            />
          </Column>
        </Columns>
      </Column>

      <Column width="content">
        <Inline space="small">
          <DriverPageDetailsHeaderActions setCurrentModal={setCurrentModal} />
        </Inline>
      </Column>
    </Columns>
  );
}

interface DriverPageDetailsHeaderActionsProps {
  setCurrentModal: (modal: DriversPageMutationDialogType | 'edit') => void;
}

function DriverPageDetailsHeaderActions({
  setCurrentModal,
}: DriverPageDetailsHeaderActionsProps) {
  const { data: subscription } = useSubscriptionSettings();
  const isMultiCarrierConnectionEnabled = useFlag(
    'multi_carrier_connection_management',
  );
  const { shouldShowPlanUpdateDrawer } = useShowPlanUpdateDrawer();
  const nextPlanEstimate = useNextPlanEstimate();
  const { driver } = useDriversPageContext();
  const { data: currentDriver } = driver;

  const canInviteDriver = useMemo(() => {
    if (subscription?.plan) {
      const { plan } = subscription;
      const seatsCount = Number(plan.seats_count);
      const driversCount = Number(plan.drivers_count);
      return driversCount === 0 && seatsCount === 1;
    }
    return false;
  }, [subscription]);

  const onReinvite = () => {
    trackDriversEvent({
      name: 'Carrier Clicked Reinvite Driver',
    });
    if (shouldShowPlanUpdateDrawer) {
      void nextPlanEstimate.refetch().then(({ data: nextPlan }) => {
        setCurrentModal(
          nextPlan && !canInviteDriver ? 'plan_upgrade' : 'reinvite',
        );
      });
    } else {
      setCurrentModal('reinvite');
    }
  };

  if (!currentDriver) {
    return null;
  }

  return (
    <>
      {currentDriver.connection_type === 'invite' &&
        currentDriver.connection_status === 'pending' && (
          <DriversPageHeaderInviteAction />
        )}

      {isMultiCarrierConnectionEnabled &&
        currentDriver.connection_status === 'deactivated' && (
          <Button
            variant="neutral"
            pending={nextPlanEstimate.isFetching}
            startIcon={<AddDriverIcon />}
            onClick={onReinvite}
          >
            Reinvite
          </Button>
        )}

      {currentDriver.connection_status === 'activated' && (
        <DriverDetailsMenuButton
          currentDriver={currentDriver}
          setCurrentModal={setCurrentModal}
        />
      )}
      {currentDriver.connection_status === 'deactivated' &&
      !isMultiCarrierConnectionEnabled ? (
        <DriverDetailsMenuButton
          currentDriver={currentDriver}
          setCurrentModal={setCurrentModal}
        />
      ) : currentDriver.connection_status === 'deactivated' ? (
        <Button
          variant="neutral"
          startIcon={<RemoveIcon />}
          onClick={() => {
            setCurrentModal('delete');
          }}
        >
          Remove
        </Button>
      ) : null}
    </>
  );
}
